import React, { useReducer } from 'react';
import commonReducer from './common/reducer';
import { setMobile } from './common/actions';
import localStorage from '../utils/localStorage';

export const commonStore = React.createContext();

let isThrottled = false;
const throttle = setFixed => {
  if (isThrottled) return;
  isThrottled = true;
  setTimeout(() => {
    isThrottled = false;
    setFixed(true);
  }, 200);
};

export const CommonProvider = ({ children, init = {} }) => {
  const isWindow = typeof window === 'object';
  const city = init.city || null;
  const becomePerformer = isWindow && localStorage.getItem('becomePerformer');
  // const isQuiz = (isWindow) && localStorage.getItem('isQuiz');
  const isMobile = isWindow ? window.innerWidth <= 768 : !init?.isDesktop;
  const filterQueryString = '';
  const [common, commonDispatch] = useReducer(commonReducer, {
    ui: {
      isMobile: init.isMobile === undefined ? isMobile : init.isMobile,
    },
    city,
    becomePerformer,
    filterQueryString,
  });

  const handleWindowResize = () => {
    throttle(() => setMobile(commonDispatch, window.innerWidth <= 768));
  };

  React.useEffect(() => {
    if (isWindow) {
      window.addEventListener('resize', handleWindowResize);
      window.addEventListener('load', handleWindowResize);
      window.isReactHydrated = true;
    }
    return () => {
      if (isWindow) window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const value = { common, dispatch: commonDispatch };
  return <commonStore.Provider value={value}>{children}</commonStore.Provider>;
};
