const storage = {};

const setItem = (key, value) => {
  try {
    storage[key] = value;
    window.localStorage.setItem(key, value);
  } catch (err) {
    console.log('localStorage.setItem error');
  }
};

const removeItem = key => {
  try {
    storage[key] = undefined;
    window.localStorage.removeItem(key);
  } catch (err) {
    console.log('localStorage.removeItem error');
  }
};

const getItem = key => {
  try {
    return window.localStorage.getItem(key);
  } catch (err) {
    console.log('localStorage.getItem error');
    return storage[key];
  }
};

export default { setItem, removeItem, getItem };
