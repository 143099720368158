import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import settings from 'src/common/settings';
import { commonStore } from '../../store/storeProvider';
import { navigationItemsFooter } from '../../common/navigationItems';
import { getPathName } from '../../utils/urlControl';

const { linksToImg, email } = settings;

const FooterDesktopLazy = dynamic(() => import('./FooterDesktop.jsx'));
const FooterMobileLazy = dynamic(() => import('./FooterMobile.jsx'));

const Footer = () => {
  const {
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);

  const blackList = ['create-task', 'admin', 'profile'];
  const router = useRouter();

  const routerQuery = router.asPath === '/';
  const navigationItems = routerQuery
    ? navigationItemsFooter.filter(el => el.name !== 'home')
    : navigationItemsFooter;

  const pathname = getPathName(router?.pathname).split('/')[1];
  if (blackList.includes(pathname)) return null;

  return isMobile ? (
    <FooterMobileLazy
      linksToImg={linksToImg}
      email={email}
      navigationItems={navigationItems}
    />
  ) : (
    <FooterDesktopLazy
      linksToImg={linksToImg}
      email={email}
      navigationItems={navigationItems}
    />
  );
};
export default Footer;
