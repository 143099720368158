import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Text, Box, Heading, FormControl } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import useModalPaddings from 'src/hooks/useModalPaddings';
import { LINK_DELETE_PROFILE } from '../../gql/mutation';
import { onCloseModal, onOpenRegister } from '../../utils/modalControl';
import { throwErrorSentry } from '../../utils/throwErrorSentry';
import EmailInput from '../EmailInput';
import { useMutationUrql } from '../../utils/urqlClient/helpers';

const DeleteProfile = ({ data, token, router }) => {
  const { t } = useTranslation(['common']);
  const [err, setError] = useState(false);
  const [isDeleted, setDeleted] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const boxPaddings = useModalPaddings()
  const [deleteProfile, { loading }] = useMutationUrql(LINK_DELETE_PROFILE, {
    onCompleted: () => {
      setDeleted(true);
    },
    onError: error => {
      throwErrorSentry(error);
      setError(true);
    },
  });

  const onSubmit = ({ email }) => {
    deleteProfile({
      input: {
        email,
        token,
      },
    });
  };

  useEffect(() => {
    if (data?.me?.id) {
      onCloseModal(router, 'deleteProfile');
    }
  }, [data?.me?.id]);

  if (err) {
    return (
      <Box {...boxPaddings}>
        <Heading as='h2' size='1-2xl' textAlign='center'>
          {t('delete-profile-modal.unable-to-delete-account')}
        </Heading>
        <Text color='typography.secondary' textAlign='center'>
          {t(
            'delete-profile-modal.account-has-pending-orders-or-link-has-expired',
          )}
        </Text>
        <Button
          mt={5}
          variant='primary'
          w='100%'
          onClick={() => routerPush(router, '/recovery')}
        >
          {t('delete-profile-modal.restore-account-access')}
        </Button>
        <Button
          mt={5}
          w='100%'
          onClick={() => onCloseModal(router, 'deleteProfile')}
        >
          {t('button.closed')}
        </Button>
      </Box>
    );
  }

  return isDeleted ? (
    <Box>
      <Heading as='h2' size='1-2xl' mb={5} textAlign='center'>
        {t('delete-profile-modal.account-deleted')}
      </Heading>
      <Button
        mt={5}
        variant='primary'
        w='100%'
        onClick={() => onOpenRegister(router)}
      >
        {t('delete-profile-modal.create-new')}
      </Button>
      <Button
        mt={5}
        w='100%'
        onClick={() => onCloseModal(router, 'deleteProfile')}
      >
        {t('button.closed')}
      </Button>
    </Box>
  ) : (
    <Box>
      <Heading as='h2' size='1-2xl' mb={5} textAlign='center'>
        {t('delete-profile-modal.account-deleting')}
      </Heading>
      <FormControl>
        <EmailInput register={register} errors={errors} err={err} />
        <Button
          mt={5}
          variant='primaryBlack'
          w='100%'
          onClick={handleSubmit(onSubmit)}
          isLoading={loading}
          loadingText={t('delete-profile-modal.delete-account')}
        >
          {t('delete-profile-modal.delete-account')}
        </Button>
      </FormControl>
      <Button
        mt={5}
        variant='primary'
        w='100%'
        onClick={() => routerPush(router, '/recovery')}
      >
        {t('delete-profile-modal.restore-access')}
      </Button>
    </Box>
  );
};

export default DeleteProfile;
