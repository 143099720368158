import { gql } from '@urql/core';
import {
  jobTypeFragments,
  profileFragments,
  orderFragments,
  myOrdersFragments,
  publicProfileFragments,
  myTenderFragment,
  ordersFragments,
  myOrderFragments,
  chatFragments,
  approvedOrderFragments,
  feedbacksFragments,
  orderSystemFragments,
  myApprovedOrderFragments,
  myApprovedOrdersFragments,
  complaintFragment,
  approvedOrderSystemFragments,
  feedbackSystemFragments,
  approvedOrderComplaintFragment,
  closedOrderFragments,
  feedbacksPreviewFragments,
  jobTypeUtpCardFragments,
  botHistoryFragments,
  publicOrderFragments,
  exampleTaskFragment,
  fileExternalFragment,
  tariffPlanFragments,
  jobTypeSeoFragments,
  jobTypePriceFromAdminFragments,
  jobTypeWithPricesFragment,
  jobTypeWithUserPricesFragment,
} from '../fragment';

const ME = gql`
  query me {
    me {
      id
      name
      permission
      status
    }
  }
`;

const BOT_SUBSCRIPTION = gql`
  query botSubscriptions {
    botSubscriptions {
      botSubscriptions {
        id
        messengerType
        subscriptionStatus
        newMessage
        newOrder
        newOrderStatus
      }
    }
  }
`;

const JOB_TYPES = gql`
  query jobtypes($filter: FilterInput) {
    jobTypes(filter: $filter) {
      filter {
        totalCount
      }
      jobTypes {
        ...JobTypeFrag
      }
    }
  }
  ${jobTypeFragments}
`;

const JOB_TYPES_WITH_PRICES = gql`
  query jobTypesWithPrices($userId: String) {
    jobTypesWithPrices(userId: $userId) {
      jobTypesWithPrices {
        ...JobTypeWithPricesFrag
        items {
          ...JobTypeWithPricesFrag
          items {
            ...JobTypeWithPricesFrag
            items {
              ...JobTypeWithPricesFrag
            }
          }
        }
      }
    }
  }
  ${jobTypeWithPricesFragment}
`;

const JOB_TYPES_WITH_USER_PRICES = gql`
  query jobTypesWithUserPrices($userId: String) {
    jobTypesWithUserPrices(userId: $userId) {
      jobTypesWithUserPrices {
        ...JobTypeWithUserPricesFrag
        items {
          ...JobTypeWithUserPricesFrag
          items {
            ...JobTypeWithUserPricesFrag
            items {
              ...JobTypeWithUserPricesFrag
            }
          }
        }
      }
    }
  }
  ${jobTypeWithUserPricesFragment}
`;

const JOB_TYPES_MINIFIED = gql`
  query jobtypes($filter: FilterInput) {
    jobTypes(filter: $filter) {
      filter {
        totalCount
      }
      jobTypes {
        id
        name
        path
        items {
          id
          name
          path
          items {
            id
            name
            path
            items {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const JOB_TYPES_FIND_TASK = gql`
  query jobtypes($filter: FilterInput) {
    jobTypes(filter: $filter) {
      filter {
        totalCount
      }
      jobTypes {
        id
        name
        items {
          id
          name
        }
      }
    }
  }
`;

const JOB_TYPE = gql`
  query jobType($id: String!) {
    jobType(id: $id) {
      ...JobTypeFrag
    }
  }
  ${jobTypeFragments}
`;

const TARIFF_PLANS = gql`
  query tariffPlans($filter: FilterInput) {
    tariffPlans(filter: $filter) {
      filter {
        totalCount
      }
      tariffPlans {
        ...TariffPlanFrag
      }
    }
  }
  ${tariffPlanFragments}
`;

const TARIFF_PLAN = gql`
  query tariffPlan($id: String!) {
    tariffPlan(id: $id) {
      ...TariffPlanFrag
    }
  }
  ${tariffPlanFragments}
`;

const BOT_HISTORY = gql`
  query botHistory($filter: BotHistoryFilter) {
    botHistory(filter: $filter) {
      ...BotHistoryFrag
    }
  }
  ${botHistoryFragments}
`;

const JOB_TYPE_UTP_CARDS = gql`
  query jobTypeUtpCards($filter: FilterInput) {
    jobTypeUtpCards(filter: $filter) {
      filter {
        totalCount
      }
      jobTypeUtpCards {
        ...JobTypeUtpCardFrag
      }
    }
  }
  ${jobTypeUtpCardFragments}
`;

const JOB_TYPE_UTP_CARD = gql`
  query jobTypeUtpCard($id: String!) {
    jobTypeUtpCard(id: $id) {
      ...JobTypeUtpCardFrag
    }
  }
  ${jobTypeUtpCardFragments}
`;

const JOB_TYPE_SEO_LIST = gql`
  query jobTypeSeoList($filter: FilterInput) {
    jobTypeSeoList(filter: $filter) {
      filter {
        totalCount
      }
      jobTypeSeoList {
        ...JobTypeSeoFrag
      }
    }
  }
  ${jobTypeSeoFragments}
`;

const JOB_TYPE_SEO = gql`
  query jobTypeSeo($filter: FilterJobTypeSeoInput) {
    jobTypeSeo(filter: $filter) {
      ...JobTypeSeoFrag
    }
  }
  ${jobTypeSeoFragments}
`;

const JOB_TYPE_PRICES = gql`
  query jobTypePrices($filter: FilterInput) {
    jobTypePrices(filter: $filter) {
      filter {
        totalCount
      }
      jobTypePrices {
        ...JobTypePriceFromAdminFrag
      }
    }
  }
  ${jobTypePriceFromAdminFragments}
`;

const JOB_TYPE_PRICE = gql`
  query jobTypePrice($id: String!) {
    jobTypePrice(id: $id) {
      ...JobTypePriceFromAdminFrag
    }
  }
  ${jobTypePriceFromAdminFragments}
`;

const PROFILE = gql`
  query profile($id: String) {
    profile(id: $id) {
      ...ProfileFrag
    }
  }
  ${profileFragments}
`;

const CHECK_RECOVERY_TOKEN = gql`
  query checkRecoveryToken($token: String) {
    checkRecoveryToken(token: $token) {
      token
    }
  }
`;

const PUBLIC_PROFILE = gql`
  query publicProfile($id: String!) {
    publicProfile(id: $id) {
      ...PublicProfileFrag
    }
  }
  ${publicProfileFragments}
`;

const PUBLIC_PROFILES = gql`
  query publicProfiles($filter: FilterInput) {
    publicProfiles(filter: $filter) {
      profiles {
        ...PublicProfileFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
  ${publicProfileFragments}
`;

const ORDER = gql`
  query order($id: String) {
    order(id: $id) {
      ...OrderFrag
      isPhone
    }
  }
  ${orderFragments}
`;

const ORDER_SYSTEM = gql`
  query orderSystem($id: String) {
    orderSystem(id: $id) {
      ...OrderSystemFrag
    }
  }
  ${orderSystemFragments}
`;

const GET_CUSTOMER_PHONE = gql`
  query getCustomerPhone($id: String) {
    getCustomerPhone(id: $id) {
      phone
    }
  }
`;

const GET_EXECUTOR_PHONE = gql`
  query getExecutorPhone($id: String) {
    getExecutorPhone(id: $id) {
      phone
    }
  }
`;

const PUBLIC_ORDER = gql`
  query publicOrder($id: String) {
    publicOrder(id: $id) {
      ...PublicOrderFrag
    }
  }
  ${publicOrderFragments}
`;

const PUBLIC_ORDERS = gql`
  query publicOrders($filter: FilterInput) {
    publicOrders(filter: $filter) {
      results {
        id
        title
        updatedAt
        status
        createdAt
        cost
        jobType {
          name
        }
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
`;

const PUBLIC_ORDERS_TOTAL_COUNT = gql`
  query publicOrders($filter: FilterInput) {
    publicOrders(filter: $filter) {
      filter {
        totalCount
      }
    }
  }
`;

const ORDERS = gql`
  query orders($filter: FilterInput) {
    orders(filter: $filter) {
      orders {
        ...OrdersFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
  ${ordersFragments}
`;

const ORDERS_SYSTEM = gql`
  query ordersSystem($filter: FilterInput) {
    ordersSystem(filter: $filter) {
      orders {
        id
        title
        views
        createdAt
        showPhoneCount
        cost
        jobType {
          id
          name
        }
        tender {
          totalCount
        }
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
`;

const MY_ORDERS = gql`
  query myOrders($filter: FilterInput) {
    myOrders(filter: $filter) {
      results {
        ...MyOrdersFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
  ${myOrdersFragments}
`;

const APPROVED_ORDERS_SYSTEM = gql`
  query approvedOrdersSystem($filter: FilterInput) {
    approvedOrdersSystem(filter: $filter) {
      results {
        id
        title
        status
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
`;

const CLOSED_ORDERS = gql`
  query closedOrders($filter: FilterInput) {
    closedOrders(filter: $filter) {
      results {
        id
        title
        status
        showPhoneCount
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
`;

const MY_APPROVED_ORDERS = gql`
  query myApprovedOrders($filter: FilterInput) {
    myApprovedOrders(filter: $filter) {
      results {
        ...MyApprovedOrdersFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
  ${myApprovedOrdersFragments}
`;

const MY_APPROVED_ORDER = gql`
  query myApprovedOrder($id: String) {
    myApprovedOrder(id: $id) {
      ...MyApprovedOrderFrag
    }
  }
  ${myApprovedOrderFragments}
`;

const APPROVED_ORDER_SYSTEM = gql`
  query approvedOrderSystem($id: String) {
    approvedOrderSystem(id: $id) {
      ...ApprovedOrderSystemFrag
    }
  }
  ${approvedOrderSystemFragments}
`;

const CLOSED_ORDER = gql`
  query closedOrder($id: String) {
    closedOrder(id: $id) {
      ...ClosedOrderFrag
    }
  }
  ${closedOrderFragments}
`;

const FEEDBACK_SYSTEM = gql`
  query feedbackSystem($id: String) {
    feedbackSystem(id: $id) {
      ...FeedbackSystemFrag
    }
  }
  ${feedbackSystemFragments}
`;

const MY_ORDER = gql`
  query myOrder($id: String) {
    myOrder(id: $id) {
      ...MyOrderFrag
    }
  }
  ${myOrderFragments}
`;

const MY_TENDER = gql`
  query myTender($id: String) {
    myTender(id: $id) {
      ...TenderFrag
    }
  }
  ${myTenderFragment}
`;

const CHAT = gql`
  query chat($id: String) {
    chat(id: $id) {
      ...ChatFrag
    }
  }
  ${chatFragments}
`;

const MY_TENDERS = gql`
  query myTenders($filter: FilterInput) {
    myTenders(filter: $filter) {
      tenders {
        ...TenderFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
  ${myTenderFragment}
`;

const MY_APPROVED_TENDER = gql`
  query myApprovedTender($id: String) {
    myApprovedTender(id: $id) {
      ...ApprovedOrderFrag
    }
  }
  ${approvedOrderFragments}
`;

const MY_APPROVED_TENDERS = gql`
  query myApprovedTenders($filter: FilterInput) {
    myApprovedTenders(filter: $filter) {
      results {
        ...ApprovedOrderFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
  ${approvedOrderFragments}
`;

const PREVIEW_FEEDBACKS = gql`
  query previewFeedbacks($input: FeedbacksInput) {
    previewFeedbacks(input: $input) {
      results {
        feedbacks {
          ...FeedbackPreviewFrag
        }
        averageRating
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
  ${feedbacksPreviewFragments}
`;

const FEEDBACKS = gql`
  query feedbacks($input: FeedbacksInput) {
    feedbacks(input: $input) {
      results {
        ...FeedbackFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
  ${feedbacksFragments}
`;

const RELEVANT_FEEDBACKS = gql`
  query relevantFeedbacks($filter: FilterInput) {
    relevantFeedbacks(filter: $filter) {
      results {
        id
        text
        rating
        createdAt
        images {
          ...FileExternalFrag
        }
        executor {
          ...PublicProfileFrag
        }
        order {
          cost
          title
          feedback {
            rating
            from {
              name
            }
          }
          createdAt
        }
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
  ${fileExternalFragment}
  ${publicProfileFragments}
`;

const RECENT_ORDERS = gql`
  query recentOrders($filter: FilterInput) {
    recentOrders(filter: $filter) {
      results {
        id
        title
        cost
        status
        createdAt
        images {
          ...FileExternalFrag
        }
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
  ${fileExternalFragment}
`;

const RELEVANT_EXECUTORS = gql`
  query relevantExecutors($filter: FilterInput) {
    relevantExecutors(filter: $filter) {
      profiles {
        ...PublicProfileFrag
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
        sortBy {
          id
          value
        }
      }
    }
  }
  ${publicProfileFragments}
`;

const NOTIFICATIONS = gql`
  query notifications {
    notifications {
      id
      entityId
      type
      status
      isPopUp
      userType
      payload {
        tenderId
      }
    }
  }
`;

const USER_SEARCHES = gql`
  query userSearches($filter: FilterInput) {
    userSearches(filter: $filter) {
      userSearches {
        id
        count
        status
        text
        type
        createdAt
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
`;

const COMPLAINT = gql`
  query complaint($id: String) {
    complaint(id: $id) {
      ...ComplaintFrag
    }
  }
  ${complaintFragment}
`;

const APPROVED_ORDER_COMPLAINT = gql`
  query approvedOrderComplaint($id: String) {
    approvedOrderComplaint(id: $id) {
      ...ApprovedOrderComplaintFrag
    }
  }
  ${approvedOrderComplaintFragment}
`;

const COMPLAINTS = gql`
  query complaints($filter: FilterInput) {
    complaints(filter: $filter) {
      complaints {
        id
        order {
          id
          title
        }
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
`;

const APPROVED_ORDER_COMPLAINTS = gql`
  query approvedOrderComplaints($filter: FilterInput) {
    approvedOrderComplaints(filter: $filter) {
      complaints {
        id
        order {
          id
          title
        }
      }
      filter {
        totalCount
        pagination {
          page
          pageSize
        }
      }
    }
  }
`;

const EXAMPLE_TASK = gql`
  query exampleTask($id: String!) {
    exampleTask(id: $id) {
      ...ExampleTaskFrag
    }
  }
  ${exampleTaskFragment}
`;

const EXAMPLE_TASKS = gql`
  query exampleTasks($filter: FilterInput) {
    exampleTasks(filter: $filter) {
      filter {
        totalCount
      }
      exampleTasks {
        ...ExampleTaskFrag
      }
    }
  }
  ${exampleTaskFragment}
`;

export {
  CLOSED_ORDER,
  CLOSED_ORDERS,
  APPROVED_ORDER_COMPLAINT,
  APPROVED_ORDER_COMPLAINTS,
  GET_EXECUTOR_PHONE,
  GET_CUSTOMER_PHONE,
  USER_SEARCHES,
  ME,
  JOB_TYPES,
  JOB_TYPES_MINIFIED,
  JOB_TYPES_FIND_TASK,
  JOB_TYPE,
  JOB_TYPE_UTP_CARDS,
  JOB_TYPE_UTP_CARD,
  JOB_TYPE_SEO_LIST,
  JOB_TYPE_SEO,
  JOB_TYPES_WITH_PRICES,
  JOB_TYPES_WITH_USER_PRICES,
  JOB_TYPE_PRICES,
  JOB_TYPE_PRICE,
  PROFILE,
  PUBLIC_ORDER,
  PUBLIC_ORDERS,
  PUBLIC_ORDERS_TOTAL_COUNT,
  PUBLIC_PROFILES,
  PUBLIC_PROFILE,
  ORDER,
  ORDER_SYSTEM,
  ORDERS,
  ORDERS_SYSTEM,
  APPROVED_ORDER_SYSTEM,
  APPROVED_ORDERS_SYSTEM,
  MY_ORDERS,
  MY_ORDER,
  MY_TENDER,
  MY_TENDERS,
  MY_APPROVED_TENDER,
  MY_APPROVED_TENDERS,
  CHAT,
  MY_APPROVED_ORDERS,
  MY_APPROVED_ORDER,
  PREVIEW_FEEDBACKS,
  FEEDBACKS,
  NOTIFICATIONS,
  BOT_SUBSCRIPTION,
  CHECK_RECOVERY_TOKEN,
  COMPLAINTS,
  COMPLAINT,
  FEEDBACK_SYSTEM,
  BOT_HISTORY,
  RELEVANT_FEEDBACKS,
  RELEVANT_EXECUTORS,
  EXAMPLE_TASK,
  EXAMPLE_TASKS,
  RECENT_ORDERS,
  TARIFF_PLAN,
  TARIFF_PLANS,
};
