import React from 'react';
import { Icon } from '@chakra-ui/react';

const EmailIcon = props => (
  <Icon viewBox='0 0 128 128' boxSize={6} {...props}>
    <path
      d='M116.73 31.8299C116.494 31.514 116.199 31.2476 115.86 31.0459C115.521 30.8443 115.146 30.7113 114.756 30.6547C114.366 30.598 113.969 30.6188 113.587 30.7158C113.205 30.8128 112.846 30.9841 112.53 31.2199L64.1398 67.3399C63.9667 67.4697 63.7562 67.5399 63.5398 67.5399C63.3234 67.5399 63.1129 67.4697 62.9398 67.3399L15.4998 31.0599C15.1866 30.8209 14.8294 30.6459 14.4486 30.545C14.0677 30.444 13.6707 30.4191 13.2803 30.4715C12.8898 30.524 12.5135 30.6529 12.1728 30.8508C11.8322 31.0487 11.5338 31.3117 11.2948 31.6249C11.0558 31.9381 10.8808 32.2953 10.7799 32.6762C10.6789 33.057 10.6539 33.454 10.7064 33.8444C10.7589 34.2349 10.8877 34.6112 11.0856 34.9519C11.2835 35.2926 11.5466 35.5909 11.8598 35.8299L49.1598 64.3599L12.2698 92.1599C11.952 92.397 11.684 92.6943 11.4811 93.0349C11.2782 93.3755 11.1444 93.7527 11.0873 94.1451C11.0302 94.5374 11.0509 94.9371 11.1483 95.3214C11.2456 95.7058 11.4178 96.0671 11.6548 96.3849C11.8918 96.7027 12.1891 96.9707 12.5297 97.1736C12.8704 97.3765 13.2476 97.5104 13.6399 97.5675C14.0322 97.6246 14.432 97.6038 14.8163 97.5065C15.2006 97.4091 15.562 97.237 15.8798 96.9999L54.1098 68.1399L59.2898 72.1399C60.4976 73.0645 61.9737 73.5704 63.4947 73.5813C65.0158 73.5921 66.4989 73.1072 67.7198 72.1999L73.1598 68.1399L111.84 96.9999C112.156 97.2357 112.515 97.4069 112.897 97.5038C113.279 97.6007 113.676 97.6214 114.066 97.5648C114.456 97.5081 114.831 97.3752 115.17 97.1737C115.508 96.9721 115.804 96.7058 116.04 96.3899C116.276 96.0741 116.447 95.7149 116.544 95.3329C116.641 94.9509 116.661 94.5536 116.605 94.1636C116.548 93.7736 116.415 93.3986 116.214 93.0599C116.012 92.7213 115.746 92.4257 115.43 92.1899L78.1698 64.3499L116.12 35.9999C116.749 35.5246 117.166 34.8205 117.28 34.0401C117.395 33.2596 117.197 32.4656 116.73 31.8299V31.8299Z'
      fill='currentColor'
    />
    <path
      d='M113 19H15C11.0218 19 7.20644 20.5804 4.3934 23.3934C1.58035 26.2064 0 30.0218 0 34L0 94C0 97.9782 1.58035 101.794 4.3934 104.607C7.20644 107.42 11.0218 109 15 109H113C116.978 109 120.794 107.42 123.607 104.607C126.42 101.794 128 97.9782 128 94V34C128 30.0218 126.42 26.2064 123.607 23.3934C120.794 20.5804 116.978 19 113 19ZM122 94C122 96.3869 121.052 98.6761 119.364 100.364C117.676 102.052 115.387 103 113 103H15C12.6131 103 10.3239 102.052 8.63604 100.364C6.94821 98.6761 6 96.3869 6 94V34C6 31.6131 6.94821 29.3239 8.63604 27.636C10.3239 25.9482 12.6131 25 15 25H113C115.387 25 117.676 25.9482 119.364 27.636C121.052 29.3239 122 31.6131 122 34V94Z'
      fill='currentColor'
    />
  </Icon>
);
export default EmailIcon;
