import Input from './input';

const NumberInput = {
  ...Input,
  baseStyle: {
    ...Input.baseStyle,
  },
};

export default NumberInput;
