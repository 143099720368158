import {
  SET_MOBILE,
  SET_CITY,
  BECOME_PERFORMER,
  SET_FILTER_QUERY_STRING,
  CLEAR_FILTER_QUERY_STRING,
  // IS_QUIZ,
} from './constants';

const commonReducer = (state, action) => {
  switch (action.type) {
    case SET_MOBILE:
      return { ...state, ui: { ...state.ui, isMobile: action.data } };
    case SET_CITY:
      return {
        ...state,
        city: {
          ...state.city,
          ...action.data,
        },
      };
    case BECOME_PERFORMER:
      return {
        ...state,
        becomePerformer: action.data,
      };
    case SET_FILTER_QUERY_STRING:
      return {
        ...state,
        filterQueryString: action.data,
      };
    case CLEAR_FILTER_QUERY_STRING:
      return {
        ...state,
        filterQueryString: '',
      };
    default:
      return state;
  }
};

export default commonReducer;
