import { extendTheme } from '@chakra-ui/react';

// // Global style overrides
// import styles from './styles';

// // Foundational style overrides
// import borders from './foundations/borders';

// Component style overrides
import Button from './components/button';
import Progress from './components/progress';
import Modal from './components/modal';
import Input from './components/input';
import Select from './components/select';
import Heading from './components/heading';
import Text from './components/text';
import Textarea from './components/textarea';
import PinInput from './components/pinInput';
import NumberInput from './components/numberInput';
import Popover from './components/popover';
import Link from './components/link';
import colors from './colors';
import space from './space';

const sizes = space;

const overrides = {
  sizes,
  colors,
  space,
  components: {
    Link,
    Popover,
    Select,
    Button,
    Progress,
    Modal,
    Input,
    Heading,
    Text,
    Textarea,
    PinInput,
    NumberInput,
  },
  // fonts: {
  //   body: 'Manrope',
  //   heading: 'Manrope',
  //   mono: 'Manrope',
  // },
  shadow: {
    gray: '0px 20px 20px 10px #edf2f7;',
  },
};

export default extendTheme(overrides);
