const { decl } = require('src/utils/helpers');

// TODO Location
const meta = {
  main: {
    title: city =>
      `Сервис поиска специалистов ${city || 'в Беларуси'}, найти мастера`,
    description: city =>
      `🔍 MasterYoda – сервис поиска специалистов ${
        city || 'в Беларуси'
      }: 📚 репетиторы, 🛠 специалисты по ремонту, 📸 фотографы, массажисты, 🎶 артисты и музыканты 🎸, nail-мастера, няньки, тренера, 🧠 психологи и пр. Поиск под ваши требования, свободный график, индивидуальный подход`,
  },
  executorList: {
    title: (city, jobItemName) =>
      `${jobItemName || 'Надежные специалисты'} ${
        city || 'в Беларуси'
      } – MasterYoda`,
    description: (city, jobItemName) =>
      `${jobItemName || 'Надежные специалисты'} ${
        city || 'в Беларуси'
      } ⏩ MasterYoda – сервис поиска надежных специалистов ✔️ Гарантия за выполненную работу ⏩ Платите мастеру напрямую.`,
  },
  executorProfile: {
    title: (name, jobItem, lastName, city, taskRating) =>
      !name
        ? `Специалист на MasterYoda ${jobItem ? `- ${jobItem}` : ''}${
            city ? ` в городе ${city} ` : ''
          }${taskRating ? `☆ ${taskRating} ` : ''}`
        : `${name}${lastName ? ` ${lastName}` : ''} - ${
            jobItem || 'специалист'
          } на MasterYoda ${city ? `в городе ${city} ` : ''}${
            taskRating ? `☆ ${taskRating} ` : ''
          }`,
    description: (name, jobItem, lastName, city, firstDayOnline) =>
      `${name ? `${name} ` : ''}${lastName ? `${lastName} ` : ''}✅ ${
        jobItem || 'Специалист'
      } ${city ? `в городе ${city} ` : ''}${
        firstDayOnline ? ` с ${firstDayOnline} ` : ''
      }на MasterYoda. Проверенные специалисты на MasterYoda с честными отзывами.`,
  },
  createTask: {
    title: city =>
      `Создать задание для поиска специалиста ${
        city ? `${city}` : 'в Беларуси'
      }`,
    description:
      '✔ Создать задание на MasterYoda – биржа для поиска специалистов: 📚 преподаватели, 🚚 грузоперевозки, 🛠 мастера по ремонту, монтажники, 🔌 электрики, сварщики, тренера, бухгалтеры и пр. ✅ Проверенные исполнители',
  },
  findTask: {
    title: (city, totalCount) =>
      `Поиск работы ${city ? `${city}` : 'в Беларуси'}${
        totalCount
          ? ` - ${totalCount} ${decl(totalCount || 0, [
              'задание',
              'заданий',
              'задания',
            ])} на MasterYoda`
          : ''
      }`,
    description: city =>
      `🔎 Поиск заказов ${
        city ? ` ${city} ` : ''
      } ⏩ MasterYoda - множество разноплановых заданий для всех уровней специалистов. ✅ Проверенные заказчики.`,
  },
  servicesList: {
    title: city =>
      `Каталог услуг по категориям ${
        city ? `${city}` : 'в Беларуси'
      } на MasterYoda`,
    description: city =>
      `🔎 На MasterYodа вы сможете найти специалиста подходящего по вашим критериям ${
        city ? `в городе ${city}` : 'в Беларуси'
      }. ✅ Проверенные специалисты по всей стране`,
  },
  previewService: {
    title: (title, city, totalCount, reviewCount) =>
      `${title || 'Услуги'} ${city || 'в Беларуси'} ${
        totalCount
          ? ` - ${totalCount} ${decl(totalCount || 0, [
              'специалист',
              'специалистов',
              'специалиста',
            ])}`
          : ''
      }, цены ${
        reviewCount
          ? `и ${reviewCount} ${decl(reviewCount || 0, [
              'отзыв',
              'отзывов',
              'отзыва',
            ])}`
          : ''
      }`,
    description: (title, city, totalCount, reviewCount) =>
      `${title || 'Услуги'} ${city || 'в Беларуси'} ⭐️ ${
        totalCount || ''
      } проверенных специалистов на MasterYoda ${
        reviewCount
          ? `⭕️ ${reviewCount} ${decl(reviewCount || 0, [
              'отзыв',
              'отзывов',
              'отзыва',
            ])}`
          : ''
      } ✔️ Гарантия на выполненную работу и услуги ⏩ Платите мастеру напрямую`,
  },
  blog: {
    title: 'Блог MasterYoda',
    description:
      '📰 Статьи с экспертизой специалистов на все случаи жизни: 🛠 мастера по ремонту, 📚 репетиторы, 🧠 психологи и т.д. Читай!)',
  },
  profile: {
    title: 'Личный кабинет пользователя',
    description:
      '✔📋 Личный кабинет пользователя, где вы можете просматривать заказы и взаимодействовать с ними. 📑 Подробная информация о пользователе, специальность, навыки, опыт, отзывы',
  },
  signup: {
    title: 'Стать мастером',
    description:
      '✔📋 Заполните профиль и получайте новые заказы прямо в Viber/Telegram 📥',
  },
  customer: {
    title: (name, lastName, city, orderRating, ordersCount, firstDayOnline) =>
      !name
        ? `Заказчик${
            firstDayOnline ? ` с ${firstDayOnline}` : ''
          } на MasterYoda${city ? ` в городе ${city}` : ''}${
            orderRating ? ` ☆ ${orderRating}` : ''
          }${
            ordersCount
              ? ` | ${ordersCount} ${decl(ordersCount || 0, [
                  'заказ',
                  'заказов',
                  'заказа',
                ])}`
              : ''
          }`
        : `${name}${lastName ? ` ${lastName}` : ''} - заказчик${
            firstDayOnline ? ` с ${firstDayOnline}` : ''
          } на MasterYoda${city ? ` в городе ${city} ` : ''}${
            orderRating ? ` ☆ ${orderRating} ` : ''
          }${
            ordersCount
              ? ` | ${ordersCount} ${decl(ordersCount || 0, [
                  'заказ',
                  'заказов',
                  'заказа',
                ])}`
              : ''
          }`,
    description: (
      name,
      lastName,
      city,
      orderRating,
      ordersCount,
      firstDayOnline,
    ) =>
      `${name ? `${name} ` : ''}${lastName ? `${lastName} ` : ''}✅ Заказчик ${
        city ? `в городе ${city} ` : ''
      }на MasterYoda.${orderRating ? ` ☆ ${orderRating}` : ''}${
        firstDayOnline ? ` 🏁 На сайте с ${firstDayOnline} ` : ''
      }${
        ordersCount
          ? ` | Заказал ${ordersCount} ${decl(ordersCount || 0, [
              'работа',
              'работ',
              'работы',
            ])}.`
          : ''
      } 💬 Проверенные заказчики на MasterYoda с честными отзывами.`,
  },

  order: {
    title: (title, city) =>
      `${title || 'Заказ'}${
        city ? ` в городе ${city}` : ' в Беларуси'
      } на MasterYoda.`,
    description: (jobItem, city) =>
      `✅ ${jobItem || 'Заказ'}${
        city ? ` в городе ${city} ` : ' в Беларуси'
      } на MasterYoda. 💬 Проверенные заказчики на MasterYoda с честными отзывами.`,
  },
  tender: {
    title: (title, city) =>
      `${title || 'Заявка на заказ'}${
        city ? ` в городе ${city}` : ' в Беларуси'
      } на MasterYoda.`,
    description: (jobItem, city) =>
      `✅ ${jobItem || 'Заявка на заказ'}${
        city ? ` в городе ${city} ` : ' в Беларуси'
      } на MasterYoda. 💬 Проверенные заказчики на MasterYoda с честными отзывами.`,
  },
  becomePerformer: {
    title: 'Как стать мастером на MasterYoda ⁉',
    description:
      '🔎 MasterYoda - поиск подработки и работы в Беларуси. На MasterYoda вы найдёте подработку и работу от прямых работодателей по своей специальности 💵 Подработка на MasterYoda. 💰 Работа на MasterYoda.',
  },
  becomeCustomer: {
    title: 'Как найти специалиста на MasterYoda',
    description:
      '🔎 MasterYoda - поиск специалистов в Беларуси. 💬 Проверенные исполнители с честными отзывами. ✅ Найти специалиста с MasterYoda - просто.',
  },
  terms: {
    title: 'Пользовательское соглашение MasterYoda.by',
    description:
      'Публичная Оферта (Договор) на оказание услуг сервиса MasterYoda.by ‼',
  },
  privacyPolicy: {
    title: 'Политика конфиденциальности сервиса MasterYoda.by',
    description: 'Политика конфиденциальности сервиса MasterYoda.by ‼',
  },
  pageNotFound: {
    title: 'Страница не найдена',
    description: 'Страница не найдена',
  },
  contacts: {
    title: 'Контакты | Masteryoda - Поиск мастеров в Беларуси',
    description:
      'Нужна помощь? Получите надежную поддержку и помощь от нашей команды специалистов. 📩 Свяжитесь с нами для консультации и ответов на ваши вопросы.',
  },
};

module.exports = meta;
