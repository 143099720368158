import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { saveAuthState, withUrqlClient } from '../../utils/urqlClient';
import { parseGqlError, UNKNOWN_ERROR } from '../../utils/error';
import { LOGIN, LOGIN_BY_BOT } from '../../gql/mutation';
import LoginModalContent from './LoginModalContent.jsx';
import { throwErrorSentry } from '../../utils/throwErrorSentry';
import { useMutationUrql } from '../../utils/urqlClient/helpers';

const LoginModalContainer = ({ onOpenRegister, onClose, refetch }) => {
  const { t } = useTranslation(['common']);
  const [err, setErr] = useState();

  const [addTodo, { loading }] = useMutationUrql(LOGIN, {
    onCompleted: data => {
      if (data?.login?.accessToken && data?.login?.refreshToken) {
        saveAuthState({
          token: data?.login?.accessToken,
          refreshToken: data?.login?.refreshToken,
        });
        refetch();
        onClose();
      }
    },
    onError: error => {
      const parseError = parseGqlError(error?.graphQLErrors, t);
      if (parseError.code === 404)
        parseError.message = t('errors-messages.wrong-login-or-password');
      setErr(parseError);
      if (parseError.type === UNKNOWN_ERROR) throwErrorSentry(error);
    },
  });

  const [loginByBot, { loading: loadingLoginByBot }] = useMutationUrql(
    LOGIN_BY_BOT,
    {
      onCompleted: data => {
        if (data?.loginByBot?.accessToken && data?.loginByBot?.refreshToken) {
          saveAuthState({
            token: data?.loginByBot?.accessToken,
            refreshToken: data?.loginByBot?.refreshToken,
          });
          refetch();
          onClose();
        }
      },
      onError: error => {
        const parseError = parseGqlError(error?.graphQLErrors, t);
        if (parseError.code === 404)
          parseError.message = t('errors-messages.incorrect-code');
        setErr(parseError);
        if (parseError.type === UNKNOWN_ERROR) throwErrorSentry(error);
      },
    },
  );

  return (
    <LoginModalContent
      onOpenRegister={onOpenRegister}
      addTodo={addTodo}
      loginByBot={loginByBot}
      err={err}
      setErr={setErr}
      isLoading={loading || loadingLoginByBot}
    />
  );
};

const WrappedComponent = withUrqlClient()(LoginModalContainer);

export default WrappedComponent;
