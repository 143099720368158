const onlyLettersRegExp = '^(\\s+)?[ёЁА-Яа-яa-zA-Z]{2,30}?(\\s+)?$';
const companyNameRegExp = '^[0-9-" ёЁА-Яа-яa-zA-Z]{2,50}$';
const lettersAndSpaceRegExp =
  '^(\\s+)?[ёЁА-Яа-яa-zA-Z]{2,20}(\\s+)?([ёЁА-Яа-яa-zA-Z]{2,20})?(\\s+)?$';
const passwordRegExp =
  '^(?=.*[a-zа-яё])(?=.*[A-ZА-ЯЁ])(?=.*\\d)[|{_`\\-=+}?№!@#\\$%\\^&\\*\\(\\)\\[\\]:;\'",\\.A-Za-zа-яА-ЯЁё\\d]{8,30}$';
const emailRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const titleRegExp = '^[А-Яа-яa-zA-ZёЁ0-9_!@#№*&.,/?();:\'"=-\\s+]{2,80}$';
const descriptionRegExp = '^[^<>]{2,1500}$';
const numberLetterSymbolRegExp =
  '^[a-zA-ZА-Яа-яёЁ0-9_!.№,?():\'"-\\s+]{2,100}$';
const streetRegExp = '^[a-zA-ZА-Яа-яёЁ0-9_/|.№,():\'"-\\s+]{2,100}$';
const costRegExp = '^[0-9.]{1,10}$';
const unpRegExp = '^[0-9]{1,10}$';
const emailOrUserNameRegExp = '^[a-zA-Z0-9-_+=!#$.@\\s+]{2,100}$';

const emptySpaceRegExp = / +/g;
const noDigitRegExp = /[\D]/g;
const clockRegExp = '^([01][0-9]|2[0-3]):([0-5][0-9])$';

const symbolInPhoneRegExp = /[\s()-]/g;
const symbolInPriceRegExp = /[.]/g;
const lettersEnRuRegExp = /[a-zа-яё]/i;
const searchInputRegExp = '(?:^|\\s)';
const complainRegExp = '^[a-zA-ZА-Яа-я0-9_!.№,?():\'"-\\s+]{2,1000}$';
const allowedTextCharactersRegExp = '_!.№,?():\'"-';

export {
  lettersAndSpaceRegExp,
  companyNameRegExp,
  onlyLettersRegExp,
  passwordRegExp,
  emailRegExp,
  descriptionRegExp,
  numberLetterSymbolRegExp,
  titleRegExp,
  streetRegExp,
  costRegExp,
  unpRegExp,
  emailOrUserNameRegExp,
  emptySpaceRegExp,
  noDigitRegExp,
  clockRegExp,
  symbolInPhoneRegExp,
  symbolInPriceRegExp,
  allowedTextCharactersRegExp,
  complainRegExp,
  lettersEnRuRegExp,
  searchInputRegExp,
};
