import { BELARUS } from 'src/common/constants';
import { deployment } from 'src/config';
import { getCityDataBelarus } from 'src/modules/CitySearchBelarus';
import { getCityDataGoogle } from 'src/modules/CitySearchGoogle';

export const getCityData = cityId => {
  if (cityId === 'all' || !cityId) return null;
  return deployment === BELARUS
    ? getCityDataBelarus(cityId)
    : getCityDataGoogle(cityId);
};
