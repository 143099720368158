export const mapAddress = address => {
  const city = address?.address_components?.find(e =>
    e?.types.includes('locality'),
  );

  const admAreaLevel1 = address?.address_components?.find(e =>
    e?.types.includes('administrative_area_level_1'),
  );

  const cityId = `${city?.long_name}--${admAreaLevel1?.short_name}`.replace(
    /\s+/g,
    '_',
  );

  const index = address?.formatted_address?.indexOf(city?.long_name);
  const street =
    index === 0 ? undefined : address?.formatted_address?.slice(0, index - 2);

  return {
    name: city?.long_name,
    id: cityId,
    street,
    location: {
      latitude: address?.geometry?.location?.lat,
      longitude: address?.geometry?.location?.lng,
    },
  };
};
