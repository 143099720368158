import React from 'react';
import { Box } from '@chakra-ui/react';

const Separator = ({ variant = 'default', ...other }) => {
  const variants = {
    bigLight: {
      bg: 'gray.50',
      h: 2.5,
      my: 8,
      borderRadius: 'lg',
    },
    default: {
      bg: 'gray.300',
      h: 'px',
      my: 8,
    },
  };
  return <Box {...variants[variant]} {...other} />;
};

export default Separator;
