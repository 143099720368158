import React, { useState } from 'react';
import {
  Button,
  Box,
  Input,
  Spinner,
  Stack,
  Heading,
  Img,
  InputGroup,
  InputRightElement,
  Text,
  Flex,
  Checkbox,
  Center,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { deployment } from 'src/config';
import { BELARUS } from 'src/common/constants';
import settings from 'src/common/settings';
import { useGetGeoData } from '../model/useGetGeoData';

const styleResultsBlock = {
  h: 50,
  maxH: '60vh',
  overflowY: 'auto',
  my: 2,
  spacing: 2,
};

const styleSpinner = {
  mx: 'auto',
  thickness: '4px',
  speed: '0.65s',
  emptyColor: 'gray.100',
  color: 'brand.primary',
  size: 'xl',
};

const styleContainer = {
  mt: [0, 0, 8],
  w: '100%',
  p: 4,
};

const styleControlBtn = {
  w: '100%',
  variant: 'primaryBlack',
  'data-testid': 'close-select-city-modal',
};

const styleCheckbox = {
  colorScheme: 'cyan',
  mr: 2,
  size: 'lg',
  bgColor: 'white',
  borderColor: 'gray.200',
};

const styleCheckboxContainer = {
  my: 2,
  alignItems: 'center',
};

const styleElement = {
  variant: 'link',
  display: 'block',
  w: '100%',
  minH: 9,
  h: 'auto',
  whiteSpace: 'pre-wrap',
  textAlign: 'left',
  px: 2.5,
  color: 'typography.secondary',
};

const stylePopularCitiesContainer = {
  flexWrap: 'wrap',
  justifyContent: 'center',
};

const stylePopularCities = {
  bg: 'brand.secondaryBackground',
  size: 'sm',
  borderRadius: '3xl',
  py: [1, 1, 2],
  px: [4, 4, 6],
  m: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxW: [66, 110, 'auto'],
};

const SelectCityModalContent = ({
  onClose,
  saveValue,
  type,
  types,
  clear,
  defaultValue,
}) => {
  const { popularCities } = settings;

  const { t } = useTranslation(['common']);

  const { isLoading, results, onChange } = useGetGeoData();
  const [isRemote, setIsRemote] = useState(!!defaultValue?.isRemote);
  const onSelect = address => {
    if (address === null) {
      saveValue(null);
      onClose();
      return;
    }
    if (address?.isRemote) saveValue({ isRemote: true });
    if (!address?.isRemote) saveValue(address);
    onClose();
  };

  const checkboxHandler = () => {
    if (isRemote) {
      setIsRemote(!isRemote);
      return;
    }
    saveValue({ isRemote: true });
    onClose();
  };

  // placeholder у нас зависит от типа
  // в беларуси тип всегда CITY
  const inputPlaceholder =
    type === types.ADDRESS && deployment !== BELARUS
      ? t('select-address.enter-address')
      : t('select-address.enter-city');

  const header =
    type === types.ADDRESS && deployment !== BELARUS
      ? t('select-address.select-address')
      : t('select-address.select-city');

  return (
    <Box {...styleContainer}>
      <InputGroup>
        <Input
          data-testid='enter-city'
          name='address'
          variant='outline'
          defaultValue={
            defaultValue?.name
              ? `${defaultValue?.name} ${defaultValue?.street || ''}`
              : ''
          }
          placeholder={inputPlaceholder}
          onChange={({ target }) => {
            onChange(target.value);
          }}
        />
        {deployment !== BELARUS && (
          <InputRightElement>
            <Img src='/images/google_on_white.png' alt='google-logo' mr={4} />
          </InputRightElement>
        )}
      </InputGroup>
      {clear && (
        <Button
          {...styleElement}
          onClick={() => onSelect(null)}
          data-testid='clear-city'
        >
          {clear}
        </Button>
      )}

      <Stack {...styleResultsBlock}>
        {isLoading && (
          <Center h='100%'>
            <Spinner {...styleSpinner} />
          </Center>
        )}
        {!isLoading && results?.length && (
          <>
            <Heading as='span' size='lg-xl'>
              {header}
            </Heading>
            {results?.map((e, i) => (
              <Button
                key={e?.id}
                {...styleElement}
                data-testid={e?.id}
                onClick={() => onSelect(results[i])}
              >
                {e?.title}
              </Button>
            ))}
          </>
        )}
        {results && !isLoading && !results?.length && (
          <Heading as='span' size='lg-xl'>
            {t('not-found')}
          </Heading>
        )}
        {!results?.length && (
          <>
            <Heading as='span' size='lg-xl'>
              {t('select-address.popular-cities')}
            </Heading>
            <Flex {...stylePopularCitiesContainer}>
              {popularCities?.map((e, i) => (
                <Button
                  key={e?.id}
                  {...stylePopularCities}
                  data-testid={e?.id}
                  onClick={() => onSelect(popularCities[i])}
                >
                  {e?.name}
                </Button>
              ))}
            </Flex>
          </>
        )}
      </Stack>
      {type === types.ADDRESS && (
        <Flex {...styleCheckboxContainer} data-testid='remote-type'>
          <Checkbox
            {...styleCheckbox}
            value={isRemote}
            defaultChecked={isRemote}
            onChange={checkboxHandler}
          />
          <Text>{t('remote', { ns: 'common' })}</Text>
        </Flex>
      )}
      <Button
        {...styleControlBtn}
        onClick={() => {
          onClose();
        }}
      >
        {t('button.closed')}
      </Button>
    </Box>
  );
};

export default SelectCityModalContent;
