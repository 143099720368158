import { emptySpaceRegExp, searchInputRegExp } from './regExp';

export const search = (arr, val) => {
  const result = [];
  const value = val.toLowerCase().replace(emptySpaceRegExp, ' ').trim();
  arr
    .filter(el => {
      if (el.items) {
        search(el.items, val).forEach(e => result.push(e));
      }
      if (el.name.toLowerCase() === value) return true;
      if (
        new RegExp(`${searchInputRegExp}${value}`).test(el.name.toLowerCase())
      )
        return true;
      if (
        el?.keywords &&
        new RegExp(`${searchInputRegExp}${value}`).test(
          el?.keywords.toLowerCase(),
        )
      )
        result.push({ ...el, keywordStatus: true });
      return false;
    })
    .forEach(e => result.push(e));

  return result;
};

export const searchItems = (arr, val) => {
  const result = [];
  arr?.forEach(el => {
    if (el.items) {
      search(el.items, val).forEach(e => result.push(e));
    }
  });

  return result;
};

export const searchId = (arr, val, prevValue = {}) => {
  let result = prevValue;
  arr?.forEach(el => {
    if (el.id === val) {
      result = el;
    }
    if (el?.items?.length > 0) result = searchId(el.items, val, result);
  });

  return result;
};
