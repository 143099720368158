import React, { useReducer, createContext } from 'react';
import reducer from './reducer';

export * from './helpers';
export * from './actions';

export const executorNtfStore = createContext();
export const ordererNtfStore = createContext();

export const ExecutorNtfProvider = ({ children }) => {
  const [executorNtf, dispatch] = useReducer(reducer, { ntf: {} });

  const value = { executorNtf, dispatch };
  return (
    <executorNtfStore.Provider value={value}>
      {children}
    </executorNtfStore.Provider>
  );
};

export const OrdererNtfProvider = ({ children }) => {
  const [ordererNtf, dispatch] = useReducer(reducer, { ntf: {} });

  const value = { ordererNtf, dispatch };
  return (
    <ordererNtfStore.Provider value={value}>
      {children}
    </ordererNtfStore.Provider>
  );
};
