import { gql } from '@urql/core';
import {
  jobTypeFragments,
  profileFragments,
  orderFragments,
  myTenderFragment,
  messageFragments,
  orderSystemFragments,
  feedbackSystemFragments,
  orderFromBotFragments,
  jobTypeUtpCardFragments,
  botHistoryFragments,
  exampleTaskFragment,
  tariffPlanFragments,
  jobTypeSeoFragments,
  jobTypePriceFromAdminFragments,
  userPricesFragments,
} from '../fragment';

const SIGNUP = gql`
  mutation signup($input: signupInput!) {
    signup(input: $input) {
      user {
        name
        id
      }
      accessToken
      refreshToken
    }
  }
`;

const LOGIN_BY_BOT = gql`
  mutation loginByBot($input: loginByBotInput!) {
    loginByBot(input: $input) {
      user {
        name
        id
        permission
      }
      accessToken
      refreshToken
    }
  }
`;

const LOGIN = gql`
  mutation login($input: loginInput!) {
    login(input: $input) {
      user {
        name
        id
        permission
      }
      accessToken
      refreshToken
    }
  }
`;

const RESTORE_PASSWORD = gql`
  mutation restorePassword($input: restorePasswordInput!) {
    restorePassword(input: $input) {
      user {
        name
        id
        permission
      }
      accessToken
      refreshToken
    }
  }
`;

const CHECK_RECOVERY = gql`
  mutation checkRecovery($input: checkRecoveryInput!) {
    checkRecovery(input: $input) {
      type
    }
  }
`;

const SEND_CONFIRM_EMAIL = gql`
  mutation sendConfirmEmail {
    sendConfirmEmail {
      email
      status
    }
  }
`;

const CONFIRM_EMAIL = gql`
  mutation confirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      email
      status
    }
  }
`;

const LINK_DELETE_PROFILE = gql`
  mutation linkDeleteProfile($input: ConfirmEmailInput!) {
    linkDeleteProfile(input: $input) {
      id
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($input: changePasswordInput!) {
    changePassword(input: $input) {
      user {
        name
        id
      }
      accessToken
      refreshToken
    }
  }
`;

const UPDATE_JOB_TYPE = gql`
  mutation update($input: UpdateJobTypeInput) {
    updateJobType(input: $input) {
      ...JobTypeFrag
    }
  }
  ${jobTypeFragments}
`;

const CREATE_JOB_TYPE = gql`
  mutation create($input: CreateJobTypeInput) {
    createJobType(input: $input) {
      ...JobTypeFrag
    }
  }
  ${jobTypeFragments}
`;

const DELETE_JOB_TYPE = gql`
  mutation remove($input: DeleteJobTypeInput) {
    removeJobType(input: $input) {
      id
    }
  }
`;

const UPDATE_TARIFF = gql`
  mutation updateTariffPlans($input: UpdateTariffInput) {
    updateTariffPlans(input: $input) {
      ...TariffPlanFrag
    }
  }
  ${tariffPlanFragments}
`;

const CREATE_TARIFF = gql`
  mutation createTariffPlans($input: CreateTariffInput) {
    createTariffPlans(input: $input) {
      ...TariffPlanFrag
    }
  }
  ${tariffPlanFragments}
`;

const DELETE_TARIFF = gql`
  mutation deleteTariffPlans($input: DeleteTariffInput) {
    deleteTariffPlans(input: $input) {
      id
    }
  }
`;

const CREATE_JOB_TYPE_UTP_CARDS = gql`
  mutation createJobTypeUtpCard($input: CreateJobTypeUtpCardInput) {
    createJobTypeUtpCard(input: $input) {
      ...JobTypeUtpCardFrag
    }
  }
  ${jobTypeUtpCardFragments}
`;

const UPDATE_JOB_TYPE_UTP_CARDS = gql`
  mutation updateJobTypeUtpCard($input: UpdateJobTypeUtpCardInput) {
    updateJobTypeUtpCard(input: $input) {
      ...JobTypeUtpCardFrag
    }
  }
  ${jobTypeUtpCardFragments}
`;

const DELETE_JOB_TYPE_UTP_CARDS = gql`
  mutation removeJobTypeUtpCard($id: String) {
    removeJobTypeUtpCard(id: $id) {
      id
    }
  }
`;

const CREATE_JOB_TYPE_SEO = gql`
  mutation createJobTypeSeo($input: CreateJobTypeSeoInput) {
    createJobTypeSeo(input: $input) {
      ...JobTypeSeoFrag
    }
  }
  ${jobTypeSeoFragments}
`;

const UPDATE_JOB_TYPE_SEO = gql`
  mutation updateJobTypeSeo($input: UpdateJobTypeSeoInput) {
    updateJobTypeSeo(input: $input) {
      ...JobTypeSeoFrag
    }
  }
  ${jobTypeSeoFragments}
`;

const DELETE_JOB_TYPE_SEO = gql`
  mutation removeJobTypeSeo($id: String) {
    removeJobTypeSeo(id: $id) {
      id
    }
  }
`;

const CREATE_USER_PRICES = gql`
  mutation createUserPrices($input: CreateUserPricesInput) {
    createUserPrices(input: $input) {
      userPriceList {
        ...UserPricesFrag
      }
    }
  }
  ${userPricesFragments}
`;

const CREATE_PRICE = gql`
  mutation createPrice($input: CreatePriceInput) {
    createPrice(input: $input) {
      ...JobTypePriceFromAdminFrag
    }
  }
  ${jobTypePriceFromAdminFragments}
`;

const UPDATE_PRICE = gql`
  mutation updatePrice($input: UpdatePriceInput) {
    updatePrice(input: $input) {
      ...JobTypePriceFromAdminFrag
    }
  }
  ${jobTypePriceFromAdminFragments}
`;

const DELETE_PRICE = gql`
  mutation removePrice($id: String) {
    removePrice(id: $id) {
      id
    }
  }
`;

const DELETE_COMPLAINTS = gql`
  mutation deleteComplaints($input: DeleteComplaintsInput) {
    deleteComplaints(input: $input) {
      id
    }
  }
`;

const DELETE_APPROVED_ORDER_COMPLAINTS = gql`
  mutation deleteApprovedOrderComplaints($input: DeleteComplaintsInput) {
    deleteApprovedOrderComplaints(input: $input) {
      id
    }
  }
`;

const DELETE_ORDER_SYSTEM = gql`
  mutation deleteOrderSystem($input: DeleteOrderInput) {
    deleteOrderSystem(input: $input) {
      id
    }
  }
`;

const UPDATE_PROFILE = gql`
  mutation updateProfile($input: UpdateProfileInput) {
    updateProfile(input: $input) {
      ...ProfileFrag
    }
  }
  ${profileFragments}
`;

const CREATE_ORDER = gql`
  mutation createOrder($input: OrderInput) {
    createOrder(input: $input) {
      ...OrderFrag
    }
  }
  ${orderFragments}
`;

const CREATE_ORDER_FROM_BOT = gql`
  mutation createOrderFromBot($input: CreateOrderFromBotInput) {
    createOrderFromBot(input: $input) {
      ...OrderFromBotFrag
    }
  }
  ${orderFromBotFragments}
`;
const UPDATE_ORDER = gql`
  mutation updateOrder($input: UpdateInput) {
    updateOrder(input: $input) {
      ...OrderFrag
    }
  }
  ${orderFragments}
`;

const CREATE_TENDER = gql`
  mutation createTender($input: CreateTenderInput) {
    createTender(input: $input) {
      ...TenderFrag
    }
  }
  ${myTenderFragment}
`;

const CANCEL_TENDER = gql`
  mutation cancelTender($id: String) {
    cancelTender(id: $id) {
      id
    }
  }
`;

const CANCEL_PROPOSUAL = gql`
  mutation cancelProposual($id: String) {
    cancelProposual(id: $id) {
      id
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation update($input: MessageInput) {
    sendMessage(input: $input) {
      ...MessageFrag
    }
  }
  ${messageFragments}
`;

const SEND_TO_MESSENGER_CHAT = gql`
  mutation sendToMessengerChat($input: SendToMessengerChatInput) {
    sendToMessengerChat(input: $input) {
      ...BotHistoryFrag
    }
  }
  ${botHistoryFragments}
`;

const CANCEL_ORDER = gql`
  mutation cancelOrder($input: CancelOrderInput) {
    cancelOrder(input: $input) {
      id
    }
  }
`;

const APPROVE_TENDER = gql`
  mutation approveTender($input: ApproveTenderInput) {
    approveTender(input: $input) {
      id
    }
  }
`;

const APPROVE_PROPOSUAL = gql`
  mutation approveProposual($input: ApproveTenderInput) {
    approveProposual(input: $input) {
      id
    }
  }
`;

const APPROVE_COMPLETE_ORDER = gql`
  mutation approveCompleteOrder($input: CompleteOrderInput) {
    approveCompleteOrder(input: $input) {
      id
    }
  }
`;

const SEND_READ = gql`
  mutation sendRead($id: String) {
    sendRead(id: $id) {
      id
    }
  }
`;

const SEND_TO_SERVICES_CHAT = gql`
  mutation sendToServicesChat($input: SendToServicesChatInput) {
    sendToServicesChat(input: $input) {
      status
    }
  }
`;

const COMPLETE_ORDER = gql`
  mutation completeOrder($input: CompleteOrderInput) {
    completeOrder(input: $input) {
      id
    }
  }
`;

const SEND_FEEDBACK = gql`
  mutation sendFeedback($input: SendFeedbackInput) {
    sendFeedback(input: $input) {
      id
    }
  }
`;

const DELETE_PROFILE = gql`
  mutation deleteProfile($input: DeleteProfileInput) {
    deleteProfile(input: $input) {
      id
    }
  }
`;

const UPDATE_BOT_SUBSCRIPTION_SETTINGS = gql`
  mutation updateBotSubscriptionSettings($input: UpdateSettingsInput) {
    updateBotSubscriptionSettings(input: $input) {
      id
    }
  }
`;

const SET_READ_ENTITY_NOTIFICATION = gql`
  mutation setReadEntityNtf($input: SetReadEntityNotificationInput) {
    setReadEntityNotification(input: $input) {
      id
    }
  }
`;

const SET_READ_NOTIFICATION = gql`
  mutation setReadNotification($input: SetReadNotificationInput) {
    setReadNotification(input: $input) {
      id
    }
  }
`;

const RECORD_USER_SEARCHES = gql`
  mutation recordUserSearch($input: RecordUserSearchInput) {
    recordUserSearch(input: $input) {
      id
      status
      count
      text
      type
      createdAt
    }
  }
`;

const UPDATE_USER_SEARCHES = gql`
  mutation updateUserSearchStatus($input: UpdateUserSearchStatusInput) {
    updateUserSearchStatus(input: $input) {
      id
      status
      count
      text
      type
      createdAt
    }
  }
`;

const GET_VIBER_TOKEN = gql`
  mutation getViberToken {
    getViberToken {
      token
    }
  }
`;

const GET_FACEBOOK_MESSENGER_TOKEN = gql`
  mutation getFbMessengerToken {
    getFbMessengerToken {
      token
    }
  }
`;

const SUBSCRIBE_EMAIL = gql`
  mutation subscribeEmail($input: SubscribeEmailInput) {
    subscribeEmail(input: $input) {
      id
    }
  }
`;

const GET_TELEGRAM_TOKEN = gql`
  mutation getTelegramToken {
    getTelegramToken {
      token
    }
  }
`;
const UNSUBSCRIBE_VIBER = gql`
  mutation unsubscribeViber {
    unsubscribeViber {
      id
    }
  }
`;

const UNSUBSCRIBE_FACEBOOK_MESSENGER = gql`
  mutation unsubscribeFbMessenger {
    unsubscribeFbMessenger {
      id
    }
  }
`;

const UNSUBSCRIBE_EMAIL = gql`
  mutation unsubscribeEmail {
    unsubscribeEmail {
      id
    }
  }
`;

const UNSUBSCRIBE_EMAIL_NOTIFICATION = gql`
  mutation unsubscribeEmailNotification($input: UnsubscribeEmailInput) {
    unsubscribeEmailNotification(input: $input) {
      email
    }
  }
`;

const UNSUBSCRIBE_TELEGRAM = gql`
  mutation unsubscribeTelegram {
    unsubscribeTelegram {
      id
    }
  }
`;

const COMPLAIN_ORDER = gql`
  mutation complainOrder($input: ComplainOrderInput) {
    complainOrder(input: $input) {
      id
    }
  }
`;

const COMPLAIN_APPROVED_ORDER = gql`
  mutation complainApprovedOrder($input: ComplainOrderInput) {
    complainApprovedOrder(input: $input) {
      id
    }
  }
`;

const UPDATE_ORDER_SYSTEM = gql`
  mutation updateOrderSystem($input: OrderUpdateInput) {
    updateOrderSystem(input: $input) {
      ...OrderSystemFrag
    }
  }
  ${orderSystemFragments}
`;

const UPDATE_FEEDBACK_SYSTEM = gql`
  mutation updateFeedbackSystem($input: UpdateFeedbackInput) {
    updateFeedbackSystem(input: $input) {
      ...FeedbackSystemFrag
    }
  }
  ${feedbackSystemFragments}
`;

const DELETE_FEEDBACK_SYSTEM = gql`
  mutation deleteFeedbackSystem($input: DeleteFeedbackInput) {
    deleteFeedbackSystem(input: $input) {
      id
    }
  }
`;

const CREATE_EXAMPLE_TASK = gql`
  mutation createExampleTask($input: CreateExampleTaskInput) {
    createExampleTask(input: $input) {
      ...ExampleTaskFrag
    }
  }
  ${exampleTaskFragment}
`;

const UPDATE_EXAMPLE_TASK = gql`
  mutation updateExampleTask($input: UpdateExampleTaskInput) {
    updateExampleTask(input: $input) {
      ...ExampleTaskFrag
    }
  }
  ${exampleTaskFragment}
`;

const DELETE_EXAMPLE_TASK = gql`
  mutation removeExampleTask($id: String) {
    removeExampleTask(id: $id) {
      id
    }
  }
`;

export {
  UPDATE_ORDER,
  DELETE_FEEDBACK_SYSTEM,
  UPDATE_FEEDBACK_SYSTEM,
  UPDATE_ORDER_SYSTEM,
  COMPLAIN_ORDER,
  COMPLAIN_APPROVED_ORDER,
  SUBSCRIBE_EMAIL,
  UNSUBSCRIBE_VIBER,
  UNSUBSCRIBE_TELEGRAM,
  UNSUBSCRIBE_EMAIL,
  GET_VIBER_TOKEN,
  GET_TELEGRAM_TOKEN,
  UPDATE_USER_SEARCHES,
  RECORD_USER_SEARCHES,
  SIGNUP,
  LOGIN,
  LOGIN_BY_BOT,
  CHANGE_PASSWORD,
  UPDATE_JOB_TYPE,
  DELETE_JOB_TYPE,
  CREATE_JOB_TYPE,
  UPDATE_PROFILE,
  CREATE_JOB_TYPE_UTP_CARDS,
  UPDATE_JOB_TYPE_UTP_CARDS,
  DELETE_JOB_TYPE_UTP_CARDS,
  CREATE_JOB_TYPE_SEO,
  UPDATE_JOB_TYPE_SEO,
  DELETE_JOB_TYPE_SEO,
  CREATE_USER_PRICES,
  CREATE_PRICE,
  UPDATE_PRICE,
  DELETE_PRICE,
  CREATE_ORDER,
  CREATE_ORDER_FROM_BOT,
  CREATE_TENDER,
  CANCEL_TENDER,
  CANCEL_PROPOSUAL,
  SEND_MESSAGE,
  CANCEL_ORDER,
  SEND_READ,
  COMPLETE_ORDER,
  APPROVE_TENDER,
  APPROVE_PROPOSUAL,
  APPROVE_COMPLETE_ORDER,
  SEND_FEEDBACK,
  DELETE_PROFILE,
  LINK_DELETE_PROFILE,
  SET_READ_ENTITY_NOTIFICATION,
  SET_READ_NOTIFICATION,
  UPDATE_BOT_SUBSCRIPTION_SETTINGS,
  RESTORE_PASSWORD,
  CHECK_RECOVERY,
  SEND_CONFIRM_EMAIL,
  CONFIRM_EMAIL,
  SEND_TO_SERVICES_CHAT,
  UNSUBSCRIBE_EMAIL_NOTIFICATION,
  DELETE_COMPLAINTS,
  DELETE_ORDER_SYSTEM,
  DELETE_APPROVED_ORDER_COMPLAINTS,
  SEND_TO_MESSENGER_CHAT,
  CREATE_EXAMPLE_TASK,
  UPDATE_EXAMPLE_TASK,
  DELETE_EXAMPLE_TASK,
  CREATE_TARIFF,
  UPDATE_TARIFF,
  DELETE_TARIFF,
  GET_FACEBOOK_MESSENGER_TOKEN,
  UNSUBSCRIBE_FACEBOOK_MESSENGER,
};
