import React from 'react';
import LoginModalContainer from './LoginModalContainer.jsx';
import Modal from '../Modal';

const LoginModal = ({ onClose, isOpen, onOpenRegister, refetch }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <LoginModalContainer onClose={onClose} onOpenRegister={onOpenRegister} refetch={refetch} />
        </Modal>
    )
}

export default LoginModal;
