import React, { useEffect, useState } from 'react';
import { Button, Text, Heading, Flex, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import { UNSUBSCRIBE_EMAIL_NOTIFICATION } from '../../gql/mutation';
import { onCloseModal } from '../../utils/modalControl';
import { throwErrorSentry } from '../../utils/throwErrorSentry';
import { useMutationUrql } from '../../utils/urqlClient/helpers';

const styleCloseButton = {
  mt: 5,
  w: '100%',
  variant: 'primary',
};

const styleSettingsButton = {
  mt: 5,
  w: '100%',
  variant: 'secondary',
};

const styleHeading = {
  as: 'h3',
  size: '1-2xl',
  textAlign: 'center',
};

const styleText = {
  mb: 5,
  color: 'typography.secondary',
  textAlign: 'center',
};

const styleContainer = {
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const UnsubscribeEmailNotification = ({ token, router }) => {
  const { t } = useTranslation(['common']);
  const [state, setState] = useState({ status: 'LOADING' });

  const [unsubscribe] = useMutationUrql(UNSUBSCRIBE_EMAIL_NOTIFICATION, {
    onCompleted: data => {
      setState({
        status: 'COMPLETED',
        email: data?.unsubscribeEmailNotification?.email,
      });
    },
    onError: error => {
      throwErrorSentry(error);
      setState({ status: 'ERROR' });
    },
  });

  useEffect(() => {
    if (token)
      unsubscribe({
        input: {
          token,
        },
      });
  }, []);

  const Content = ({ status }) => {
    switch (status) {
      case 'LOADING':
        return (
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.100'
            color='brand.primary'
            size='xl'
          />
        );
      case 'COMPLETED':
        return (
          <Flex {...styleContainer}>
            <Heading {...styleHeading}>
              {t('unsubscribe-email-notification-modal.title', {
                email: state?.email,
              })}
            </Heading>
            <Text {...styleText}>
              {t('unsubscribe-email-notification-modal.description')}
            </Text>
            <Button
              {...styleSettingsButton}
              onClick={() => routerPush(router, '/profile?index=settings')}
            >
              {t('button.settings')}
            </Button>
          </Flex>
        );
      case 'ERROR':
        return (
          <>
            <Heading {...styleHeading}>
              {t('unsubscribe-email-notification-modal.error')}
            </Heading>
            <Text {...styleText}>
              {t('unsubscribe-email-notification-modal.subscription-not-found')}
            </Text>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Flex {...styleContainer} minH={50}>
      <Content status={state?.status} />
      <Button
        {...styleCloseButton}
        onClick={() => onCloseModal(router, 'unsubscribe')}
      >
        {t('button.closed')}
      </Button>
    </Flex>
  );
};

export default UnsubscribeEmailNotification;
