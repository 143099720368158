import { defineStyleConfig } from '@chakra-ui/react';

const labelVariant = {
  fontWeight: 'semibold',
  noOfLines: 1,
};

const sizes = {
  xl: {
    fontWeight: 'medium',
    fontSize: 'xl',
  },
};

export default defineStyleConfig({ sizes, variants: { label: labelVariant } });
