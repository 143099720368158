import {
  SET_MOBILE,
  BECOME_PERFORMER,
  SET_FILTER_QUERY_STRING,
  CLEAR_FILTER_QUERY_STRING,
  SET_CITY,
  // IS_QUIZ,
} from './constants';
import localStorage from '../../utils/localStorage';

export const setMobile = async (dispatch, data) => {
  dispatch({
    type: SET_MOBILE,
    data,
  });
};

export const setCity = async (dispatch, data) => {
  dispatch({
    type: SET_CITY,
    data,
  });
};

export const setBecomePerformer = async (dispatch, data) => {
  localStorage.setItem('becomePerformer', data);
  dispatch({
    type: BECOME_PERFORMER,
    data,
  });
};

export const setFilterQueryString = async (dispatch, data) => {
  dispatch({
    type: SET_FILTER_QUERY_STRING,
    data,
  });
};

export const clearFilterQueryString = async (dispatch, data) => {
  dispatch({
    type: CLEAR_FILTER_QUERY_STRING,
    data,
  });
};
