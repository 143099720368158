import React from 'react';
import { Icon } from '@chakra-ui/react';

const LocationIcon = props => (
  <Icon viewBox='0 0 430 512' {...props}>
    <path
      d='M215.104 512.172L200.233 499.425C179.732 482.259 0.724609 327.662 0.724609 214.578C0.724609 96.1802 96.7056 0.199219 215.104 0.199219C333.502 0.199219 429.483 96.1802 429.483 214.578C429.483 327.663 250.476 482.26 230.06 499.51L215.104 512.172ZM215.104 35C122.351 35.105 39.105 121.825 39 214.578C39 285.62 157.213 412.057 215.104 464C272.996 412.036 395.5 285.533 395.5 214.577C395.395 121.824 307.858 35.106 215.104 35Z'
      fill='black'
    />
    <path
      d='M215.104 299.558C168.172 299.558 130.126 261.512 130.126 214.58C130.126 167.648 168.172 129.602 215.104 129.602C262.036 129.602 300.082 167.648 300.082 214.58C300.082 261.512 262.037 299.558 215.104 299.558ZM215 162C185.5 162 163 183 163 214C163 242.5 183.5 267 215 267C244 267 268 244 268 214.5C268 186.5 247.5 162 215 162Z'
      fill='black'
    />
  </Icon>
);
export default LocationIcon;
