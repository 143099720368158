import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Img,
  FormControl,
  Box,
  Text,
  HStack,
  VStack,
  PinInput,
  PinInputField,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { useRouter } from 'next/router';
import { v4 } from 'uuid';
import { useTranslation } from 'next-i18next';
import settings from 'src/common/settings';
import useModalPaddings from 'src/hooks/useModalPaddings';
import { MessengerIcon, TelegramIcon, ViberIcon } from 'src/icons';
import NextLink from '../NextLink';
import { emailOrUserNameRegExp } from '../../utils/regExp';
import Separator from '../Separator';
import useToast from '../../hooks/useToast';
import {
  fbMessengerBotName,
  telegramBotName,
  viberBotName,
} from '../../config';
import { TELEGRAM, VIBER, FACEBOOK_MESSENGER } from '../../common/constants';
import ErrorMessageContainer from '../AccordionItem/ErrorMessageContainer';

const styleBackBtn = {
  variant: 'secondary',
  w: '100%',
};

const styleHeaderContainer = {
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'center',
  'data-testid': 'login-heading',
  mb: 4,
};

const styleViberButton = {
  variant: 'primary',
  w: '100%',
  leftIcon: <ViberIcon />,
  bg: 'brand.viber',
};

const styleFbMessengerButton = {
  variant: 'primary',
  w: '100%',
  leftIcon: <MessengerIcon />,
  bg: 'brand.messenger',
};

const styleTelegramBtn = {
  variant: 'primary',
  w: '100%',
  leftIcon: <TelegramIcon />,
  bg: 'brand.telegram',
};

const styleLoginBtn = isLoading => ({
  w: '100%',
  isLoading,
  variant: 'primary',
  type: 'submit',
  'data-testid': 'inputSubmit',
});

const styleSignUpBtn = {
  color: 'brand.primary',
  variant: 'link',
  fontSize: 'sm',
};

const styleCodeInput = err => ({
  size: 'md',
  type: 'number',
  textAlign: 'center',
  variant: err ? 'error' : 'outline',
  fontSize: 'md',
});

const styleEmailInput = err => ({
  key: 'reg_emailOrUserName',
  id: 'reg_emailOrUserName',
  name: 'emailOrUserName',
  variant: err ? 'error' : 'outline',
});

const stylePasswordInput = (err, show) => ({
  key: 'reg_password',
  id: 'reg_password',
  variant: err ? 'error' : 'outline',
  name: 'password',
  type: show ? 'text' : 'password',
});

const styleShowPasswordBtn = show => ({
  'aria-label': 'Show password',
  size: 'md',
  icon: <Img w='24px' src={show ? '/icons/eye.svg' : '/icons/eyeon.svg'} />,
});

const LoginModalContent = ({
  onOpenRegister,
  addTodo,
  loginByBot,
  err,
  setErr,
  isLoading,
}) => {
  const { brand } = settings;

  const { t } = useTranslation(['common']);
  const { showErrorToast } = useToast();
  const queryErrors = {};
  const [show, setShow] = useState(false);
  const [isOpenTelegram, setIsOpenTelegram] = useState(false);
  const [authToken, setAuthToken] = useState(v4());
  const [loginFromBot, setLoginFromBot] = useState(false);
  const router = useRouter();
  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { submitCount, isValid },
    getValues,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (err?.message) {
      showErrorToast(err?.message);
    }
  }, [err]);

  const onSubmit = ({ emailOrUserName, password }) => {
    setErr();
    addTodo({
      input: {
        userName: emailOrUserName.trim(),
        password: password.trim(),
      },
    });
  };

  const loginByBotSubmit = () => {
    const code = getValues('code');
    if (!code || code?.length < 4) {
      setErr({ code: true });
      return;
    }
    setErr();
    handleSubmit(() =>
      loginByBot({
        input: {
          code,
          token: authToken,
        },
      }),
    )();
  };

  if (err?.field) {
    errors[err.field] = err;
  } else {
    queryErrors.common = err;
  }

  const handleClick = () => setShow(!show);

  const login = messenger => {
    const token = v4();
    if (messenger !== FACEBOOK_MESSENGER) {
      setAuthToken(token);
    }

    switch (messenger) {
      case VIBER:
        setIsOpenTelegram(false);
        router.push(
          `viber://pa?chatURI=${viberBotName}&context=login--${token}`,
        );
        break;
      case TELEGRAM:
        setIsOpenTelegram(true);
        setTimeout(
          () =>
            router.push(
              `tg://resolve?domain=${telegramBotName}&start=login--${token}`,
            ),
          1500,
        );
        break;
      case FACEBOOK_MESSENGER:
        setIsOpenTelegram(false);
        break;
      default:
        break;
    }

    setLoginFromBot(true);
  };

  useEffect(() => {
    if (!submitCount && isValid && loginFromBot) {
      const code = getValues('code');
      loginByBotSubmit({ code });
    }
  }, [submitCount, isValid]);

  const boxPaddings = useModalPaddings();

  return (
    <Box {...boxPaddings}>
      <Flex {...styleHeaderContainer}>
        <Heading as='h2' size='lg' mr='10px'>
          {t('login-modal.login-in')}{' '}
        </Heading>
        <Heading as='h2' size='lg' color='brand.primary'>
          {brand}
        </Heading>
      </Flex>
      {!loginFromBot ? (
        <>
          <FormControl mt={5} as='form'>
            <Stack spacing={5}>
              <Box>
                <Input
                  data-testid='email-input'
                  placeholder={t('register-block.email-or-login')}
                  ref={register({
                    pattern: {
                      value: new RegExp(`${emailOrUserNameRegExp}`),
                      message: t('invalid-characters-for-email-or-login'),
                    },
                    required: t('obligatory-field'),
                  })}
                  {...styleEmailInput(errors.emailOrUserName || err)}
                />
                <ErrorMessageContainer errors={errors} name='emailOrUserName' />
              </Box>
              <Box>
                <InputGroup>
                  <Input
                    data-testid='password-input'
                    placeholder={t('register-block.password')}
                    ref={register({
                      required: t('obligatory-field'),
                    })}
                    {...stylePasswordInput(errors.password || err, show)}
                  />
                  <InputRightElement onMouseDown={e => e.preventDefault()}>
                    <IconButton
                      {...styleShowPasswordBtn(show)}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
                <ErrorMessageContainer errors={errors} name='password' />
              </Box>
              <Flex justifyContent='end'>
                <NextLink style={{ width: 'fit-content' }} href={'/recovery'}>
                  <Text
                    data-testid='password-forget'
                    as='div'
                    w='auto'
                    fontSize='sm'
                  >
                    {t('login-modal.forgot-your-password')}
                  </Text>
                </NextLink>
              </Flex>
              <Button
                {...styleLoginBtn(isLoading)}
                onClick={handleSubmit(onSubmit)}
              >
                {t('button.continue')}
              </Button>
              <Separator my={0} />
              {telegramBotName && (
                <Button
                  data-testid='login-tg'
                  {...styleTelegramBtn}
                  onClick={() => login(TELEGRAM)}
                >
                  {t('login-modal.login-by', { messenger: 'Telegram' })}
                </Button>
              )}
              {viberBotName && (
                <Button
                  data-testid='login-viber'
                  {...styleViberButton}
                  onClick={() => login(VIBER)}
                >
                  {t('login-modal.login-by', { messenger: 'Viber' })}
                </Button>
              )}
              {fbMessengerBotName && (
                <NextLink
                  href={`https://m.me/${fbMessengerBotName}/?ref=login--${authToken}`}
                  target='_blank'
                >
                  <Button
                    data-testid='login-messenger'
                    {...styleFbMessengerButton}
                    onClick={() => login(FACEBOOK_MESSENGER)}
                  >
                    {t('login-modal.login-by', { messenger: 'Messenger' })}
                  </Button>
                </NextLink>
              )}
            </Stack>
          </FormControl>
          {/* <Separator my={5} /> */}
          <Flex justifyContent='center' mt={5} mb={3}>
            <Text as='span' mr={1} fontSize='sm'>
              {t('login-modal.do-not-have-an-account-yet')}
            </Text>
            <Button
              data-testid='get-registration'
              {...styleSignUpBtn}
              onClick={onOpenRegister}
            >
              {t('button.signup')}
            </Button>
          </Flex>
        </>
      ) : (
        <VStack mt={5} spacing={3}>
          {isOpenTelegram && (
            <VStack>
              <Img
                w='90%'
                src={'/deployment/images/telegramStart.png'}
                borderRadius='lg'
              />
              <Text textAlign='center'>
                <Button variant='link' onClick={() => login(TELEGRAM)}>
                  {t('login-modal.telegram-login-message.button')}
                </Button>
                {t('login-modal.telegram-login-message.text')}
              </Text>
            </VStack>
          )}
          <Text mt={10}>{t('login-modal.enter-code-authorization')}</Text>
          <Box mb={3}>
            <Controller
              control={control}
              name='code'
              rules={{
                required: t('obligatory-field'),
                minLength: {
                  value: 4,
                  message: t('at-least-count-characters', { count: 4 }),
                },
                maxLength: {
                  value: 4,
                  message: t('no-more-than-count-characters', { count: 4 }),
                },
              }}
              render={({ ref, ...restField }) => (
                <HStack>
                  <PinInput {...restField} {...styleCodeInput(err)}>
                    <PinInputField
                      ref={{
                        ...ref,
                      }}
                    />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              )}
            />
          </Box>

          <Button
            {...styleLoginBtn(isLoading)}
            loadingText={t('button.log-in')}
            onClick={loginByBotSubmit}
          >
            {t('button.log-in')}
          </Button>
          <Button {...styleBackBtn} onClick={() => setLoginFromBot(false)}>
            {t('button.back')}
          </Button>
        </VStack>
      )}
    </Box>
  );
};

export default LoginModalContent;
