import React from 'react';
import { Box, Toast, useToast as useNotificationToast } from '@chakra-ui/react';

const useToast = () => {
  const toast = useNotificationToast();
  const showErrorToast = title => {
    toast({
      title,
      status: 'error',
      duration: 4000,
      isClosable: true,
      render: props => (
        <Box data-testid='error-toast'>
          <Toast {...props} />
        </Box>
      ),
    });
  };

  const showSuccessToast = title => {
    toast({
      title,
      status: 'success',
      duration: 4000,
      isClosable: true,
      render: props => (
        <Box data-testid='success-toast'>
          <Toast {...props} />
        </Box>
      ),
    });
  };

  const showInfoToast = title => {
    toast({
      title,
      status: 'info',
      duration: 4000,
      isClosable: true,
      render: props => (
        <Box data-testid='info-toast'>
          <Toast {...props} />
        </Box>
      ),
    });
  };

  return {
    showErrorToast,
    showSuccessToast,
    showInfoToast,
  };
};

export default useToast;
