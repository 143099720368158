const linksToImg = [
  {
    img: '/icons/Telegram.svg',
    alt: 'Telegram',
    url: 'https://t.me/master_yoda_by_bot',
  },
  {
    img: '/icons/Odnoklassniki.svg',
    alt: 'Odnoklassniki',
    url: 'https://ok.ru',
  },
  {
    img: '/icons/Vk.svg',
    alt: 'Vkontakte',
    url: 'https://vk.com/mysteryodaby',
  },
  {
    img: '/icons/Insta.svg',
    alt: 'Instagram',
    url: 'https://www.instagram.com/masteryoda.by',
  },
];

const email = 'support@masteryoda.by';

const phone = '291675331';

const address = 'ул. М.Л. Мошенского 85, Брест 224022, Беларусь';

const brand = 'MasterYoda';

const defaultJobTypeid = 'master-na-chas';

const currency = 'Br';

const countryKey = 'BY';

const popularCities = [
  {
    id: 'minsk--minskaya_oblast',
    name: 'Минск',
    regionName: 'Минская область',
    location: {
      latitude: 53.9045398,
      longitude: 27.5615244,
    },
  },
  {
    id: 'brest--brestskaya_oblast',
    name: 'Брест',
    regionName: 'Брестская область',
    location: {
      latitude: 52.0976214,
      longitude: 23.7340503,
    },
  },
  {
    id: 'vitebsk--vitebskaya_oblast',
    name: 'Витебск',
    regionName: 'Витебская область',
    location: {
      latitude: 55.1848061,
      longitude: 30.201622,
    },
  },
  {
    id: 'gomel--gomelskaya_oblast',
    name: 'Гомель',
    regionName: 'Гомельская область',
    location: {
      latitude: 52.4411761,
      longitude: 30.9878462,
    },
  },
  {
    id: 'grodno--grodnenskaya_oblast',
    name: 'Гродно',
    regionName: 'Гродненская область',
    location: {
      latitude: 53.6693538,
      longitude: 23.8131306,
    },
  },
  {
    id: 'mogilev--mogilevskaya_oblast',
    name: 'Могилев',
    regionName: 'Могилевская область',
    location: {
      latitude: 53.9007159,
      longitude: 30.3313598,
    },
  },
];

const phoneFormat = val =>
  val
    ? `${val ? '(' : ''}${val?.slice(0, 2)}${
        val?.length > 2 ? ') ' : ''
      }${val?.slice(2, 5)}${val?.length > 5 ? ' ' : ''}${val?.slice(5, 7)}${
        val?.length > 7 ? ' ' : ''
      }${val?.slice(7, 9)}`
    : '';

const phonePrefix = '+375';

const phoneRegExp =
  '^\\((16|15|21|23|17|22|24|25|29|33|44)\\)\\s\\d{3}\\s\\d{2}\\s\\d{2}$';
const phoneWithoutFormatRegExp =
  '^(16|15|21|23|17|22|24|25|29|33|44)\\d{3}\\d{2}\\d{2}$';

const phoneValidation = {
  phonePrefix,
  phoneFormat,
  phoneRegExp,
  phoneWithoutFormatRegExp,
};

module.exports = {
  popularCities,
  currency,
  defaultJobTypeid,
  brand,
  address,
  phone,
  email,
  linksToImg,
  countryKey,
  phoneValidation,
};
