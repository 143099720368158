import { routerPush } from './urlControl';

const goBack = (router, filterQueryString) => {
  if (router.asPath.indexOf('find-task') !== -1)
    return routerPush(router, `/`);
  if (router.asPath.indexOf('executors') !== -1)
    return routerPush(router, `/executors?${filterQueryString}`);
  return console.log('Ошибка в пути файла');
};

export default goBack;
