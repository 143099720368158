export const getCityDataGoogle = cityId => {
  const [cityName, region] = cityId.replace('_', ' ')?.split('--');
  if (!region || region?.length < 2) return null;

  return {
    name: cityName,
    id: cityId,
    location: {
      latitude: 0,
      longitude: 0,
    },
  };
};
