import React from 'react';
import { Icon } from '@chakra-ui/react';

const ViewsIcon = props => (
  <Icon viewBox='0 0 512 512' boxSize={6} {...props}>
    <path
      d='m69.193 342.129c52.17 47.142 115.633 78.871 186.807 78.871 
        71.165 0 134.628-31.721 186.807-78.871 42.639-38.529 66.042-76.741 
        67.018-78.349 2.9-4.781 2.9-10.779 0-15.561-.976-1.608-24.379-39.82-67.018-78.349-52.17-47.141-115.633-78.87-186.807-78.87-71.165 
        0-134.628 31.721-186.807 78.871-42.638 38.528-66.042 76.741-67.018 78.349-2.9 
        4.781-2.9 10.779 0 15.561.976 1.607 24.38 39.82 67.018 78.348zm20.114-150c52.232-47.197 
        108.316-71.129 166.693-71.129 58.128 0 113.999 23.74 166.062 70.56 28.638 25.754 48.228 
        51.846 56.96 64.45-8.636 12.486-27.92 38.189-56.329 63.86-52.232 47.198-108.316 71.13-166.693 
        71.13-58.128 0-113.999-23.74-166.062-70.56-28.638-25.754-48.228-51.846-56.96-64.45 8.635-12.487 27.92-38.19 56.329-63.861z'
      fill='currentColor'
    />
    <path
      d='m256 361c57.897 0 105-47.103 105-105s-47.103-105-105-105-105 47.103-105 105 47.103 105 105 
        105zm0-180c41.355 0 75 33.645 75 75s-33.645 75-75 75-75-33.645-75-75 33.645-75 75-75z'
      fill='currentColor'
    />
    <path
      d='m256 301c24.813 0 45-20.187 45-45s-20.187-45-45-45-45 20.187-45 45 20.187 45 45 45zm0-60c8.271 
        0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15z'
      fill='currentColor'
    />
  </Icon>
);
export default ViewsIcon;
