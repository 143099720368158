import axios from 'axios';
// import jwtDecode from 'jwt-decode';
import { makeOperation } from 'urql';
import config from '../../config';
import { throwErrorSentry } from '../throwErrorSentry';
import localStorage from '../localStorage';

const TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const PERMISSION = 'permissions';

const saveAuthState = ({ token, refreshToken }) => {
  if (typeof window === 'undefined') return;
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

const savePermissions = permissions => {
  if (typeof window === 'undefined') return;
  localStorage.setItem(PERMISSION, permissions);
};

const getPermissions = () => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem(PERMISSION);
};

const clearAuthState = () => {
  if (typeof window === 'undefined') return;
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(PERMISSION);
};

const getToken = () => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem(TOKEN_KEY);
};

const getRefreshToken = () => {
  if (typeof window === 'undefined') return null;
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

const getRequest = async token =>
  axios({
    method: 'post',
    url:
      process.env.NODE_ENV === 'production'
        ? '/refreshtoken'
        : `${config.api}/refreshtoken`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: { token },
  });

const getAuth = async ({ authState }) => {
  let refreshToken = authState?.refreshToken;
  if (!refreshToken) {
    refreshToken = getRefreshToken();
  }

  if (!refreshToken) {
    return null;
  }
  let result;
  try {
    result = await getRequest(refreshToken);
  } catch (err) {
    if (err?.response?.data !== 'Expired token') {
      throwErrorSentry(err);
      clearAuthState();
    }
    return null;
  }

  if (result.data?.refreshToken) {
    saveAuthState({
      token: result.data?.accessToken,
      refreshToken: result.data?.refreshToken,
    });
    return result.data;
  }

  clearAuthState();
  window.location.reload();

  return null;
};

const addAuthToOperation = ({ authState, operation }) => {
  const token = authState?.token || getToken();
  if (!token) {
    return operation;
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  return makeOperation(operation?.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        authorization: token,
      },
    },
  });
};

const didAuthError = ({ error }) => {
  return error.graphQLErrors.some(e => e.extensions?.code === 'EXPIRED');
};

// const willAuthError = ({ operation, authState }) => {
//   if (!authState) {
//     return !(
//       operation.kind === 'mutation' &&
//       operation.query.definitions.some(definition => {
//         return (
//           definition.kind === 'OperationDefinition' &&
//           definition.selectionSet.selections.some(node => {
//             return (
//               node.kind === 'Field' &&
//               (node.name.value === 'login' || node.name.value === 'register')
//             );
//           })
//         );
//       })
//     );
//   }
//   const decoded = jwtDecode(getToken());
//   const isExpiring = decoded.exp * 1000 - new Date().getTime() <= 5000;
//   return operation.kind === 'query' && isExpiring;
// };

export {
  // willAuthError,
  savePermissions,
  didAuthError,
  addAuthToOperation,
  getAuth,
  saveAuthState,
  clearAuthState,
  getToken,
  getRefreshToken,
  getRequest,
  getPermissions,
};
