import {
  DELETE_FULL_NOT,
  DELETE_NOT_ITEM,
  SET_NOT,
  SET_INIT_NOT,
} from './constants';

export const setNot = async (dispatch, data) => {
  dispatch({
    type: SET_NOT,
    data,
  });
};

export const setInitNot = async (dispatch, data) => {
  if (typeof data !== 'object') return;
  dispatch({
    type: SET_INIT_NOT,
    data,
  });
};

/**
 * @param {Function} dispatch dispatch
 * @param {String} id Id of Entity that includes array of notifications
 *
 * Remove entity with array of notifications
 */
export const deleteFullItem = async (dispatch, id) => {
  dispatch({
    type: DELETE_FULL_NOT,
    id,
  });
};

/**
 * @param {Function} dispatch dispatch
 * @param {Object} data object with ids
 * @param {string} data.id Id of entity
 * @param {string} data.notIds Id of notification
 *
 * Remove only one notification
 */
export const deleteNotItem = async (dispatch, data) => {
  dispatch({
    type: DELETE_NOT_ITEM,
    data,
  });
};
