const Heading = {
  baseStyle: {
    color: 'typography.primary',
    fontWeight: 'semibold',
  },
  sizes: {
    '3-4xl': {
      fontSize: ['3xl', '3xl', '3xl', '4xl'],
    },
    '2-4xl': {
      fontSize: ['2xl', '2xl', '3xl', '4xl'],
    },
    '1-4xl': {
      fontSize: ['xl', '2xl', '3xl', '4xl'],
    },
    '2-3xl': {
      fontSize: ['2xl', '2xl', '3xl'],
    },
    '1-2xl': {
      fontSize: ['xl', '2xl', '2xl'],
    },
    fixLg: {
      fontSize: 'lg',
    },
    fixMd: {
      fontSize: '16px',
    },
    fix2xl: {
      fontSize: '2xl',
    },
    'lg-xl': {
      fontSize: ['lg', 'lg', 'xl'],
    },
    'md-xl': {
      fontSize: ['md', 'md', 'xl'],
    },
  },
  variants: {
    main: {
      lineHeight: ['40px', '40px', '80px'],
      fontSize: ['4xl', '4xl', '5xl'],
      color: 'white',
    },
    mainXL: {
      lineHeight: ['40px', '40px', '80px'],
      fontSize: ['3xl', '4xl', '5xl'],
    },
    base: {
      fontSize: ['lg', 'lg', '2xl'],
    },
    primaryLink: {
      _hover: { cursor: 'pointer', color: 'brand.primary' },
    },
  },
};

export default Heading;
