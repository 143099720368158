import { getQuery, getPathName } from './urlControl';

const LOGIN = 'login';
const REGISTER = 'register';

export const onOpenRegister = router => {
  const params = getQuery(router.query);

  router.push({
    pathname: getPathName(router.pathname),
    query: { ...params, modal: REGISTER },
  });
};

export const onOpenLogin = router => {
  const params = getQuery(router.query);

  router.push({
    pathname: getPathName(router.pathname),
    query: { ...params, modal: LOGIN },
  });
};

export const onCloseModal = (router, type = 'modal') => {
  const params = getQuery(router.query);

  return router.push({
    pathname: getPathName(router.pathname),
    query: Object.entries(params)
      .filter(([key]) => key !== type)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  });
};
