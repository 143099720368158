import React, { useContext } from 'react';
import dynamic from 'next/dynamic';
import { commonStore } from '../../store/storeProvider';

const ModalDesktopLazy = dynamic(() => import('./ModalDesktop.jsx'));
const ModalMobileLazy = dynamic(() => import('./ModalMobile.jsx'));

const Modal = (props) => {
  const {
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);
  return isMobile ? (
    <ModalMobileLazy {...props} > {props.children} </ModalMobileLazy>
  ) : (
    <ModalDesktopLazy {...props} > {props.children} </ModalDesktopLazy>
  );
};

export default Modal;
