const Link = {
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    primaryLink: {
      _hover: { cursor: 'pointer', color: 'brand.primary' },
    },
  },
};

export default Link;
