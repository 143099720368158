import React from 'react';
import { Box, Input } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'next-i18next';
import { emailRegExp } from '../../utils/regExp';

const EmailInput = ({ register, placeholder, errors, err, ...other }) => {
  const { t } = useTranslation('common');
  return (
    <Box {...other}>
      <Input
        key='reg_email'
        id='reg_email'
        name='email'
        type='email'
        data-testid='email-input'
        placeholder={placeholder || 'E-mail'}
        variant={
          errors.email || err?.name === 'email' || (err && !err?.name)
            ? 'error'
            : 'outline'
        }
        ref={register({
          required: t('obligatory-field'),
          maxLength: {
            value: 35,
            message: t('no-more-than-count-characters', { count: 35 }),
          },
          pattern: {
            value: emailRegExp,
            message: t('wrong-email-address'),
          },
        })}
      />
      <ErrorMessage
        errors={errors}
        name='email'
        render={({ message }) => (
          <Box pos='relative'>
            <Box fontSize='xs' position='absolute' data-testid='error-email'>
              {message}
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default EmailInput;
