var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"z2lC-CjtQCfZ7GKRohQEE"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { sentryDsn, isProd } from './src/config';
import pcg from './package.json';

if (isProd) {
  Sentry.init({
    dsn: sentryDsn,
    tracesSampleRate: 0.3,
    release: pcg.version,
  });
}
