export const locations = [
  {
    id: 'minskaya--oblast',
    name: 'Минская',
    cityName: 'Минск',
    title: 'Минске',
    items: [
      {
        id: 'minsk--minskaya_oblast',
        name: 'Минск',
        lat: 53.9045398,
        lng: 27.5615244,
      },
      {
        id: 'berezino--minskaya_oblast',
        name: 'Березино',
        lat: 53.8446885,
        lng: 28.9892933,
      },
      {
        id: 'bobr--minskaya_oblast',
        name: 'Бобр',
        lat: 54.3404541,
        lng: 29.2745787,
      },
      {
        id: 'borisov--minskaya_oblast',
        name: 'Борисов',
        lat: 54.2144309,
        lng: 28.508436,
      },
      {
        id: 'vileika--minskaya_oblast',
        name: 'Вилейка',
        lat: 54.4980119,
        lng: 26.9200749,
      },
      {
        id: 'volozhin--minskaya_oblast',
        name: 'Воложин',
        lat: 54.0878914,
        lng: 26.533109,
      },
      {
        id: 'gorodeya--minskaya_oblast',
        name: 'Городея',
        lat: 53.3118876,
        lng: 26.529787,
      },
      {
        id: 'dzerzhinsk--minskaya_oblast',
        name: 'Дзержинск',
        lat: 53.6849853,
        lng: 27.1325559,
      },
      {
        id: 'zhodino--minskaya_oblast',
        name: 'Жодино',
        lat: 54.1016136,
        lng: 28.3471258,
      },
      {
        id: 'zaslavl--minskaya_oblast',
        name: 'Заславль',
        lat: 54.0006143,
        lng: 27.276841,
      },
      {
        id: 'zelenii bor--minskaya_oblast',
        name: 'Зеленый Бор',
        lat: 54.0164763,
        lng: 28.4864947,
      },
      {
        id: 'ivenets--minskaya_oblast',
        name: 'Ивенец',
        lat: 53.8882122,
        lng: 26.7405467,
      },
      {
        id: 'kletsk--minskaya_oblast',
        name: 'Клецк',
        lat: 53.0637214,
        lng: 26.6392615,
      },
      {
        id: 'kopil--minskaya_oblast',
        name: 'Копыль',
        lat: 53.1511461,
        lng: 27.0908004,
      },
      {
        id: 'krupki--minskaya_oblast',
        name: 'Крупки',
        lat: 54.3269605,
        lng: 29.1426031,
      },
      {
        id: 'logoisk--minskaya_oblast',
        name: 'Логойск',
        lat: 54.2042169,
        lng: 27.8532022,
      },
      {
        id: 'lyuban--minskaya_oblast',
        name: 'Любань',
        lat: 52.7990255,
        lng: 27.9918895,
      },
      {
        id: 'marina gorka--minskaya_oblast',
        name: 'Марьина горка',
        lat: 53.506761,
        lng: 28.1527803,
      },
      {
        id: 'molodechno--minskaya_oblast',
        name: 'Молодечно',
        lat: 54.3104099,
        lng: 26.8488824,
      },
      {
        id: 'myadel--minskaya_oblast',
        name: 'Мядель',
        lat: 54.8771794,
        lng: 26.9388432,
      },
      {
        id: 'nesvizh--minskaya_oblast',
        name: 'Несвиж',
        lat: 53.2226038,
        lng: 26.6766138,
      },
      {
        id: 'pukhovichi--minskaya_oblast',
        name: 'Пуховичи',
        lat: 53.5339049,
        lng: 28.2501616,
      },
      {
        id: 'rakov--minskaya_oblast',
        name: 'Раков',
        lat: 53.9667658,
        lng: 27.0469131,
      },
      {
        id: 'rudensk--minskaya_oblast',
        name: 'Руденск',
        lat: 53.5977081,
        lng: 27.8611129,
      },
      {
        id: 'slutsk--minskaya_oblast',
        name: 'Слуцк',
        lat: 53.0210495,
        lng: 27.5540131,
      },
      {
        id: 'smolevichi--minskaya_oblast',
        name: 'Смолевичи',
        lat: 54.0297214,
        lng: 28.0892299,
      },
      {
        id: 'soligorsk--minskaya_oblast',
        name: 'Солигорск',
        lat: 52.7899466,
        lng: 27.5359618,
      },
      {
        id: 'starie dorogi--minskaya_oblast',
        name: 'Старые дороги',
        lat: 53.0389897,
        lng: 28.2605496,
      },
      {
        id: 'stolbtsi--minskaya_oblast',
        name: 'Столбцы',
        lat: 53.4862776,
        lng: 26.7452131,
      },
      {
        id: 'uzda--minskaya_oblast',
        name: 'Узда',
        lat: 53.4619521,
        lng: 27.215089,
      },
      {
        id: 'fanipol--minskaya_oblast',
        name: 'Фаниполь',
        lat: 53.746238,
        lng: 27.3375641,
      },
      {
        id: 'cherven--minskaya_oblast',
        name: 'Червень',
        lat: 53.7117479,
        lng: 28.4244728,
      },
    ],
  },
  {
    id: 'brestskaya--oblast',
    name: 'Брестская',
    cityName: 'Брест',
    title: 'Брестe',
    items: [
      {
        id: 'brest--brestskaya_oblast',
        name: 'Брест',
        lat: 52.0976214,
        lng: 23.7340503,
      },
      {
        id: 'antopol--brestskaya_oblast',
        name: 'Антополь',
        lat: 52.2041343,
        lng: 24.7853018,
      },
      {
        id: 'baranovichi--brestskaya_oblast',
        name: 'Барановичи',
        lat: 53.1255737,
        lng: 26.0091683,
      },
      {
        id: 'beloozersk--brestskaya_oblast',
        name: 'Белоозерск',
        lat: 52.4717355,
        lng: 25.1783133,
      },
      {
        id: 'bereza--brestskaya_oblast',
        name: 'Береза',
        lat: 52.5373125,
        lng: 24.9788566,
      },
      {
        id: 'bereza kartuska--brestskaya_oblast',
        name: 'Береза Картуска',
        lat: 0,
        lng: 0,
      },
      {
        id: 'visokoe--brestskaya_oblast',
        name: 'Высокое',
        lat: 52.367978,
        lng: 23.3759718,
      },
      {
        id: 'gantsevichi--brestskaya_oblast',
        name: 'Ганцевичи',
        lat: 52.7634482,
        lng: 26.4275001,
      },
      {
        id: 'gorodishche--brestskaya_oblast',
        name: 'Городище',
        lat: 53.3269782,
        lng: 26.0063136,
      },
      {
        id: 'david-gorodok--brestskaya_oblast',
        name: 'Давид-Городок',
        lat: 52.0546671,
        lng: 27.2119237,
      },
      {
        id: 'domachevo--brestskaya_oblast',
        name: 'Домачево',
        lat: 51.7441207,
        lng: 23.5951587,
      },
      {
        id: 'drogichin--brestskaya_oblast',
        name: 'Дрогичин',
        lat: 52.1930413,
        lng: 25.1471719,
      },
      {
        id: 'zhabinka--brestskaya_oblast',
        name: 'Жабинка',
        lat: 52.1954738,
        lng: 24.014935,
      },
      {
        id: 'ivanovo--brestskaya_oblast',
        name: 'Иваново',
        lat: 52.145887,
        lng: 25.5329454,
      },
      {
        id: 'ivatsevichi--brestskaya_oblast',
        name: 'Ивацевичи',
        lat: 52.7085795,
        lng: 25.3346543,
      },
      {
        id: 'kamenets--brestskaya_oblast',
        name: 'Каменец',
        lat: 52.3979454,
        lng: 23.8257811,
      },
      {
        id: 'kobrin--brestskaya_oblast',
        name: 'Кобрин',
        lat: 52.2141109,
        lng: 24.3581792,
      },
      {
        id: 'kossovo--brestskaya_oblast',
        name: 'Коссово',
        lat: 52.7567887,
        lng: 25.1531057,
      },
      {
        id: 'luninets--brestskaya_oblast',
        name: 'Лунинец',
        lat: 52.2513941,
        lng: 26.8066432,
      },
      {
        id: 'lyakhovichi--brestskaya_oblast',
        name: 'Ляховичи',
        lat: 53.0399358,
        lng: 26.2614947,
      },
      {
        id: 'malorita--brestskaya_oblast',
        name: 'Малорита',
        lat: 51.7925615,
        lng: 24.0739629,
      },
      {
        id: 'mikashevichi--brestskaya_oblast',
        name: 'Микашевичи',
        lat: 52.2160531,
        lng: 27.4751997,
      },
      {
        id: 'pinsk--brestskaya_oblast',
        name: 'Пинск',
        lat: 52.1124967,
        lng: 26.0634602,
      },
      {
        id: 'pruzhani--brestskaya_oblast',
        name: 'Пружаны',
        lat: 52.5557638,
        lng: 24.4554645,
      },
      {
        id: 'stolin--brestskaya_oblast',
        name: 'Столин',
        lat: 51.8873063,
        lng: 26.8394148,
      },
    ],
  },
  {
    id: 'vitebskaya--oblast',
    name: 'Витебская',
    cityName: 'Витебск',
    title: 'Витебске',
    items: [
      {
        id: 'vitebsk--vitebskaya_oblast',
        name: 'Витебск',
        lat: 55.1848061,
        lng: 30.201622,
      },
      {
        id: 'baran--vitebskaya_oblast',
        name: 'Барань',
        lat: 54.4788881,
        lng: 30.3160067,
      },
      {
        id: 'begoml--vitebskaya_oblast',
        name: 'Бегомль',
        lat: 54.7318321,
        lng: 28.0605962,
      },
      {
        id: 'beshenkovichi--vitebskaya_oblast',
        name: 'Бешенковичи',
        lat: 55.0444134,
        lng: 29.4559263,
      },
      {
        id: 'bogushevsk--vitebskaya_oblast',
        name: 'Богушевск',
        lat: 54.8420473,
        lng: 30.2064824,
      },
      {
        id: 'braslav--vitebskaya_oblast',
        name: 'Браслав',
        lat: 55.6411645,
        lng: 27.0450814,
      },
      {
        id: 'verkhnedvinsk--vitebskaya_oblast',
        name: 'Верхнедвинск',
        lat: 55.7784607,
        lng: 27.9323817,
      },
      {
        id: 'vetrino--vitebskaya_oblast',
        name: 'Ветрино',
        lat: 55.4104842,
        lng: 28.4633425,
      },
      {
        id: 'vidzi--vitebskaya_oblast',
        name: 'Видзы',
        lat: 55.3948269,
        lng: 26.6327297,
      },
      {
        id: 'voropaevo--vitebskaya_oblast',
        name: 'Воропаево',
        lat: 55.1390249,
        lng: 27.2066843,
      },
      {
        id: 'glubokoe--vitebskaya_oblast',
        name: 'Глубокое',
        lat: 55.1391321,
        lng: 27.6842904,
      },
      {
        id: 'gorodok--vitebskaya_oblast',
        name: 'Городок',
        lat: 55.4618616,
        lng: 29.9874438,
      },
      {
        id: 'disna--vitebskaya_oblast',
        name: 'Дисна',
        lat: 55.5662814,
        lng: 28.2137745,
      },
      {
        id: 'dokshitsi--vitebskaya_oblast',
        name: 'Докшицы',
        lat: 54.8953858,
        lng: 27.7599272,
      },
      {
        id: 'druya--vitebskaya_oblast',
        name: 'Друя',
        lat: 55.7859618,
        lng: 27.4327467,
      },
      {
        id: 'dubrovno--vitebskaya_oblast',
        name: 'Дубровно',
        lat: 54.5734035,
        lng: 30.6828298,
      },
      {
        id: 'yezerishche--vitebskaya_oblast',
        name: 'Езерище',
        lat: 55.8384089,
        lng: 29.9937851,
      },
      {
        id: 'lepel--vitebskaya_oblast',
        name: 'Лепель',
        lat: 54.878179,
        lng: 28.6980563,
      },
      {
        id: 'liozno--vitebskaya_oblast',
        name: 'Лиозно',
        lat: 55.024145,
        lng: 30.8005553,
      },
      {
        id: 'miori--vitebskaya_oblast',
        name: 'Миоры',
        lat: 55.6226563,
        lng: 27.633435,
      },
      {
        id: 'novolukoml--vitebskaya_oblast',
        name: 'Новолукомль',
        lat: 54.6575917,
        lng: 29.1509792,
      },
      {
        id: 'novopolotsk--vitebskaya_oblast',
        name: 'Новополоцк',
        lat: 55.5146432,
        lng: 28.5606439,
      },
      {
        id: 'orsha--vitebskaya_oblast',
        name: 'Орша',
        lat: 54.5071478,
        lng: 30.4119546,
      },
      {
        id: 'polotsk--vitebskaya_oblast',
        name: 'Полоцк',
        lat: 55.4831573,
        lng: 28.7990619,
      },
      {
        id: 'postavi--vitebskaya_oblast',
        name: 'Поставы',
        lat: 55.1134117,
        lng: 26.8433286,
      },
      {
        id: 'rossoni--vitebskaya_oblast',
        name: 'Россоны',
        lat: 55.9014488,
        lng: 28.8200362,
      },
      {
        id: 'senno--vitebskaya_oblast',
        name: 'Сенно',
        lat: 54.8133814,
        lng: 29.7063314,
      },
      {
        id: 'tolochin--vitebskaya_oblast',
        name: 'Толочин',
        lat: 54.4098787,
        lng: 29.6935064,
      },
      {
        id: 'ushachi--vitebskaya_oblast',
        name: 'Ушачи',
        lat: 55.1786995,
        lng: 28.6159394,
      },
      {
        id: 'chashniki--vitebskaya_oblast',
        name: 'Чашники',
        lat: 54.847813,
        lng: 29.1730691,
      },
      {
        id: 'sharkovshchina--vitebskaya_oblast',
        name: 'Шарковщина',
        lat: 55.3682716,
        lng: 27.4703402,
      },
      {
        id: 'shumilino--vitebskaya_oblast',
        name: 'Шумилино',
        lat: 55.3000777,
        lng: 29.6112895,
      },
    ],
  },
  {
    id: 'gomelskaya--oblast',
    name: 'Гомельская',
    cityName: 'Гомель',
    title: 'Гомеле',
    items: [
      {
        id: 'gomel--gomelskaya_oblast',
        name: 'Гомель',
        lat: 52.4411761,
        lng: 30.9878462,
      },
      {
        id: 'belitsk--gomelskaya_oblast',
        name: 'Белицк',
        lat: 52.9382677,
        lng: 30.4114864,
      },
      {
        id: 'bolshevik--gomelskaya_oblast',
        name: 'Большевик',
        lat: 52.5645365,
        lng: 30.8769586,
      },
      {
        id: 'bragin--gomelskaya_oblast',
        name: 'Брагин',
        lat: 51.7954009,
        lng: 30.2688788,
      },
      {
        id: 'buda-koshelevo--gomelskaya_oblast',
        name: 'Буда-Кошелево',
        lat: 52.7201592,
        lng: 30.569225,
      },
      {
        id: 'vasilevichi--gomelskaya_oblast',
        name: 'Василевичи',
        lat: 52.2443711,
        lng: 29.8315009,
      },
      {
        id: 'vasilevka--gomelskaya_oblast',
        name: 'Васильевка',
        lat: 52.2449975,
        lng: 31.5048893,
      },
      {
        id: 'vetka--gomelskaya_oblast',
        name: 'Ветка',
        lat: 52.5662086,
        lng: 31.1730798,
      },
      {
        id: 'dobrush--gomelskaya_oblast',
        name: 'Добруш',
        lat: 52.4031652,
        lng: 31.3294618,
      },
      {
        id: 'yelsk--gomelskaya_oblast',
        name: 'Ельск',
        lat: 51.8137357,
        lng: 29.1596054,
      },
      {
        id: 'zhitkovichi--gomelskaya_oblast',
        name: 'Житковичи',
        lat: 52.2160819,
        lng: 27.8502774,
      },
      {
        id: 'zhlobin--gomelskaya_oblast',
        name: 'Жлобин',
        lat: 52.8888952,
        lng: 30.0282481,
      },
      {
        id: 'kalinkovichi--gomelskaya_oblast',
        name: 'Калинковичи',
        lat: 52.1253899,
        lng: 29.3297633,
      },
      {
        id: 'korma--gomelskaya_oblast',
        name: 'Корма',
        lat: 53.1307962,
        lng: 30.7935909,
      },
      {
        id: 'lelchitsi--gomelskaya_oblast',
        name: 'Лельчицы',
        lat: 51.7959101,
        lng: 28.3296165,
      },
      {
        id: 'loev--gomelskaya_oblast',
        name: 'Лоев',
        lat: 51.9377873,
        lng: 30.7892014,
      },
      {
        id: 'mozir--gomelskaya_oblast',
        name: 'Мозырь',
        lat: 52.0322082,
        lng: 29.2223129,
      },
      {
        id: 'narovlya--gomelskaya_oblast',
        name: 'Наровля',
        lat: 51.8010382,
        lng: 29.4967759,
      },
      {
        id: 'oktyabrskii--gomelskaya_oblast',
        name: 'Октябрьский',
        lat: 52.6473109,
        lng: 28.8851644,
      },
      {
        id: 'petrikov--gomelskaya_oblast',
        name: 'Петриков',
        lat: 52.1267722,
        lng: 28.4919521,
      },
      {
        id: 'rechitsa--gomelskaya_oblast',
        name: 'Речица',
        lat: 52.3635403,
        lng: 30.3923965,
      },
      {
        id: 'rogachev--gomelskaya_oblast',
        name: 'Рогачев',
        lat: 53.0918554,
        lng: 30.0503982,
      },
      {
        id: 'svetlogorsk--gomelskaya_oblast',
        name: 'Светлогорск',
        lat: 52.6266995,
        lng: 29.7490257,
      },
      {
        id: 'turov--gomelskaya_oblast',
        name: 'Туров',
        lat: 52.0663389,
        lng: 27.7405067,
      },
      {
        id: 'khoiniki--gomelskaya_oblast',
        name: 'Хойники',
        lat: 51.9109552,
        lng: 29.9994415,
      },
      {
        id: 'chechersk--gomelskaya_oblast',
        name: 'Чечерск',
        lat: 52.9186475,
        lng: 30.91628,
      },
    ],
  },
  {
    id: 'grodnenskaya--oblast',
    name: 'Гродненская',
    cityName: 'Гродно',
    title: 'Гродно',
    items: [
      {
        id: 'grodno--grodnenskaya_oblast',
        name: 'Гродно',
        lat: 53.6693538,
        lng: 23.8131306,
      },
      {
        id: 'beryozovka--grodnenskaya_oblast',
        name: 'Берёзовка',
        lat: 53.7221715,
        lng: 25.5004738,
      },
      {
        id: 'bolshaya berestovitsa--grodnenskaya_oblast',
        name: 'Большая Берестовица',
        lat: 53.1948656,
        lng: 24.0191604,
      },
      {
        id: 'volkovisk--grodnenskaya_oblast',
        name: 'Волковыск',
        lat: 53.1516417,
        lng: 24.4422029,
      },
      {
        id: 'voronovo--grodnenskaya_oblast',
        name: 'Вороново',
        lat: 54.1499032,
        lng: 25.3160274,
      },
      {
        id: 'dyatlovo--grodnenskaya_oblast',
        name: 'Дятлово',
        lat: 53.4651358,
        lng: 25.4074899,
      },
      {
        id: 'zheludok--grodnenskaya_oblast',
        name: 'Желудок',
        lat: 53.5977548,
        lng: 24.9840659,
      },
      {
        id: 'zelva--grodnenskaya_oblast',
        name: 'Зельва',
        lat: 53.1501463,
        lng: 24.8029785,
      },
      {
        id: 'ive--grodnenskaya_oblast',
        name: 'Ивье',
        lat: 53.9323017,
        lng: 25.777486,
      },
      {
        id: 'kozlovshchina--grodnenskaya_oblast',
        name: 'Козловщина',
        lat: 53.3190638,
        lng: 25.2873294,
      },
      {
        id: 'korelichi--grodnenskaya_oblast',
        name: 'Кореличи',
        lat: 53.5666638,
        lng: 26.1406893,
      },
      {
        id: 'lida--grodnenskaya_oblast',
        name: 'Лида',
        lat: 53.8873843,
        lng: 25.2894383,
      },
      {
        id: 'mosti--grodnenskaya_oblast',
        name: 'Мосты',
        lat: 53.4102363,
        lng: 24.5338654,
      },
      {
        id: 'novogrudok--grodnenskaya_oblast',
        name: 'Новогрудок',
        lat: 53.597736,
        lng: 25.8243706,
      },
      {
        id: 'ostrovets--grodnenskaya_oblast',
        name: 'Островец',
        lat: 54.6105209,
        lng: 25.9524709,
      },
      {
        id: 'oshmyani--grodnenskaya_oblast',
        name: 'Ошмяны',
        lat: 54.4243464,
        lng: 25.9351597,
      },
      {
        id: 'svisloch--grodnenskaya_oblast',
        name: 'Свислочь',
        lat: 53.0358086,
        lng: 24.0942728,
      },
      {
        id: 'skidel--grodnenskaya_oblast',
        name: 'Скидель',
        lat: 53.5866599,
        lng: 24.2503492,
      },
      {
        id: 'slonim--grodnenskaya_oblast',
        name: 'Слоним',
        lat: 53.0875127,
        lng: 25.3087192,
      },
      {
        id: 'smorgon--grodnenskaya_oblast',
        name: 'Сморгонь',
        lat: 54.4762327,
        lng: 26.3981493,
      },
      {
        id: 'shchuchin--grodnenskaya_oblast',
        name: 'Щучин',
        lat: 53.6040518,
        lng: 24.741899,
      },
    ],
  },
  {
    id: 'mogilevskaya--oblast',
    name: 'Могилевская',
    cityName: 'Могилев',
    title: 'Могилеве',
    items: [
      {
        id: 'mogilev--mogilevskaya_oblast',
        name: 'Могилев',
        lat: 53.9007159,
        lng: 30.3313598,
      },
      {
        id: 'asenkovichi--mogilevskaya_oblast',
        name: 'Асеньковичи',
        lat: 0,
        lng: 0,
      },
      {
        id: 'belinichi--mogilevskaya_oblast',
        name: 'Белыничи',
        lat: 53.9969111,
        lng: 29.707695,
      },
      {
        id: 'bobruisk--mogilevskaya_oblast',
        name: 'Бобруйск',
        lat: 53.1446069,
        lng: 29.2213753,
      },
      {
        id: 'bikhov--mogilevskaya_oblast',
        name: 'Быхов',
        lat: 53.5180441,
        lng: 30.2402914,
      },
      {
        id: 'glusk--mogilevskaya_oblast',
        name: 'Глуск',
        lat: 52.8996249,
        lng: 28.6709271,
      },
      {
        id: 'glusha--mogilevskaya_oblast',
        name: 'Глуша',
        lat: 53.0864876,
        lng: 28.856285,
      },
      {
        id: 'gorki--mogilevskaya_oblast',
        name: 'Горки',
        lat: 54.2825201,
        lng: 30.990449,
      },
      {
        id: 'grodzyanka--mogilevskaya_oblast',
        name: 'Гродзянка',
        lat: 53.5488048,
        lng: 28.7434739,
      },
      {
        id: 'yelizovo--mogilevskaya_oblast',
        name: 'Елизово',
        lat: 53.3975307,
        lng: 29.0037835,
      },
      {
        id: 'dribin--mogilevskaya_oblast',
        name: 'Дрибин',
        lat: 54.1190657,
        lng: 31.1014303,
      },
      {
        id: 'kirovsk--mogilevskaya_oblast',
        name: 'Кировск',
        lat: 53.2691975,
        lng: 29.4765176,
      },
      {
        id: 'klimovichi--mogilevskaya_oblast',
        name: 'Климовичи',
        lat: 53.6091856,
        lng: 31.9594674,
      },
      {
        id: 'klichev--mogilevskaya_oblast',
        name: 'Кличев',
        lat: 53.4917756,
        lng: 29.3327583,
      },
      {
        id: 'kostyukovichi--mogilevskaya_oblast',
        name: 'Костюковичи',
        lat: 53.3449659,
        lng: 32.0532332,
      },
      {
        id: 'krasnopole--mogilevskaya_oblast',
        name: 'Краснополье',
        lat: 53.3383537,
        lng: 31.3972255,
      },
      {
        id: 'krichev--mogilevskaya_oblast',
        name: 'Кричев',
        lat: 53.7093085,
        lng: 31.7171895,
      },
      {
        id: 'krugloe--mogilevskaya_oblast',
        name: 'Круглое',
        lat: 54.2483092,
        lng: 29.796286,
      },
      {
        id: 'mstislavl--mogilevskaya_oblast',
        name: 'Мстиславль',
        lat: 54.0188307,
        lng: 31.7244374,
      },
      {
        id: 'osipovichi--mogilevskaya_oblast',
        name: 'Осиповичи',
        lat: 53.3048792,
        lng: 28.6357281,
      },
      {
        id: 'slavgorod--mogilevskaya_oblast',
        name: 'Славгород',
        lat: 53.4431223,
        lng: 30.9995629,
      },
      {
        id: 'khotimsk--mogilevskaya_oblast',
        name: 'Хотимск',
        lat: 53.4099308,
        lng: 32.5773652,
      },
      {
        id: 'chausi--mogilevskaya_oblast',
        name: 'Чаусы',
        lat: 53.8017532,
        lng: 30.953397,
      },
      {
        id: 'cherikov--mogilevskaya_oblast',
        name: 'Чериков',
        lat: 53.569694,
        lng: 31.37973,
      },
      {
        id: 'shklov--mogilevskaya_oblast',
        name: 'Шклов',
        lat: 54.2023958,
        lng: 30.2955436,
      },
    ],
  },
];
