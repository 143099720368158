import { gql } from '@urql/core';

const botHistoryFragments = gql`
  fragment BotHistoryFrag on BotHistory {
    chatId
    messages {
      type
      createdAt
      message {
        text
        data
      }
    }
  }
`;

const fileFragment = gql`
  fragment FileFrag on File {
    id
    fileName
    url
    type
    mimeType
    tag
    aspectRatio
    thmb {
      id
      url
      type
      mimeType
    }
  }
`;

const fileExternalFragment = gql`
  fragment FileExternalFrag on FileExternal {
    id
    fileName
    url
    type
    mimeType
    tag
    aspectRatio
    thmb {
      id
      url
      type
      mimeType
    }
  }
`;

const feedbacksFragments = gql`
  fragment FeedbackFrag on Feedback {
    id
    text
    images {
      ...FileFrag
    }
    from {
      id
      name
      lastName
      image {
        ...FileFrag
      }
      lastVisit
    }
    orderTitle
    rating
    jobTypeName
    jobTypeId
    createdAt
  }
  ${fileFragment}
`;

const feedbacksPreviewFragments = gql`
  fragment FeedbackPreviewFrag on FeedbackPreviewItem {
    id
    text
    images {
      ...FileFrag
    }
    from {
      id
      name
      lastName
    }
    for {
      id
      name
      lastName
      image {
        ...FileFrag
      }
      lastVisit
    }
    orderTitle
    rating
    jobTypeName
    jobTypeId
    createdAt
  }
  ${fileFragment}
`;

const tariffPlanFragments = gql`
  fragment TariffPlanFrag on TariffPlan {
    id
    name
    status
    type
    durationInMonths
    order
    price
    cionCount
    priceRange
  }
`;
const jobTypeFragments = gql`
  fragment JobTypeFrag on JobType {
    id
    name
    items {
      id
      name
      order
      title
      keywords
      keywordsSEO
      additionalServices
      detailedDescription
      description
      descriptionSEO
      h1
      orderTitle
      createTaskSchema
      updatedAt
      parentId
      path
      pathObject {
        id
        name
        path
      }
      mobileImage {
        ...FileFrag
      }
      desktopImage {
        ...FileFrag
      }
      responsePrice
      items {
        id
        name
        order
        title
        keywords
        keywordsSEO
        additionalServices
        detailedDescription
        description
        descriptionSEO
        h1
        orderTitle
        createTaskSchema
        updatedAt
        parentId
        path
        pathObject {
          id
          name
          path
        }
        mobileImage {
          ...FileFrag
        }
        desktopImage {
          ...FileFrag
        }
      }
    }
    keywords
    keywordsSEO
    itemsCount
    mobileImage {
      ...FileFrag
    }
    desktopImage {
      ...FileFrag
    }
    order
    title
    executors
    additionalServices
    detailedDescription
    description
    descriptionSEO
    h1
    orderTitle
    parentId
    path
    pathObject {
      id
      name
      path
    }
    updatedAt
    responsePrice
  }
  ${fileFragment}
`;

const jobTypeUtpCardFragments = gql`
  fragment JobTypeUtpCardFrag on JobTypeUtpCard {
    id
    name
    order
    itemsList {
      id
      name
      order
      image {
        ...FileFrag
      }
      price
      list
    }
  }
  ${fileFragment}
`;

const jobTypeSeoFragments = gql`
  fragment JobTypeSeoFrag on JobTypeSeo {
    id
    jobType {
      id
      pathObject {
        id
        name
        path
      }
    }
    cityId
    title
    h1
    additionalServices
    description
    detailedDescription
    prices {
      name
      price
    }
    faqs {
      question
      answer
    }
  }
`;

const jobTypeUnitFragment = gql`
  fragment JobTypeUnitFrag on JobTypeUnitType {
    priceFrom
    priceTo
    unit
  }
`;

const jobTypePriceFromAdminFragments = gql`
  fragment JobTypePriceFromAdminFrag on JobTypePriceFromAdmin {
    id
    jobType {
      id
      pathObject {
        id
        name
        path
      }
    }
    isJobTypePrice
    name
    units {
      ...JobTypeUnitFrag
    }
  }
  ${jobTypeUnitFragment}
`;

const userUnitFragment = gql`
  fragment UserUnitFrag on UserUnitType {
    price
    priceType
    priceFrom
    priceTo
    unit
    description
  }
`;

const jobTypePriceFragment = gql`
  fragment JobTypePriceFrag on JobTypePrice {
    units {
      ...JobTypeUnitFrag
    }
    values {
      ...UserUnitFrag
    }
  }
  ${userUnitFragment}
  ${jobTypeUnitFragment}
`;

const jobTypeWithUserPricesFragment = gql`
  fragment JobTypeWithUserPricesFrag on JobTypeWithUserPricesType {
    id
    name
    prices {
      ...UserUnitFrag
    }
  }
  ${userUnitFragment}
`;

const jobTypeWithPricesFragment = gql`
  fragment JobTypeWithPricesFrag on JobTypeWithPricesType {
    id
    name
    path
    jobTypePriceId
    userPriceId
    totalCount
    type
    price {
      ...JobTypePriceFrag
    }
  }
  ${jobTypePriceFragment}
`;

const userPricesFragments = gql`
  fragment UserPricesFrag on UserPrice {
    id
    jobTypePriceId
    userId
    cityId
    unitsValues {
      ...UserUnitFrag
    }
  }
  ${userUnitFragment}
`;

const portfolioFragments = gql`
  fragment PortfolioFrag on Portfolio {
    id
    jobTypeId
    title
    description
    price
    images {
      ...FileFrag
    }
  }
  ${fileFragment}
`;

const profileFragments = gql`
  fragment ProfileFrag on Profile {
    id
    name
    city {
      name
      id
      location {
        longitude
        latitude
      }
    }
    birthday
    lastName
    patronymic
    gender
    phone {
      phone
    }
    messenger {
      name
      id
      value
    }
    email {
      email
      status
    }
    social {
      id
      url
      name
    }
    description
    jobTypes {
      id
      name
      path
      items {
        id
        name
        path
        items {
          id
          name
          path
          items {
            id
            name
            path
          }
        }
      }
    }
    image {
      ...FileFrag
    }
    experience {
      id
      start
      end
      position
      workPlace
    }
    education {
      id
      start
      end
      type
      specialization
      studyPlace
    }
    price {
      id
      name
      items {
        id
        name
        price
        unit
      }
    }
    status
    orderRating {
      score
      feedbacksCount
    }
    taskRating {
      score
      feedbacksCount
    }
    oldPortfolio {
      images {
        ...FileFrag
      }
    }
    portfolio {
      ...PortfolioFrag
    }
    isCompany
    isAssurance
    unp
  }
  ${portfolioFragments}
  ${fileFragment}
`;

const publicProfileFragments = gql`
  fragment PublicProfileFrag on PublicProfile {
    name
    id
    lastName
    city {
      id
      name
      location {
        longitude
        latitude
      }
    }
    image {
      ...FileFrag
    }
    lastVisit
    birthday
    passportApprove
    firstDayOnline
    orderRating {
      score
      feedbacksCount
    }
    taskRating {
      score
      feedbacksCount
    }
    jobTypes {
      id
      name
      parentId
      path
    }
    experience {
      id
      start
      end
      position
      workPlace
    }
    education {
      id
      start
      end
      type
      specialization
      studyPlace
    }
    price {
      id
      name
      items {
        id
        name
        price
        unit
      }
    }
    completedTasks {
      count
    }
    createdTasks {
      count
    }
    description
    oldPortfolio {
      images {
        ...FileFrag
      }
    }
    portfolio {
      ...PortfolioFrag
    }
    status
    messenger {
      name
      id
      value
    }
    updatedAt
    createdAt
    isPhone
    isCompany
    isAssurance
    unp
  }
  ${fileFragment}
  ${portfolioFragments}
`;

const profileSystemFragments = gql`
  fragment ProfileSystemFrag on ProfileSystem {
    name
    id
    lastName
    city {
      id
      name
      location {
        longitude
        latitude
      }
    }
    image {
      ...FileFrag
    }
    lastVisit
    birthday
    passportApprove
    firstDayOnline
    orderRating {
      score
      feedbacksCount
    }
    taskRating {
      score
      feedbacksCount
    }
    jobTypes {
      id
      name
    }
    experience {
      id
      start
      end
      position
      workPlace
    }
    education {
      id
      start
      end
      type
      specialization
      studyPlace
    }
    price {
      id
      name
      items {
        id
        name
        price
        unit
      }
    }
    completedTasks {
      count
    }
    createdTasks {
      count
    }
    description
    oldPortfolio {
      images {
        ...FileFrag
      }
    }
    portfolio {
      ...PortfolioFrag
    }
    status
    messenger {
      name
      id
      value
    }
    updatedAt
    createdAt
    phone {
      phone
    }
    botSubscriptions {
      id
      messengerType
      subscriptionStatus
      newMessage
      newOrder
      newOrderStatus
    }
    isCompany
    isAssurance
    unp
  }
  ${portfolioFragments}
  ${fileFragment}
`;

const orderFragments = gql`
  fragment OrderFrag on Order {
    id
    title
    address {
      name
      id
      street
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    end
    cost
    paymentType
    description
    views
    status
    owner {
      ...PublicProfileFrag
    }
    created
    jobType {
      id
      name
    }
    noTimeLimit
    type
    additional {
      id
      name
    }
    createdAt
    tendersCount
    images {
      ...FileFrag
    }
  }
  ${publicProfileFragments}
  ${fileFragment}
`;

const publicOrderFragments = gql`
  fragment PublicOrderFrag on PublicOrder {
    id
    title
    address {
      name
      id
      location {
        longitude
        latitude
      }
    }
    start {
      time
      date
    }
    end
    cost
    description
    views
    status
    created
    jobType {
      id
      name
    }
    additional {
      id
      name
    }
    noTimeLimit
    createdAt
    executor {
      id
      lastName
      name
      image {
        ...FileFrag
      }
      city {
        name
      }
      birthday
      taskRating {
        feedbacksCount
        score
      }
    }
    feedback {
      ...FeedbackFrag
    }
  }
  ${fileFragment}
  ${feedbacksFragments}
`;

const orderFromBotFragments = gql`
  fragment OrderFromBotFrag on OrderFromBot {
    token
  }
`;

const orderSystemFragments = gql`
  fragment OrderSystemFrag on OrderSystem {
    id
    title
    address {
      name
      id
      street
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    end
    cost
    paymentType
    description
    views
    status
    systemOwner {
      ...ProfileSystemFrag
    }
    executor {
      ...ProfileSystemFrag
    }
    created
    jobType {
      id
      name
      parentId
      pathObject {
        id
        name
        path
      }
    }
    noTimeLimit
    type
    additional {
      id
      name
    }
    createdAt
    images {
      ...FileFrag
    }
    phone
    showPhoneCount
    tender {
      tenders {
        id
        chat {
          id
          messages {
            sender {
              ...PublicProfileFrag
            }
            message
            files {
              ...FileFrag
            }
            createdAt
          }
          isNew
        }
        owner {
          ...ProfileSystemFrag
        }
        createdAt
        price
        status
        isFromCustomer
      }
      totalCount
    }
  }
  ${profileSystemFragments}
  ${publicProfileFragments}
  ${fileFragment}
`;

const ordersFragments = gql`
  fragment OrdersFrag on Order {
    id
    title
    address {
      name
      id
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    cost
    paymentType
    description
    views
    status
    created
    jobType {
      id
      name
    }
    noTimeLimit
    additional {
      id
      name
    }
    createdAt
    tendersCount
  }
`;

const myOrderFragments = gql`
  fragment MyOrderFrag on MyOrder {
    id
    title
    address {
      name
      street
      id
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    end
    cost
    paymentType
    description
    views
    status
    created
    phone
    jobType {
      id
      name
    }
    noTimeLimit
    additional {
      id
      name
    }
    createdAt
    tender {
      tenders {
        id
        chat {
          id
          messages {
            sender {
              ...PublicProfileFrag
            }
            message
            files {
              ...FileFrag
            }
            createdAt
          }
          isNew
        }
        owner {
          ...PublicProfileFrag
        }
        createdAt
        price
        status
      }
      totalCount
    }
    images {
      ...FileFrag
    }
    type
    executor {
      name
      id
      lastName
      city {
        id
        name
      }
      image {
        ...FileFrag
      }
      lastVisit
      taskRating {
        score
        feedbacksCount
      }
      completedTasks {
        count
      }
      isCompany
      unp
    }
  }
  ${fileFragment}
  ${publicProfileFragments}
`;

const myOrdersFragments = gql`
  fragment MyOrdersFrag on MyOrder {
    id
    title
    start {
      time
      date
    }
    cost
    paymentType
    description
    views
    status
    jobType {
      id
      name
    }
    additional {
      id
      name
    }
    createdAt
    tender {
      totalCount
      tenders {
        chat {
          isNew
        }
        id
        status
        isFromCustomer
      }
    }
  }
`;

const myApprovedOrdersFragments = gql`
  fragment MyApprovedOrdersFrag on MyApprovedOrder {
    id
    title
    start {
      time
      date
    }
    end
    cost
    paymentType
    description
    views
    status
    jobType {
      id
      name
    }
    additional {
      id
      name
    }
    createdAt
    noTimeLimit
    executor {
      id
      name
      lastName
      lastVisit
      image {
        ...FileFrag
      }
    }
    feedback {
      ...FeedbackFrag
    }
    chat {
      isNew
    }
    isEstimated
  }
  ${feedbacksFragments}
  ${fileFragment}
`;

const myApprovedOrderFragments = gql`
  fragment MyApprovedOrderFrag on MyApprovedOrder {
    id
    title
    address {
      name
      street
      id
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    end
    cost
    phone
    paymentType
    description
    views
    status
    jobType {
      id
      name
    }
    additional {
      id
      name
    }
    createdAt
    executor {
      ...PublicProfileFrag
    }
    noTimeLimit
    chat {
      id
      messages {
        sender {
          ...PublicProfileFrag
        }
        message
        files {
          ...FileFrag
        }
        createdAt
      }
      isNew
    }
    feedback {
      ...FeedbackFrag
    }
    images {
      ...FileFrag
    }
    isEstimated
  }
  ${fileFragment}
  ${feedbacksFragments}
  ${publicProfileFragments}
`;

const approvedOrderSystemFragments = gql`
  fragment ApprovedOrderSystemFrag on ApprovedOrderSystem {
    id
    title
    type
    address {
      name
      street
      id
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    end
    cost
    phone
    paymentType
    description
    views
    status
    jobType {
      id
      name
    }
    additional {
      id
      name
    }
    createdAt
    executor {
      ...ProfileSystemFrag
    }
    systemOwner {
      ...ProfileSystemFrag
    }
    noTimeLimit
    chat {
      id
      messages {
        sender {
          ...PublicProfileFrag
        }
        message
        files {
          ...FileFrag
        }
        createdAt
      }
      isNew
    }
    feedbackExecutor {
      ...FeedbackFrag
    }
    feedbackOwner {
      ...FeedbackFrag
    }
    images {
      ...FileFrag
    }
  }
  ${fileFragment}
  ${feedbacksFragments}
  ${profileSystemFragments}
  ${publicProfileFragments}
`;

const closedOrderFragments = gql`
  fragment ClosedOrderFrag on ClosedOrder {
    id
    title
    type
    address {
      name
      id
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    end
    cost
    phone
    showPhoneCount
    paymentType
    description
    views
    status
    jobType {
      id
      name
    }
    additional {
      id
      name
    }
    createdAt
    createdAtOrder
    systemOwner {
      ...ProfileSystemFrag
    }
    noTimeLimit
    images {
      ...FileFrag
    }
    reason
    reasonDescription
    complaints {
      id
      type
      description
      owner {
        id
        name
        lastName
      }
      createdAt
    }
    tenders {
      id
      chat {
        id
        messages {
          sender {
            ...PublicProfileFrag
          }
          message
          files {
            ...FileFrag
          }
          createdAt
        }
        isNew
      }
      owner {
        ...ProfileSystemFrag
      }
      createdAt
      price
      status
      isFromCustomer
    }
  }
  ${fileFragment}
  ${publicProfileFragments}
  ${profileSystemFragments}
`;

const feedbackSystemFragments = gql`
  fragment FeedbackSystemFrag on FeedbackSystem {
    id
    text
    images {
      ...FileFrag
    }
    from {
      id
      name
      lastName
      image {
        ...FileFrag
      }
      lastVisit
    }
    owner {
      id
      name
      lastName
      image {
        ...FileFrag
      }
      lastVisit
    }
    order {
      id
      title
    }
    type
    rating
    jobTypeName
    createdAt
  }
  ${fileFragment}
`;

const myTenderFragment = gql`
  fragment TenderFrag on Tender {
    id
    chat {
      id
      messages {
        sender {
          ...PublicProfileFrag
        }
        message
        files {
          ...FileFrag
        }
        createdAt
      }
      isNew
    }
    order {
      ...OrderFrag
      isPhone
    }
    createdAt
    price
    status
    isFromCustomer
  }
  ${publicProfileFragments}
  ${orderFragments}
  ${fileFragment}
`;

const complaintFragment = gql`
  fragment ComplaintFrag on Complaint {
    id
    order {
      ...OrderFrag
    }
    createdAt
    updatedAt
    items {
      id
      type
      description
      owner {
        id
        name
        lastName
      }
      createdAt
    }
  }
  ${orderFragments}
`;

const approvedOrderComplaintFragment = gql`
  fragment ApprovedOrderComplaintFrag on ApprovedOrderComplaint {
    id
    order {
      ...ApprovedOrderSystemFrag
    }
    createdAt
    updatedAt
    items {
      id
      type
      description
      owner {
        id
        name
        lastName
      }
      createdAt
    }
  }
  ${approvedOrderSystemFragments}
`;

const chatFragments = gql`
  fragment ChatFrag on Chat {
    id
    messages {
      sender {
        id
        name
        lastName
        lastVisit
        image {
          ...FileFrag
        }
      }
      message
      files {
        ...FileFrag
      }
      createdAt
    }
    isNew
  }
  ${fileFragment}
`;

const messageFragments = gql`
  fragment MessageFrag on Message {
    sender {
      id
      name
      lastName
      lastVisit
      image {
        ...FileFrag
      }
    }
    message
    createdAt
  }
  ${fileFragment}
`;

const approvedOrderFragments = gql`
  fragment ApprovedOrderFrag on ApprovedOrder {
    id
    title
    address {
      name
      id
      location {
        longitude
        latitude
      }
    }
    isRemote
    start {
      time
      date
    }
    cost
    paymentType
    description
    phone
    views
    status
    owner {
      ...PublicProfileFrag
    }
    created
    jobType {
      id
      name
    }
    noTimeLimit

    additional {
      id
      name
    }
    createdAt
    chat {
      ...ChatFrag
    }
    feedback {
      ...FeedbackFrag
    }
    images {
      ...FileFrag
    }
    isEstimated
  }
  ${fileFragment}
  ${feedbacksFragments}
  ${publicProfileFragments}
  ${chatFragments}
`;

const exampleTaskFragment = gql`
  fragment ExampleTaskFrag on ExampleTask {
    id
    name
    examplesList {
      id
      text
      imageId
      executorId
      image {
        ...FileExternalFrag
      }
      executor {
        ...PublicProfileFrag
      }
    }
  }
  ${fileExternalFragment}
  ${publicProfileFragments}
`;

export {
  closedOrderFragments,
  approvedOrderComplaintFragment,
  complaintFragment,
  jobTypeFragments,
  jobTypeUtpCardFragments,
  jobTypeSeoFragments,
  fileFragment,
  profileFragments,
  publicProfileFragments,
  orderFragments,
  publicOrderFragments,
  orderFromBotFragments,
  myTenderFragment,
  ordersFragments,
  myOrderFragments,
  myOrdersFragments,
  chatFragments,
  messageFragments,
  approvedOrderFragments,
  myApprovedOrderFragments,
  myApprovedOrdersFragments,
  feedbacksFragments,
  orderSystemFragments,
  approvedOrderSystemFragments,
  feedbackSystemFragments,
  feedbacksPreviewFragments,
  botHistoryFragments,
  exampleTaskFragment,
  fileExternalFragment,
  tariffPlanFragments,
  jobTypePriceFromAdminFragments,
  jobTypeWithPricesFragment,
  jobTypeWithUserPricesFragment,
  userPricesFragments,
};
