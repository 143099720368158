/* eslint-disable import/prefer-default-export */
const INVALID_FIELD = 'INVALID_FIELD';
const INVALID_PASSWORD = 'INVALID_PASSWORD';
const INVALID_LOGIN = 'INVALID_LOGIN';
export const UNCOMPLETED_TASKS = 'UNCOMPLETED_TASKS';
const TITLE = 'title';
const DESCRIPTION = 'description';
const STREET = 'street';
const COST = 'cost';
const CONTACT = 'contact';
const DUPLICATED_PROPOSUAL = 'DUPLICATED_PROPOSUAL';
const DUPLICATED_TENDER = 'DUPLICATED_TENDER';
const UNACCEPTABLE = 'UNACCEPTABLE';
const SUBSCRIPTION_EXISTS = 'SUBSCRIPTION_EXISTS';
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

export const parseGqlError = (graphQLErrors = {}, t = key => key) => {
  const extensions = graphQLErrors?.[0]?.extensions;
  if (extensions) {
    switch (extensions?.code) {
      case 11000:
        return {
          message: t('errors-messages.11000', { ns: 'common' }),
          code: 11000,
        };
      case INVALID_FIELD:
        return {
          message: t('errors-messages.invalid-field', { ns: 'common' }),
          code: INVALID_FIELD,
          name: graphQLErrors?.[0]?.message?.replace(/.*(?=@)/, '')?.slice(1),
        };
      case 404:
        return {
          message: t('errors-messages.404', { ns: 'common' }),
          code: 404,
        };
      case 402:
        return {
          message: t('errors-messages.payment-required', { ns: 'common' }), // TODO нужен другой текст или вместо тоста модалка с тарифами или покупкой отклика
          code: 402,
        };
      case INVALID_LOGIN:
        return {
          message: t('errors-messages.invalid-login', { ns: 'common' }),
          code: INVALID_LOGIN,
        };
      case INVALID_PASSWORD:
        return {
          message: t('errors-messages.invalid-password', { ns: 'common' }),
          code: INVALID_PASSWORD,
        };
      case TITLE:
        return {
          message: t('errors-messages.title', { ns: 'common' }),
          code: TITLE,
        };
      case DESCRIPTION:
        return {
          message: t('errors-messages.description', { ns: 'common' }),
          code: DESCRIPTION,
        };
      case STREET:
        return {
          message: t('errors-messages.street', { ns: 'common' }),
          code: STREET,
        };
      case COST:
        return {
          message: t('errors-messages.cost', { ns: 'common' }),
          code: COST,
        };
      case CONTACT:
        return {
          message: t('errors-messages.contact', { ns: 'common' }),
          code: CONTACT,
        };
      case UNCOMPLETED_TASKS:
        return {
          message: t('errors-messages.uncompleted-tasks', { ns: 'common' }),
          code: UNCOMPLETED_TASKS,
        };
      case DUPLICATED_PROPOSUAL:
        return {
          message: t('errors-messages.duplicated-proposual', { ns: 'common' }),
          code: DUPLICATED_PROPOSUAL,
        };
      case DUPLICATED_TENDER:
        return {
          message: t('errors-messages.duplicated-tender', { ns: 'common' }),
          code: DUPLICATED_TENDER,
        };
      case UNACCEPTABLE:
        return {
          message: t('errors-messages.unacceptable', { ns: 'common' }),
          code: UNACCEPTABLE,
        };
      case SUBSCRIPTION_EXISTS:
        return {
          message: t('errors-messages.subscription-exists', { ns: 'common' }),
          code: SUBSCRIPTION_EXISTS,
        };
      default:
        break;
    }
  }
  return {
    message: t('errors-messages.default-error', { ns: 'common' }),
    code: graphQLErrors?.[0]?.code,
    type: UNKNOWN_ERROR,
  };
};
