import { getColor, mode } from '@chakra-ui/theme-tools';
import { constants } from '../constants';

function getDefaults(props) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  };
}

function variantFilled(props) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      fontSize: 'md',
      border: '1px solid',
      borderColor: 'typography.error',
      bg: mode(constants.error100, 'whiteAlpha.50')(props),
      _hover: {
        bg: mode(constants.error200, 'whiteAlpha.100')(props),
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
      _focus: {
        bg: 'white',
        borderColor: getColor(theme, fc),
      },
    },
    addon: {
      border: '1px solid',
      borderColor: 'transparent',
      bg: mode('brand.secondaryBackground', 'whiteAlpha.50')(props),
    },
  };
}

const Input = {
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
  variants: {
    error: variantFilled,
    outline: {
      field: {
        bg: 'white',
        fontSize: 'md',
      },
    },
    filled: {
      field: {
        fontSize: 'md',
      },
    },
    flushed: {
      field: {
        fontSize: 'md',
      },
    },
    unstyled: {
      field: {
        fontSize: 'md',
      },
    },
  },
};

export default Input;
