import React from 'react';
import { Global } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { appWithTranslation } from 'next-i18next';
import DefaultLayout from '../src/layouts/DefaultLayout';
import theme from '../src/theme';
import fonts from '../src/theme/font-face';
import { CommonProvider } from '../src/store/storeProvider';
import {
  OrdererNtfProvider,
  ExecutorNtfProvider,
} from '../src/store/notification';
import Header from '../src/components/Header';
import { isProd, gtmId } from '../src/config';
import localStorage from '../src/utils/localStorage';
import meta from '../src/utils/seo/meta';
import './styles.css';
import MetaDecorator from '../src/utils/seo/MetaDecorator';
import Footer from '../src/components/Footer';
import { TaskProvider } from '../src/store/taskProvider';

const FooterOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(Footer);

if (isProd) {
  const tagManagerArgs = {
    gtmId,
  };

  if (typeof window !== 'undefined') {
    setTimeout(() => TagManager.initialize(tagManagerArgs), 3000);
    try {
      const urlParams = new URLSearchParams(window.location.search).get('ad');
      const myParam = urlParams ? `ad_${urlParams}` : '';

      if (myParam) {
        window?.dataLayer?.push({ source: myParam });
        localStorage.setItem('source', myParam);
      }
    } catch (err) {
      console.log('gtm:error');
    }
  }
}

function MyApp({ Component, pageProps, router }) {
  const Layout = Component.Layout || DefaultLayout;
  dayjs.locale(router?.locale);

  return (
    <>
      <MetaDecorator
        title={meta.main.title()}
        description={meta.main.description()}
      />
      <CommonProvider init={pageProps?.ssgContext}>
        <TaskProvider>
          <OrdererNtfProvider>
            <ExecutorNtfProvider>
              <Global styles={fonts} />
              <ChakraProvider theme={theme}>
                <Header />
                <Layout as='main'>
                  <Component {...pageProps} />
                </Layout>
                <FooterOnDemand />
              </ChakraProvider>
            </ExecutorNtfProvider>
          </OrdererNtfProvider>
        </TaskProvider>
      </CommonProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
