import React from 'react';
import { Icon } from '@chakra-ui/react';

const PeopleIcon = props => (
  <Icon viewBox='0 0 31 31' {...props}>
    <path
      d='M11.9959 18.8947L12.5013 18.8968C19.8408 18.9607 23.9935 20.4431 23.9935 24.492C23.9935 28.4552 20.0135 29.984 12.9949 30.1121L11.9959 30.121C4.34051 30.121 0 28.6658 0 24.5236C0 20.3761 4.35778 18.8947 11.9959 18.8947ZM11.9959 21.2651C5.64738 21.2651 2.37043 22.3791 2.37043 24.5236C2.37043 26.6546 5.63952 27.7506 11.9959 27.7506C18.3449 27.7506 21.623 26.6364 21.623 24.492C21.623 22.3622 18.3509 21.2651 11.9959 21.2651ZM24.6314 17.7756C25.5489 17.838 26.461 17.9695 27.3375 18.1638C29.215 18.5373 30.6108 19.2344 31.2504 20.5763C31.7226 21.5699 31.7226 22.7258 31.2505 23.7192C30.6145 25.0578 29.2332 25.7499 27.3451 26.1382C26.7039 26.2701 26.0773 25.8573 25.9454 25.2161C25.8135 24.575 26.2264 23.9483 26.8675 23.8164C28.0825 23.5665 28.8912 23.1613 29.1095 22.7018C29.2757 22.3521 29.2757 21.9436 29.11 21.595C28.8902 21.1338 28.0774 20.7278 26.8501 20.4834C26.0641 20.3094 25.2695 20.1949 24.4706 20.1405C23.8175 20.0961 23.3241 19.5307 23.3685 18.8776C23.4129 18.2245 23.9783 17.7311 24.6314 17.7756ZM11.9959 0C16.4966 0 20.1107 3.61349 20.1107 8.11318C20.1107 12.6142 16.4969 16.2279 11.9959 16.2279C7.49517 16.2279 3.88276 12.6144 3.88276 8.11318C3.88276 3.61327 7.49545 0 11.9959 0ZM22.1551 1.69027C25.6869 1.69027 28.5505 4.5545 28.5505 8.08568C28.5505 11.618 25.6874 14.4811 22.1551 14.4811C21.5005 14.4811 20.9699 13.9505 20.9699 13.2959C20.9699 12.6413 21.5005 12.1107 22.1551 12.1107C24.3782 12.1107 26.1801 10.3088 26.1801 8.08568C26.1801 5.86354 24.3776 4.0607 22.1551 4.0607C21.5005 4.0607 20.9699 3.53006 20.9699 2.87548C20.9699 2.22091 21.5005 1.69027 22.1551 1.69027ZM11.9959 2.37043C8.80469 2.37043 6.25318 4.92233 6.25318 8.11318C6.25318 11.3054 8.80449 13.8575 11.9959 13.8575C15.1878 13.8575 17.7403 11.305 17.7403 8.11318C17.7403 4.92272 15.1876 2.37043 11.9959 2.37043Z'
      fill={props.color}
    />
  </Icon>
);
export default PeopleIcon;
