import React from 'react';
import RegisterModalContent from './RegisterModalContent.jsx';
import Modal from '../Modal';

const RegisterModal = ({ isOpen, onClose, onOpenLogin }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <RegisterModalContent onOpenLogin={onOpenLogin} />
  </Modal>
);

export default RegisterModal;
