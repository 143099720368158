const Progress = {
  sizes: {
    sm: {
      track: {
        borderRadius: '20px',
        height: '10px',
      },
      filledTrack: {
        borderRadius: '20px',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    colorScheme: 'cyan',
    hasStripe: true,
  },
};
export default Progress;
