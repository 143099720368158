import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useQuery } from 'urql';
// import { initPath } from '../../utils/urlControl';
import { clearAuthState } from '../../utils/urqlClient/auth';
import { commonStore } from '../../store/storeProvider';
import LoginModal from '../LoginModal';
import RegisterModal from '../RegisterModal';
import { withUrqlClient } from '../../utils/urqlClient';
import { PROFILE, ME } from '../../gql/query';
import {
  onOpenLogin,
  onOpenRegister,
  onCloseModal,
} from '../../utils/modalControl';
import { navigationItemsHeader } from '../../common/navigationItems';
import Modal from '../Modal';
import ConfirmEmail from '../ConfirmEmail';
import DeleteProfile from '../DeleteProfile';
import UnsubscribeEmailNotification from '../UnsubscribeEmailNotification';

const HeaderDesktopLazy = dynamic(() => import('./HeaderDesktop.jsx'));
const HeaderMobileLazy = dynamic(() => import('./HeaderMobile.jsx'));

const LOGIN = 'login';
const REGISTER = 'register';

const Header = () => {
  const {
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);

  const [me, reexecuteQuery] = useQuery({
    query: ME,
    // prevent request me on server side
    requestPolicy: typeof window !== 'undefined' ? undefined : 'cache-only',
  });
  let data = me?.data;

  const router = useRouter();
  const routerQuery = router.asPath === '/';
  const profileId = data?.me?.id;
  const [isShowPhone, setShowPhone] = useState(false);
  const [profileData, reexecuteQueryProfile] = useQuery({
    query: PROFILE,
    variables: { id: profileId },
    pause: !profileId,
  });
  let navigationItems = data?.me
    ? navigationItemsHeader.filter(el => el.name !== 'become-a-executor')
    : navigationItemsHeader;
  navigationItems = routerQuery
    ? navigationItems.filter(el => el.name !== 'home')
    : navigationItems;
  if (data) {
    data = { ...data, profile: { ...profileData.data?.profile } };
  }
  const onClose = type => onCloseModal(router, type);
  const onRegister = () => onOpenRegister(router);
  const onLogin = () => onOpenLogin(router);
  const logout = () => {
    clearAuthState();
    onClose();
    window.location.reload();
  };

  const checkIsShowPhone = () => {
    // let route = value;
    // if (route.includes(initPath)) route = `${route}`.split(initPath)?.[1];
    // if (
    //   route === '/master-na-chas-lp' ||
    //   route === '/kliningovie-uslugi-lp' ||
    //   route === '/[region]/[cityName]/[jobType]' ||
    //   route === '/[region]/[cityName]/[jobType]/[jobItemLevel1]' ||
    //   route ===
    //     '/[region]/[cityName]/[jobType]/[jobItemLevel1]/[jobItemLevel2]' ||
    //   route === '/near-me/[jobType]' ||
    //   route === '/near-me/[jobType]/[jobItemLevel1]' ||
    //   route === '/near-me/[jobType]/[jobItemLevel1]/[jobItemLevel2]'
    // ) {
    //   return true;
    // }
    return false;
  };

  useEffect(() => {
    setShowPhone(checkIsShowPhone(router?.route));
  }, [router?.route]);

  const refetchProfile = () => {
    reexecuteQueryProfile({ requestPolicy: 'network-only' });
  };

  const refetch = () => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  return (
    <Box pt={16}>
      <Modal
        isOpen={router?.query?.confirmEmail}
        onClose={() => onClose('confirmEmail')}
      >
        <ConfirmEmail
          data={data}
          token={router?.query?.confirmEmail}
          router={router}
          profileRefetch={refetchProfile}
        />
      </Modal>
      <Modal
        isOpen={router?.query?.unsubscribe}
        onClose={() => onClose('unsubscribe')}
      >
        <UnsubscribeEmailNotification
          token={router?.query?.unsubscribe}
          router={router}
        />
      </Modal>
      <LoginModal
        isOpen={!(data && data.me) && router?.query?.modal === LOGIN}
        onClose={onClose}
        onOpenRegister={onRegister}
        refetch={refetch}
      />
      <RegisterModal
        isOpen={!(data && data.me) && router?.query?.modal === REGISTER}
        onClose={onClose}
        onOpenLogin={onLogin}
      />

      <Modal
        isOpen={router?.query?.deleteProfile}
        onClose={() => onClose('deleteProfile')}
      >
        <DeleteProfile
          data={data}
          token={router?.query?.deleteProfile}
          router={router}
        />
      </Modal>
      {isMobile ? (
        <HeaderMobileLazy
          data={data}
          onRegister={onRegister}
          onLogin={onLogin}
          logout={logout}
          navigationItems={navigationItems}
          isShowPhone={isShowPhone}
        />
      ) : (
        <HeaderDesktopLazy
          data={data}
          onRegister={onRegister}
          onLogin={onLogin}
          logout={logout}
          navigationItems={navigationItems}
          isShowPhone={isShowPhone}
        />
      )}
    </Box>
  );
};

const WrappedComponent = withUrqlClient()(Header);
export default WrappedComponent;
