import { createClient } from '@urql/core';
import { api } from '../../config';

let urqlClient = null;

export function resetClient() {
  urqlClient = null;
}

export function initUrqlClient(clientOptions, canEnableSuspense = false) {
  // Create a new Client for every server-side rendered request.
  // This ensures we reset the state for each rendered page.
  // If there is an exising client instance on the client-side, use it.
  const isServer = typeof window === 'undefined';
  if (isServer || !urqlClient) {
    urqlClient = createClient({
      url: `${api}/graphql`,
      ...clientOptions,
      suspense: canEnableSuspense && (isServer || clientOptions.suspense),
      maskTypename: true,
    });
    // Serialize the urqlClient to null on the client-side.
    // This ensures we don't share client and server instances of the urqlClient.
    urqlClient.toJSON = () => null;
  }

  // Return both the Client instance and the ssrCache.
  return urqlClient;
}
