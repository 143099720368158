import { createElement, useCallback, useReducer, useMemo } from 'react';
import {
  Provider,
  ssrExchange,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';
import { initUrqlClient, resetClient } from './init-urql-client';
import {
  getAuth,
  addAuthToOperation,
  didAuthError,
  // willAuthError,
} from './auth';
import { retryExchangeOptions } from './helpers';

let ssr;

export function withUrqlClient(options = {}) {
  return AppOrPage => {
    const WithUrql = ({ pageProps, urqlClient, urqlState, ...rest }) => {
      const [version, forceUpdate] = useReducer(prev => prev + 1, 0);
      const urqlServerState = (pageProps && pageProps.urqlState) || urqlState;

      const client = useMemo(() => {
        if (urqlClient && !version) {
          return urqlClient;
        }

        if (!ssr || typeof window === 'undefined') {
          // We want to force the cache to hydrate, we do this by setting the isClient flag to true
          ssr = ssrExchange({
            initialState: urqlServerState,
            isClient: true,
            staleWhileRevalidate:
              typeof window !== 'undefined'
                ? options.staleWhileRevalidate
                : undefined,
          });
        } else if (!version) {
          ssr.restoreData(urqlServerState);
        }

        const clientConfig = {
          exchanges: [
            dedupExchange,
            cacheExchange,
            retryExchange(retryExchangeOptions),
            ssr,
            authExchange({
              getAuth,
              addAuthToOperation,
              didAuthError,
              // willAuthError,
            }),
            fetchExchange,
          ],
        };

        return initUrqlClient(clientConfig);
      }, [urqlClient, urqlServerState, version]);

      const resetUrqlClient = useCallback(() => {
        resetClient();
        ssr = ssrExchange({ initialState: undefined });
        forceUpdate();
      }, []);

      return createElement(
        Provider,
        { value: client },
        createElement(AppOrPage, {
          ...rest,
          pageProps,
          urqlClient: client,
          resetUrqlClient,
        }),
      );
    };

    // Set the displayName to indicate use of withUrqlClient.
    const displayName = AppOrPage.displayName || AppOrPage.name || 'Component';
    WithUrql.displayName = `withUrqlClient(${displayName})`;

    if (AppOrPage.getLayout) {
      WithUrql.getLayout = AppOrPage.getLayout;
    }

    return WithUrql;
  };
}
