export const EXECUTORS_PREVIEW_PAGE_SIZE = 6;

const WEEK = 604800;
const HALF_AN_HOUR = 1800;
const HOUR = 3600;
const DAY = 86400;
const ONE_SEC = 1;

export const timeInSeconds = {
  WEEK,
  HALF_AN_HOUR,
  HOUR,
  DAY,
  ONE_SEC,
};

export const MIN_HEIGHT = 'calc(100vh - 64px)';
export const MIN_HEIGHT_WITH_PADDING = 'calc(100vh - 104px)';

export const WHATSAPP = 'WHATSAPP';
export const TELEGRAM = 'TELEGRAM';
export const VIBER = 'VIBER';
export const EMAIL = 'EMAIL';
export const FACEBOOK_MESSENGER = 'FACEBOOK_MESSENGER';
export const EXECUTOR = 'EXECUTOR';
export const SYSTEM_USER = 'SYSTEM_USER';

export const JOB_TYPE = 'JOB_TYPE';
export const PRICE_DATA = 'PRICE_DATA';
export const UNIT = 'UNIT';
export const DATE = 'DATE';
export const PRICE = 'PRICE';
export const PRICE_RANGE = 'PRICE_RANGE';
export const DELETE = 'DELETE';
export const PORTFOLIO = 'PORTFOLIO';

export const SIGNUP_URL = '/signup?step=0-7';

export const SUBSCRIBED = 'SUBSCRIBED';
export const DEFAULT_JOB_TYPES_VARIABLES = {
  filter: {
    pagination: {
      page: 1,
      pageSize: 9999,
    },
    sortBy: {
      id: 'order',
      value: 'ASC',
    },
  },
};

export const userTypes = {
  ORDERER: 'ORDERER',
  EXECUTOR: 'EXECUTOR',
  NEW_USER: 'NEW_USER',
  DELETED: 'DELETED',
};

export const tabTypes = {
  ORDERER: 'orderer',
  EXECUTOR: 'executor',
  NEW_USER: 'new-user',
};

export const imageSizes = {
  S: 'S',
  S_ORIGINAL_ASPECT_RATIO: 'S_ORIGINAL_ASPECT_RATIO',
  XS: 'XS',
  M: 'M',
};

export const closedOrderReason = [
  {
    id: 'executor-not-found',
  },
  {
    id: 'agreed-by-phone',
  },
  {
    id: 'found-on-another-site',
  },
  {
    id: 'other',
  },
];

export const complainTypeApproveOrder = [
  {
    id: 'profanity',
  },
  {
    id: 'fraud',
  },
  {
    id: 'other',
  },
];

export const complainTypeOpenOrder = [
  {
    id: 'spam',
  },
  {
    id: 'not-an-order',
  },
  {
    id: 'profanity',
  },
  {
    id: 'incorrect-description',
  },
  {
    id: 'incorrect-price',
  },
  {
    id: 'invalid-service',
  },
  {
    id: 'fraud',
  },
  {
    id: 'other',
  },
];

export const defaultUnits = {
  SERVICE: {
    key: 'SERVICE',
  },
  MINUTE: {
    key: 'MINUTE',
  },
  HALF_HOUR: {
    key: 'HALF_HOUR',
  },
  ACADEMIC_HOUR: {
    key: 'ACADEMIC_HOUR',
  },
  HOUR: {
    key: 'HOUR',
  },
  DAY: {
    key: 'DAY',
  },
  WEEK: {
    key: 'WEEK',
  },
  MONTH: {
    key: 'MONTH',
  },
  METRE: {
    key: 'METRE',
  },
  SM_2: {
    key: 'SM_2',
  },
  M_2: {
    key: 'M_2',
  },
  M_3: {
    key: 'M_3',
  },
  KM: {
    key: 'KM',
  },
  ACR: {
    key: 'ACR',
  },
  GRAM: {
    key: 'GRAM',
  },
  KG: {
    key: 'KG',
  },
  TONNE: {
    key: 'TONNE',
  },
  LESSON: {
    key: 'LESSON',
  },
  PIECE: {
    key: 'PIECE',
  },
  CHARACTERS_1000: {
    key: 'CHARACTERS_1000',
  },
  R_M: {
    key: 'R_M',
  },
};

const DMY = 'DD.MM.YYYY';
const DMYHM = 'DD.MM.YYYY hh:mm';
const DMYHMS = 'DD.MM.YYYY hh:mm:ss';

export const dateFormat = {
  DMY,
  DMYHM,
  DMYHMS,
};
export const USA = 'usa';
export const BELARUS = 'belarus';

export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
