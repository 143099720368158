/* eslint-disable no-case-declarations */
import {
  DELETE_FULL_NOT,
  DELETE_NOT_ITEM,
  SET_NOT,
  SET_INIT_NOT,
} from './constants';

const calcLength = ntf => {
  const allNtf = Object.values(ntf);
  let ntfLength = 0;

  const ntfLengthByStatus = allNtf.reduce((acc, el) => {
    if (el) {
      el.forEach(e => {
        ntfLength += 1;
        if (acc[e.status]) {
          acc[e.status] += 1;
        } else {
          acc[e.status] = 1;
        }
      });
    }
    return acc;
  }, {});
  return {
    ...ntfLengthByStatus,
    ntfLength,
  };
};

const notificationReducer = (state, action) => {
  switch (action.type) {
    case SET_NOT:
      let newAction = state.ntf[action.data.id]
        ? [...state.ntf[action.data.id], action.data.data]
        : [action.data.data];
      const keyVal = {};
      newAction = newAction.filter(e => {
        if (!keyVal[e.id]) {
          keyVal[e.id] = e.id;
          return true;
        }
        return false;
      });
      const ntf = { ...state.ntf, [action.data.id]: newAction };
      const res = {
        ...state,
        ntf,
        length: calcLength(ntf),
      };

      return res;
    case SET_INIT_NOT:
      return { ...state, ntf: action.data, length: calcLength(action.data) };
    case DELETE_FULL_NOT:
      // eslint-disable-next-line no-param-reassign
      delete state.ntf[action.id];
      const delNtf = { ...state.ntf };
      return { ...state, ntf: delNtf, length: calcLength(delNtf) };
    case DELETE_NOT_ITEM:
      const notEntity = state.ntf[action.data.id];
      if (!notEntity) return state;
      const newNotEntity = notEntity.filter(
        e => !action.data.notIds.includes(e.id),
      );

      const updatedNtf = { ...state.ntf, [action.data.id]: newNotEntity };
      if (!newNotEntity.length) {
        delete updatedNtf[action.data.id];
      }
      return { ...state, ntf: updatedNtf, length: calcLength(updatedNtf) };
    default:
      return state;
  }
};

export default notificationReducer;
