import axios from 'axios';
import { defaultLocale, googleApiKey } from 'src/config';
import settings from 'src/common/settings';
import { mapAddress } from '../model/mapAddress';

const { countryKey } = settings;

export const getGoogleGeoCode = async address => {
  if (address?.length < 3) return [];

  const res = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:${countryKey}&language=${defaultLocale}&key=${googleApiKey}`,
  );

  // Фильтруем результаты убираем из выдачи результат со страной
  // если address_components?.length === 1 то это страна
  // остовляем только результаты с городами населенными пунктами и конкретыми адресами
  const addresses = res?.data?.results
    ? res?.data?.results
        .filter(e => e?.address_components?.length > 1)
        .map(e => ({
          ...mapAddress(e),
          title: e?.address_components
            .reverse()
            .slice(1)
            .map(el => el?.long_name)
            .join(', '),
        }))
    : [];

  return addresses;
};
