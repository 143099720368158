const linksToImg = [
  {
    img: '/icons/Telegram.svg',
    alt: 'Telegram',
    url: 'https://t.me/master_yoda_by_bot',
  },
  {
    img: '/icons/Odnoklassniki.svg',
    alt: 'Odnoklassniki',
    url: 'https://ok.ru',
  },
  {
    img: '/icons/Vk.svg',
    alt: 'Vkontakte',
    url: 'https://vk.com/mysteryodaby',
  },
  {
    img: '/icons/Insta.svg',
    alt: 'Instagram',
    url: 'https://www.instagram.com/masteryoda.by',
  },
];

const email = 'support@localprof.com';

const phone = '291675331';

const address = 'ул. М.Л. Мошенского 85, Брест 224022, Беларусь';

const brand = 'LocalProf';

const defaultJobTypeid = 'one';

const currency = 'Usd';

const countryKey = 'USA';

const popularCities = [
  {
    id: 'minsk--minskaya_oblast',
    name: 'Минск',
    regionName: 'Минская область',
    location: {
      latitude: 53.9045398,
      longitude: 27.5615244,
    },
  },
  {
    id: 'brest--brestskaya_oblast',
    name: 'Брест',
    regionName: 'Брестская область',
    location: {
      latitude: 52.0976214,
      longitude: 23.7340503,
    },
  },
];

const phoneFormat = val =>
  val
    ? `${val ? '(' : ''}${val?.slice(0, 3)}${
        val?.length > 3 ? ') ' : ''
      }${val?.slice(3, 6)}${val?.length > 7 ? ' ' : ''}${val?.slice(6, 10)}`
    : '';
const phonePrefix = '+1';

const phoneRegExp = '^\\([2-9]{1}[0-9]{2}\\) [2-9]{1}[0-9]{2} [0-9]{4}';
const phoneWithoutFormatRegExp = '^[2-9]{1}[0-9]{2}[2-9]{1}[0-9]{2}[0-9]{4}';

const phoneValidation = {
  phonePrefix,
  phoneFormat,
  phoneRegExp,
  phoneWithoutFormatRegExp,
};

module.exports = {
  popularCities,
  currency,
  defaultJobTypeid,
  brand,
  address,
  phone,
  email,
  linksToImg,
  countryKey,
  phoneValidation,
};
