import React from 'react';
import { Icon } from '@chakra-ui/react';

const TelegramIcon = props => (
  <Icon viewBox='0 0 37 32' boxSize={6} {...props}>
    <path
      d='M27.3191 6.39067L12.3878 15.5869C11.5429 16.1085 11.143 17.1387 11.4153 18.0916L13.0297 23.7643C13.1676 24.248 13.598 24.5695 14.0933 24.5695C14.1209 24.5695 14.1487 24.5685 14.1767 24.5665C14.7061 24.528 15.125 24.1337 15.1954 23.6081L15.6152 20.4976C15.6718 20.0791 15.8735 19.6898 16.1825 19.4021L28.5041 7.94855C28.8764 7.60329 28.9256 7.03793 28.6188 6.63354C28.3115 6.22879 27.7531 6.12412 27.3191 6.39067ZM15.1973 18.343C14.6437 18.8584 14.283 19.5548 14.1817 20.304L13.9781 21.8128L12.8062 17.695C12.711 17.3615 12.8512 17.0007 13.1469 16.8182L24.0852 10.0814L15.1973 18.343Z'
      fill='currentColor'
    />
    <path
      d='M35.7148 0.396188C35.2483 0.000799014 34.6046 -0.108047 34.0344 0.112197L1.06933 12.8292C0.416469 13.081 -0.00331303 13.6957 1.96997e-05 14.3952C0.00342334 15.0954 0.429375 15.7067 1.08606 15.9527L8.78609 18.8266L11.7897 28.4862C11.9547 29.0172 12.371 29.4299 12.9032 29.59C13.4352 29.75 14.0097 29.6356 14.4391 29.2844L18.9163 25.6345C19.1313 25.4592 19.4383 25.4505 19.6628 25.6135L27.7382 31.4764C28.0287 31.6875 28.3716 31.7962 28.7184 31.7962C28.9215 31.7962 29.126 31.7589 29.3223 31.6831C29.8531 31.4778 30.2392 31.0228 30.3547 30.4661L36.2706 2.00958C36.3948 1.4104 36.1819 0.792216 35.7148 0.396188ZM34.8544 1.71567L28.9386 30.1721C28.9172 30.2749 28.8432 30.3177 28.8009 30.334C28.7584 30.3505 28.6743 30.3687 28.5884 30.3062L20.5128 24.443C20.1518 24.181 19.7273 24.0508 19.3036 24.0508C18.8414 24.0508 18.3799 24.2058 18.0026 24.5131L13.5245 28.1639C13.4447 28.2291 13.362 28.2175 13.3199 28.2048C13.2775 28.192 13.2018 28.1558 13.171 28.0569L10.062 18.0582C9.99559 17.8446 9.83391 17.6737 9.62431 17.5954L1.59278 14.5979C1.47252 14.5528 1.44692 14.459 1.44657 14.3882C1.44621 14.318 1.47089 14.2246 1.59002 14.1787L34.5552 1.46174C34.5553 1.46167 34.5555 1.4616 34.5558 1.46153C34.6628 1.42019 34.742 1.4677 34.7798 1.49961C34.8176 1.53187 34.8779 1.60278 34.8544 1.71567Z'
      fill='currentColor'
    />
  </Icon>
);
export default TelegramIcon;
