import { baseUrl } from 'src/config';
import { setCity } from 'src/store/common/actions';
import { getCityData } from 'src/widgets/SelectCity';

const initPath = '/[ssgParams]';

const getPathName = pathname => {
  if (pathname === initPath) return '/';
  if (pathname.includes(initPath)) return `${pathname}`.split(initPath)?.[1];

  return pathname;
};

const getQuery = query => {
  const params = { ...query };
  if (params?.ssgParams) delete params.ssgParams;
  return params;
};

const updateQueryParam = (router, paramName, paramValue) => {
  const query = getQuery(router.query);

  const newQuery = { ...query, [paramName]: paramValue };
  router.push({ pathname: getPathName(router.pathname), query: newQuery });
};

const parseUrl = url => {
  const parsedUrl = new URL(url, baseUrl); // we need to provide a base URL, but it can be any valid URL since we're only interested in the relative URL
  const pathName = parsedUrl.pathname;
  const queryObj = Object.fromEntries(parsedUrl.searchParams.entries());
  return { pathName, queryObj };
};

const getHref = input => {
  const queryParams = Object.keys(input.query).map(
    key => `${key}=${input.query[key]}`,
  );
  const queryString = queryParams.join('&');
  return `${input.pathName}?${queryString}`;
};

const getHrefWithoutSlash = href => {
  if (href[href.length - 1] !== '/') return href;
  return href.slice(0, -1);
};

const getHrefWithCity = (href, cityId) => {
  if (!href.startsWith('/') || !cityId) return href;
  let newHref = href;
  if (
    href.split('/')?.[1].includes('cityId') &&
    href.split('/')?.[1].includes('device')
  ) {
    newHref = href.split('/').slice(2).join('/');
  }

  const { pathName, queryObj } = parseUrl(newHref);
  const query = { ...queryObj, city: cityId };

  return getHref({
    pathName,
    query,
  });
};

const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const setQuery = ({ router, val, queryName, deleteQuery, dispatch }) => {
  if (!isJson(router.query?.ssgParams) && queryName === 'city') {
    const newHref = getHrefWithCity(router.asPath, val);
    router.push(newHref);
    return;
  }

  const params = getQuery(router.query);
  if (queryName === 'city') {
    setCity(dispatch, getCityData(val));
  }

  let page = {};
  if (router.query?.page) {
    page = { page: 1 };
  }

  const query = deleteQuery
    ? {
        ...Object.entries(params)
          .filter(([key]) => key !== deleteQuery)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
        ...page,
      }
    : { ...params, ...page };

  if (val) query[queryName] = val;

  router.push({
    pathname: getPathName(router.pathname),
    query: val
      ? query
      : Object.entries(query)
          .filter(([key]) => key !== queryName)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  });
};

const deleteQuery = (router, urlQuery) => {
  const params = getQuery(router.query);

  return router.replace(
    {
      pathname: getPathName(router.pathname),
      query: Object.entries(params)
        .filter(([key]) => key !== urlQuery)
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    },
    undefined,
    {
      scroll: false,
    },
  );
};

const getPreviousUrl = (itemStatus, isMobile, isOrder, statuses) => {
  switch (itemStatus) {
    case statuses.IN_PROGRESS:
      return isMobile
        ? `?index=open${isOrder ? 'Orders' : 'Tasks'}`
        : `?index=${isOrder ? 'orders' : 'tasks'}&type=open${
            isOrder ? 'Orders' : 'Tasks'
          }&scroll=true`;
    case statuses.WAITING_FOR_OWNER_COMPLETE:
    case statuses.APPROVED:
      return isMobile
        ? `?index=during${isOrder ? 'Orders' : 'Tasks'}`
        : `?index=${isOrder ? 'orders' : 'tasks'}&type=during${
            isOrder ? 'Orders' : 'Tasks'
          }&scroll=true`;
    case statuses.DONE:
      return isMobile
        ? `?index=closed${isOrder ? 'Orders' : 'Tasks'}`
        : `?index=${isOrder ? 'orders' : 'tasks'}&type=closed${
            isOrder ? 'Orders' : 'Tasks'
          }&scroll=true`;
    default:
      return null;
  }
};

const routerPush = (router, href, as, options) =>
  router.push(href, as, options);
const routerReplace = (router, href) => router.replace(href);
const routerReload = (router, href) => router.reload(href);

const getPathForCategory = (path, router) => {
  if (router?.query?.cityName)
    return `/${router?.query?.region}/${router?.query?.cityName}/${path}`;
  return `/near-me/${path}`;
};

export {
  deleteQuery,
  getPreviousUrl,
  setQuery,
  getPathName,
  getQuery,
  initPath,
  getHrefWithCity,
  routerPush,
  routerReplace,
  routerReload,
  getHrefWithoutSlash,
  updateQueryParam,
  getPathForCategory,
};
