const space = {
  0: '0px',
  px: '1px',
  0.5: '2px',
  1: '4px',
  1.5: '6px',
  2: '8px',
  2.5: '10px',
  3: '12px',
  3.5: '14px',
  4: '16px',
  4.5: '18px',
  5: '20px',
  6: '24px',
  6.5: '26px',
  7: '28px',
  7.5: '30px',
  8: '32px',
  8.5: '34px',
  9: '36px',
  10: '40px',
  10.5: '42px',
  11: '44px',
  11.5: '46px',
  12: '48px',
  12.5: '50px',
  13: '52px',
  14: '56px',
  15: '60px',
  16: '64px',
  16.5: '66px',
  17: '68px',
  18: '72px',
  19: '76px',
  20: '80px',
  21: '84px',
  21.5: '86px',
  22: '88px',
  23: '92px',
  23.5: '94px',
  24: '96px',
  25: '100px',
  27: '108px',
  28: '112px',
  29: '116px',
  30: '120px',
  32: '128px',
  33: '132px',
  35: '140px',
  36: '144px',
  37.5: '150px',
  38: '152px',
  39: '156px',
  40: '160px',
  41: '164px',
  42.5: '170px',
  43: '172px',
  44: '176px',
  45: '180px',
  46: '184px',
  48: '192px',
  50: '200px',
  52: '208px',
  52.5: '210px',
  55: '220px',
  56: '224px',
  58: '232px',
  60: '240px',
  61: '244px',
  64: '256px',
  65: '260px',
  66: '264px',
  68: '272px',
  70: '280px',
  72.5: '290px',
  75: '300px',
  76: '304px',
  76.5: '290px',
  77.5: '310px',
  80: '320px',
  81: '324px',
  82: '328px',
  85: '340px',
  86: '344px',
  87.5: '350px',
  91: '364px',
  94.5: '378px',
  95: '380px',
  96: '384px',
  100: '400px',
  101.5: '406px',
  103: '412px',
  106: '424px',
  110: '440px',
  114: '456px',
  119: '476px',
  120: '480px',
  125: '500px',
  130: '520px',
  140: '560px',
  150: '600px',
  183: '732px',
  194: '776px',
  201.5: '806px',
  200: '800px',
  285: '1140px',
};

export default space;
