import {
  ssrExchange,
  dedupExchange,
  cacheExchange,
  fetchExchange,
  useMutation,
} from 'urql';
import { retryExchange } from '@urql/exchange-retry';
import { useEffect } from 'react';
import { timeInSeconds } from '../../common/constants';
import { initUrqlClient } from './init-urql-client';

// Функция для проверки наличия ошибок в закешированных запросах
// Если найдены запросы с ощибками то кэш таких запросов удаляется
// и время обнавления страницы изменяется на минимальное
export const checkRequestErrors = (cache, time) => {
  const errors = [];
  const cacheKeys = Object.keys(cache);

  cacheKeys.forEach(e => {
    if (
      cache[e]?.error &&
      cache[e]?.error?.graphQLErrors?.[0]?.extensions?.code !== 404
    ) {
      errors.push(e);
    }
  });

  const urqlState = cache;
  let revalidate = time;

  if (errors.length) {
    revalidate = timeInSeconds.ONE_SEC;
    errors.forEach(e => delete urqlState[e]);
  }

  return {
    revalidate,
    urqlState,
  };
};

export const retryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: error => error?.graphQLErrors?.[0]?.extensions?.code !== 404,
};

export const getServerCLient = () => {
  const ssrCache = ssrExchange({ isClient: false });
  const client = initUrqlClient({
    exchanges: [
      dedupExchange,
      cacheExchange,
      retryExchange(retryExchangeOptions),
      ssrCache,
      fetchExchange,
    ],
  });
  return {
    client,
    ssrCache,
  };
};

export const useMutationUrql = (mutation, actions = {}) => {
  const { onCompleted, onError } = actions;
  const [{ fetching, data, error }, action] = useMutation(mutation);

  useEffect(() => {
    if (fetching) return;
    if (data && typeof data === 'object' && !fetching && onCompleted) {
      const dataKeys = Object.keys(data);
      const completedData = data?.[dataKeys?.[0]];
      if (completedData && completedData !== null) onCompleted(data);
    }
    if (error && !fetching && onError) {
      onError(error);
    }
  }, [fetching, data, error]);

  return [action, { loading: fetching, data, error }];
};
