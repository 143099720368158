import React, { useContext, useState } from 'react';
import { commonStore } from './storeProvider';

const taskStore = React.createContext();
export const useTaskStore = () => useContext(taskStore);

export const TaskProvider = ({ children }) => {
  const {
    common: { city },
  } = useContext(commonStore);

  const [task, setTask] = useState(
    city?.id
      ? {
          address: {
            city,
            street: '',
          },
        }
      : {},
  );

  const [orderToken, setOrderToken] = useState();

  const setTaskValue = (type, value) =>
    setTask({ ...task, [type]: value || null });

  const clearTask = () => {
    setTask(
      city?.id
        ? {
            address: {
              city,
              street: '',
            },
          }
        : {},
    );
    setOrderToken(null);
  };

  const value = {
    task,
    orderToken,
    clearTask,
    setTask,
    setTaskValue,
    setOrderToken,
  };

  return <taskStore.Provider value={value}>{children}</taskStore.Provider>;
};
