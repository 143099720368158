import WalletIcon from './WalletIcon.jsx';
import CostIcon from './Cost.jsx';
import LocationIcon from './Location.jsx';
import PeopleIcon from './People.jsx';
import MessengerIcon from './MessengerIcon.jsx';
import TelegramIcon from './TelegramIcon.jsx';
import ViberIcon from './ViberIcon.jsx';
import EmailIcon from './EmailIcon.jsx';
import WhatsAppIcon from './WhatsAppIcon.jsx';
import GetOfferIcon from './GetOfferIcon.jsx';
import ViewsIcon from './ViewsIcon.jsx';

export {
  WalletIcon,
  CostIcon,
  LocationIcon,
  PeopleIcon,
  ViberIcon,
  TelegramIcon,
  MessengerIcon,
  EmailIcon,
  WhatsAppIcon,
  GetOfferIcon,
  ViewsIcon,
};
