/* eslint-disable no-underscore-dangle */
import { theme as chakraTheme } from '@chakra-ui/react';
import * as rest from '@chakra-ui/theme-tools';
import { primaryGradient, constants } from '../constants';

const { mode, transparentize } = rest;

function variantGhost(props) {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    return {
      color: mode('inherit', 'whiteAlpha.900')(props),
      _hover: {
        bg: mode('brand.secondaryBackground', 'whiteAlpha.200')(props),
      },
      _active: { bg: mode('gray.200', 'whiteAlpha.300')(props) },
    };
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);

  return {
    color: mode(`${c}.600`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  };
}

function base(props) {
  const { colorScheme: c } = props;
  const borderColor = mode('gray.200', 'whiteAlpha.300')(props);

  return {
    cursor: 'pointer',
    border: '1px solid',
    borderColor: c === 'gray' ? borderColor : 'currentColor',
    ...variantGhost(props),
  };
}

const Button = {
  // 1. We can update the base styles
  baseStyle: {
    fontWeight: 'normal',
  },
  variants: {
    default: props => ({
      ...base(props),
      bg: 'brand.secondaryBackground',
      color: 'typography.primary',
      _hover: {
        _disabled: {
          bg: 'typography.primary',
        },
        color: 'white',
        bg: 'typography.primary',
      },
    }),
    defaultDisabled: props => ({
      ...base(props),
      bg: 'brand.secondaryBackground',
      color: 'typography.primary',
      _hover: {
        bg: 'brand.secondaryBackground',
      },
      cursor: 'auto',
    }),
    outline: props => ({
      ...base(props),
      bg: 'white',
    }),
    primaryLink: props => ({
      ...base(props),
      border: 'none',
      textAlign: 'left',
      p: 0,
      height: 'auto',
      justifyContent: 'start',
      _hover: {
        color: 'brand.primary',
      },
      _active: {
        bg: 'none',
      },
    }),
    error: props => ({
      ...base(props),
      borderColor: constants.error200,
      _hover: {
        _disabled: {
          bg: constants.error200,
        },
        bg: constants.error200,
      },
      bg: constants.error100,
    }),
    primary: props => ({
      ...base(props),
      bg: 'brand.primaryGradient',
      color: 'white',
      _hover: {
        _disabled: {
          bg: 'brand.primaryGradientLight',
        },
        bg: 'brand.primaryGradientLight',
      },
    }),
    secondary: props => ({
      ...base(props),
      bg: `linear-gradient(#ffffff,#ffffff) padding-box, ${primaryGradient} border-box`,
      border: '1px solid',
      borderColor: 'transparent',
      color: 'brand.primary',
      _hover: {
        _disabled: {
          bg: `linear-gradient(#ffffff,#ffffff) padding-box, ${primaryGradient} border-box`,
        },
        boxShadow: '0px 5px 20px 0px #2f89922e;',
      },
    }),
    primaryBlack: props => ({
      ...base(props),
      bg: 'black.500',
      color: 'white',
      border: 'none',
      _hover: {
        _disabled: {
          bg: 'black.400',
        },
        bg: 'black.400',
      },
    }),
    primaryBlackDisabled: props => ({
      ...base(props),
      bg: 'black.500',
      color: 'white',
      border: 'none',
      _hover: {
        _disabled: {
          bg: 'black.500',
        },
        bg: 'black.500',
      },
      cursor: 'auto',
    }),
    secondaryBlack: props => ({
      ...base(props),
      bg: 'transparent',
      color: 'typography.primary',
      borderColor: 'gray.900',
      border: '1px solid',
      _hover: {
        _disabled: {
          bg: 'black.500',
        },
        bg: 'black.500',
        color: 'white',
      },
    }),
    primaryWhite: props => ({
      ...base(props),
      border: 'none',
      _hover: {
        _disabled: {
          bg: 'white',
        },
        bg: 'white',
      },
    }),
  },
  // fonts: {
  //   body: 'Manrope, sans-serif',
  //   heading: 'Manrope, serif',
  //   mono: 'Manrope, monospace',
  // },
  defaultProps: {
    size: 'lg',
  },
  sizes: {
    lg: {
      ...chakraTheme.components.Button.sizes.lg,
      fontSize: 'md',
    },
    md: {
      ...chakraTheme.components.Button.sizes.md,
      fontSize: 'md',
    },
    sm: {
      ...chakraTheme.components.Button.sizes.sm,
      fontSize: 'sm',
    },
    xs: {
      ...chakraTheme.components.Button.sizes.xs,
      fontSize: 'xs',
    },
  },
};

export default Button;
