import React from 'react';
import { Icon } from '@chakra-ui/react';

const MessengerIcon = props => (
  <Icon viewBox='0 0 157 157' boxSize={6} {...props}>
    <path
      d='M0 76.145C0 32.4009 34.2849 0 78.5 0C122.715 0 157 32.4205 157 76.1646C157 119.909 122.715 152.31 78.5 152.31C70.5519 152.31 62.9374 151.25 55.7743 149.287C54.3809 148.915 52.8894 149.013 51.5745 149.601L35.9922 156.47C35.0512 156.885 34.0225 157.063 32.9967 156.987C31.9709 156.912 30.9793 156.586 30.1091 156.037C29.2389 155.489 28.5168 154.735 28.0062 153.842C27.4956 152.949 27.2121 151.944 27.1806 150.916L26.7489 136.943C26.7096 135.216 25.9246 133.607 24.649 132.469C9.38075 118.81 0 99.0278 0 76.145ZM54.4199 61.8383L31.3606 98.4193C29.1429 101.932 33.4604 105.877 36.7574 103.365L61.5242 84.5641C62.3391 83.9451 63.3334 83.6084 64.3567 83.6049C65.38 83.6013 66.3766 83.9312 67.1958 84.5445L85.5452 98.3016C86.8467 99.279 88.3367 99.976 89.9212 100.349C91.5056 100.721 93.1501 100.761 94.7508 100.466C96.3515 100.171 97.8737 99.5473 99.2212 98.6343C100.569 97.7213 101.712 96.5388 102.58 95.1616L125.659 58.6002C127.857 55.0873 123.539 51.1231 120.242 53.6351L95.4754 72.4358C94.6605 73.0548 93.6662 73.3915 92.6429 73.395C91.6196 73.3986 90.623 73.0688 89.8038 72.4555L71.4544 58.6983C70.1529 57.7209 68.6629 57.0239 67.0785 56.6513C65.494 56.2788 63.8495 56.2388 62.2488 56.5339C60.6481 56.8289 59.126 57.4526 57.7785 58.3656C56.431 59.2786 55.2874 60.4611 54.4199 61.8383Z'
      fill='currentColor'
    />
  </Icon>
);
export default MessengerIcon;
