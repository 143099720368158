import React from 'react';
import { Icon } from '@chakra-ui/react';

const WalletIcon = props => (
  <Icon viewBox='0 0 11 10' {...props}>
    <path
      d='M9 2.5H8.5V2C8.5 1.60218 8.34196 1.22064 8.06066 0.93934C7.77936 0.658035 7.39782 0.5 7 0.5H2C1.60218 0.5 1.22064 0.658035 0.93934 0.93934C0.658035 1.22064 0.5 1.60218 0.5 2V8C0.5 8.39782 0.658035 8.77936 0.93934 9.06066C1.22064 9.34196 1.60218 9.5 2 9.5H9C9.39782 9.5 9.77936 9.34196 10.0607 9.06066C10.342 8.77936 10.5 8.39782 10.5 8V4C10.5 3.60218 10.342 3.22064 10.0607 2.93934C9.77936 2.65804 9.39782 2.5 9 2.5ZM2 1.5H7C7.13261 1.5 7.25979 1.55268 7.35355 1.64645C7.44732 1.74021 7.5 1.86739 7.5 2V2.5H2C1.86739 2.5 1.74021 2.44732 1.64645 2.35355C1.55268 2.25979 1.5 2.13261 1.5 2C1.5 1.86739 1.55268 1.74021 1.64645 1.64645C1.74021 1.55268 1.86739 1.5 2 1.5ZM9.5 6.5H9C8.86739 6.5 8.74021 6.44732 8.64645 6.35355C8.55268 6.25979 8.5 6.13261 8.5 6C8.5 5.86739 8.55268 5.74021 8.64645 5.64645C8.74021 5.55268 8.86739 5.5 9 5.5H9.5V6.5ZM9.5 4.5H9C8.60218 4.5 8.22064 4.65804 7.93934 4.93934C7.65804 5.22064 7.5 5.60218 7.5 6C7.5 6.39782 7.65804 6.77936 7.93934 7.06066C8.22064 7.34196 8.60218 7.5 9 7.5H9.5V8C9.5 8.13261 9.44732 8.25979 9.35355 8.35355C9.25979 8.44732 9.13261 8.5 9 8.5H2C1.86739 8.5 1.74021 8.44732 1.64645 8.35355C1.55268 8.25979 1.5 8.13261 1.5 8V3.415C1.66063 3.47151 1.82972 3.50025 2 3.5H9C9.13261 3.5 9.25979 3.55268 9.35355 3.64645C9.44732 3.74021 9.5 3.86739 9.5 4V4.5Z'
      fill='currentColor'
    />
  </Icon>
);
export default WalletIcon;
