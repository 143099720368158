import { SIGNUP_URL } from './constants';

const navigationItemsHeader = [
  { name: 'create-task', to: '/create-task' },
  { name: 'services', to: '/near-me' },
  { name: 'executors', to: '/executors' },
  { name: 'become-a-executor', to: SIGNUP_URL },
];

const navigationItemsFooter = [
  { name: 'home', to: '/' },
  { name: 'executors', to: '/executors' },
  { name: 'create-task', to: '/create-task' },
  { name: 'services', to: '/near-me' },
  { name: 'become-a-executor', to: SIGNUP_URL },
  { name: 'terms', to: '/terms' },
  { name: 'privacy-policy', to: '/privacy-policy' },
  // { name: 'blog', to: '/blog' },
];

export { navigationItemsHeader, navigationItemsFooter };
