import React from 'react';
import { Button, Flex, Heading, Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { routerPush } from 'src/utils/urlControl';
import settings from 'src/common/settings';
import { SIGNUP_URL } from 'src/common/constants';
import useModalPaddings from 'src/hooks/useModalPaddings';
import Separator from '../Separator';

const RegisterModalContent = ({ onOpenLogin }) => {
  const { brand } = settings;

  const { t } = useTranslation(['common']);
  const router = useRouter();
  const boxPaddings = useModalPaddings()

  return (
    <Box {...boxPaddings} overflowY='auto'>
      <Flex
        alignItems='center'
        flexWrap='wrap'
        justifyContent='center'
        pb={5}
        data-testid='registration-heading'
      >
        <Heading as='h2' size='lg' mr='10px'>
          {t('register-modal.register-in')}
        </Heading>
        <Heading as='h2' size='lg' color='brand.primary'>
          {brand}
        </Heading>
      </Flex>
      <Separator my={3} />
      <Flex alignItems='center' flexDirection='column' overflow='hidden'>
        <Text
          mb={2}
          mx={2}
          color='typography.secondary'
          fontSize='lg'
          textAlign='center'
        >
          {t('register-modal.become-executor-or-create-task')}
        </Text>
        <Button
          mt={5}
          w={52}
          variant='primary'
          data-gtm='start-signup'
          data-testid='to-signup'
          onClick={() => routerPush(router, SIGNUP_URL)}
        >
          {t('button.become-a-executor')}
        </Button>
        <Box w={20} my={4}>
          <hr />
        </Box>
        <Button
          mb={5}
          w={52}
          variant='primary'
          onClick={() => routerPush(router, '/create-task')}
        >
          {t('button.create-task')}
        </Button>
      </Flex>
      <Separator my={3} />
      <Flex justifyContent='center' mt={1}>
        <Text as='span' mr={0.5} fontSize='sm'>
          {t('already-have-account')}
        </Text>
        <Button
          color='brand.primary'
          fontSize='sm'
          variant='link'
          onClick={onOpenLogin}
        >
          {t('button.log-in')}
        </Button>
      </Flex>
    </Box>
  );
};

export default RegisterModalContent;
