import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { size } from '../../theme/constants';

const DefaultContainer = props => {
  const { children, container, bg, isPaddingMd, ...other } = props;
  return (
    <Box w='100%' bg={bg} {...container}>
      <Flex
        bg={bg}
        maxW={285}
        mx='auto'
        w='100%'
        px={[
          size.container.mobile.x,
          size.container.mobile.x,
          size.container.desktop.x,
        ]}
        pt={
          isPaddingMd
            ? [
                size.container.mobile.y2,
                size.container.mobile.y2,
                size.container.desktop.y2,
              ]
            : [
                size.container.mobile.y,
                size.container.mobile.y,
                size.container.desktop.y,
              ]
        }
        pb={
          isPaddingMd
            ? [
                size.container.mobile.y2,
                size.container.mobile.y2,
                size.container.desktop.y2,
              ]
            : [
                size.container.mobile.y,
                size.container.mobile.y,
                size.container.desktop.y,
              ]
        }
        flexDirection='column'
        {...other}
      >
        {children}
      </Flex>
    </Box>
  );
};

DefaultContainer.propTypes = {
  children: PropTypes.any,
  container: PropTypes.object,
  bg: PropTypes.any,
};

export default DefaultContainer;
