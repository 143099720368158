import React, { useEffect, useState } from 'react';
import {
  Button,
  Text,
  Box,
  Heading,
  Flex,
  Img,
  Spinner,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import useModalPaddings from 'src/hooks/useModalPaddings';
import { CONFIRM_EMAIL, SEND_CONFIRM_EMAIL } from '../../gql/mutation';
import { onCloseModal, onOpenLogin } from '../../utils/modalControl';
import { throwErrorSentry } from '../../utils/throwErrorSentry';
import useToast from '../../hooks/useToast';
import { useMutationUrql } from '../../utils/urqlClient/helpers';

const ConfirmEmail = ({ data, token, profileRefetch, router }) => {
  const { t } = useTranslation(['common']);
  const [isSubmit, setSubmit] = useState(false);
  const { showErrorToast, showSuccessToast } = useToast();
  const modalPaddings = useModalPaddings();

  const [sendConfirm, { loading }] = useMutationUrql(SEND_CONFIRM_EMAIL, {
    onCompleted: () => {
      showSuccessToast(t('email-sent-successfully', { ns: 'common' }));
    },
    onError: error => {
      throwErrorSentry(error);
      showErrorToast(t('errors-messages.default-error', { ns: 'common' }));
    },
  });

  const sendConfirmEmail = () => sendConfirm({});

  const [err, setError] = useState(false);

  const [confirmEmail] = useMutationUrql(CONFIRM_EMAIL, {
    onCompleted: () => {
      profileRefetch();
    },
    onError: error => {
      if (error?.graphQLErrors?.[0]?.extensions?.code !== 404) {
        throwErrorSentry(error);
      }
      setError(true);
      profileRefetch();
    },
  });

  const onSubmit = () => {
    if (data?.profile?.email?.email && data?.me?.id)
      confirmEmail({
        input: {
          email: data?.profile?.email?.email,
          token,
        },
      });
  };

  useEffect(() => {
    if (data?.me?.id && router?.query?.modal === 'login') {
      onCloseModal(router);
    }
    if (!data?.me?.id && !router?.query?.modal) {
      onOpenLogin(router);
    }
    if (data?.me?.id && !isSubmit && data?.profile?.email?.email) {
      setSubmit(true);
      onSubmit();
    }
  }, [data?.me?.id, data?.profile?.email?.email]);

  if (err && data?.me?.id) {
    return (
      <Flex {...modalPaddings} alignItems='center' flexDirection='column'>
        <Heading as='h3' size='1-2xl' textAlign='center'>
          {t('confirm-email-modal.failed-to-verify-email')}
        </Heading>
        <Text mb={5} color='typography.secondary' textAlign='center'>
          {t('confirm-email-modal.link-expired')}
        </Text>
        <Button
          mt={5}
          w='100%'
          variant='secondary'
          onClick={sendConfirmEmail}
          isLoading={loading}
          loadingText={t('button.get-new-link')}
        >
          {t('button.get-new-link')}
        </Button>
        <Button
          mt={5}
          w='100%'
          variant='primary'
          onClick={() => onCloseModal(router, 'confirmEmail')}
        >
          {t('button.closed')}
        </Button>
      </Flex>
    );
  }

  if (!data?.me?.id) {
    return (
      <Box>
        <Heading as='h3' size='1-2xl' mb={5} textAlign='center'>
          {t('confirm-email-modal.need-login')}
        </Heading>
        <Button
          w='100%'
          variant='primaryBlack'
          onClick={() => onOpenLogin(router)}
        >
          {t('button.log-in')}
        </Button>
      </Box>
    );
  }

  return data?.profile?.email?.status ? (
    <Flex alignItems='center' flexDirection='column'>
      <Img h={[30, 30, 36]} src='/images/BestFinish.svg' />
      <Heading size='2-3xl' textAlign='center' mt={3}>
        {t('congratulate')}
      </Heading>
      <Text color='typography.secondary' textAlign='center'>
        {t('confirm-email-modal.your-email-has-been-confirmed')}
      </Text>
      <Button
        mt={5}
        w='100%'
        variant='primary'
        onClick={() => routerPush(router, '/create-task')}
      >
        {t('button.create-task')}
      </Button>
    </Flex>
  ) : (
    <Flex alignItems='center' flexDirection='column'>
      <Heading as='h3' size='1-2xl' textAlign='center'>
        {t('confirm-email-modal.need-login')}
      </Heading>
      <Text mb={5} color='typography.secondary' textAlign='center'>
        {data?.profile?.email?.email}
      </Text>
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.100'
        color='brand.primary'
        size='xl'
      />
    </Flex>
  );
};

export default ConfirmEmail;
