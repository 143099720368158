/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Icon } from '@chakra-ui/react';

const GetOfferIcon = props => (
  <Icon viewBox='0 0 211 157' width='211' height='157' fill='none' {...props}>
    <rect y='13' width='75' height='124' rx='7' fill='#E4E7EE' />
    <mask
      id='mask0'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='5'
      y='24'
      width='66'
      height='66'
    >
      <path
        d='M53.5255 74.5291C63.1763 65.9847 64.0733 51.2347 55.529 41.5838C46.9847 31.933 32.2345 31.036 22.5837 39.5804C12.9329 48.1247 12.0359 62.8748 20.5803 72.5256C29.1246 82.1764 43.8747 83.0734 53.5255 74.5291Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask0)'>
      <path
        d='M53.5255 74.5291C63.1763 65.9847 64.0733 51.2347 55.529 41.5838C46.9847 31.933 32.2345 31.036 22.5837 39.5804C12.9329 48.1247 12.0359 62.8748 20.5803 72.5256C29.1246 82.1764 43.8747 83.0734 53.5255 74.5291Z'
        fill='#FCFCFF'
      />
      <path
        d='M31.5642 47.7223C30.868 48.1676 30.3425 48.835 30.0729 49.6163C29.7176 50.2669 29.5816 51.0147 29.6852 51.7488C29.7549 52.0907 29.9423 52.3974 30.2148 52.6154C30.4873 52.8333 30.8276 52.9489 31.1765 52.9418C31.0684 53.1734 31.0319 53.432 31.0718 53.6844C31.1116 53.9368 31.226 54.1716 31.4002 54.3586C31.7507 54.7263 32.1786 55.0115 32.6529 55.1937C33.7738 55.7042 34.9241 56.1474 36.0978 56.521C37.0282 56.8519 38.004 57.038 38.9909 57.0728C40.1017 57.0863 41.1914 56.7687 42.121 56.1605C43.0506 55.5523 43.778 54.681 44.2104 53.6577C44.4043 53.1805 44.2104 52.4646 44.4192 51.9874C44.7749 51.8594 45.0736 51.6092 45.2621 51.2815C45.4505 50.9537 45.5165 50.5697 45.4482 50.1979C45.3279 49.7196 45.0745 49.2853 44.7175 48.9452C43.979 48.0266 43.1038 47.2269 42.1226 46.574C41.6301 46.2573 41.0716 46.0578 40.4899 45.9905C39.9083 45.9233 39.3189 45.9903 38.7672 46.1863'
        fill='#A6B0C6'
      />
      <path
        d='M22.4974 68.6002C24.0544 66.9267 25.9306 65.5817 28.0153 64.6443C30.1001 63.7069 32.3514 63.1961 34.6365 63.142L41.7948 63.0227C41.7948 63.0227 48.1775 63.8578 52.0251 67.0045C63.5081 76.4146 64.9397 91.3574 64.9397 91.3574C64.9397 91.3574 15.011 82.857 14.9365 82.5289C14.8619 82.2008 19.3209 72.3135 22.4974 68.6002Z'
        fill='#B8C1D4'
      />
      <path
        d='M42.63 49.4072C44.1213 55.2531 43.8976 59.384 39.1851 59.9657C35.9341 60.3683 33.6226 59.8613 32.6831 56.9831C32.2506 55.7154 32.1462 49.8994 33.4883 48.4826C35.7253 46.1264 41.3922 44.4263 42.63 49.4072Z'
        fill='#EFF4FA'
      />
      <path
        d='M34.5171 56.9683L34.6811 62.6203C34.6811 62.6203 33.9206 65.066 38.3497 65.066C42.7789 65.066 41.6902 62.6203 41.6902 62.6203L41.4964 56.0735'
        fill='#EFF4FA'
      />
      <path
        d='M37.0077 43.621C36.1313 43.2421 35.1691 43.1058 34.2219 43.2265C33.2748 43.3472 32.3775 43.7204 31.6241 44.307C30.8982 44.9274 30.4335 45.7991 30.3229 46.7476C30.2123 47.6961 30.464 48.6513 31.0276 49.4222C31.1586 49.5995 31.3236 49.7489 31.5131 49.8616C31.7026 49.9743 31.9128 50.048 32.1311 50.0783C32.4298 50.0746 32.7244 50.0085 32.9961 49.8845C33.9574 49.4748 34.8482 48.9161 35.6357 48.2291C35.7302 48.5622 35.9473 48.8473 36.2432 49.0271C36.5392 49.2069 36.8922 49.2681 37.2314 49.1985C37.913 49.0338 38.54 48.6946 39.0508 48.2142L40.0947 49.2134C40.567 49.7884 41.2372 50.166 41.9737 50.2722C42.3265 50.2438 42.6671 50.1298 42.9659 49.9401C43.2648 49.7504 43.5129 49.4907 43.6887 49.1835C44.1761 48.2566 44.3042 47.1823 44.0482 46.1667C43.7923 45.1512 43.1704 44.2659 42.3018 43.6806C41.395 43.1571 40.3663 42.8815 39.3192 42.8815C38.2721 42.8815 37.2434 43.1571 36.3366 43.6806C36.2173 43.6806 36.2024 44.0087 36.3366 43.9193'
        fill='#A6B0C6'
      />
    </g>
    <rect x='24' y='21' width='27' height='2' rx='1' fill='white' />
    <g opacity='0.6'>
      <rect x='11' y='93' width='53' height='4' rx='2' fill='white' />
      <rect x='11' y='104' width='45' height='4' rx='2' fill='white' />
      <rect x='11' y='114' width='53' height='4' rx='2' fill='white' />
    </g>
    <rect x='136' y='13' width='75' height='124' rx='7' fill='#E4E7EE' />
    <mask
      id='mask1'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='141'
      y='24'
      width='66'
      height='66'
    >
      <path
        d='M189.525 74.5291C199.176 65.9847 200.073 51.2347 191.529 41.5838C182.985 31.933 168.235 31.036 158.584 39.5804C148.933 48.1247 148.036 62.8748 156.58 72.5256C165.125 82.1764 179.875 83.0734 189.525 74.5291Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask1)'>
      <path
        d='M189.525 74.5291C199.176 65.9847 200.073 51.2347 191.529 41.5838C182.985 31.933 168.235 31.036 158.584 39.5804C148.933 48.1247 148.036 62.8748 156.58 72.5256C165.125 82.1764 179.875 83.0734 189.525 74.5291Z'
        fill='#FCFCFF'
      />
      <path
        d='M185.767 58.9235C185.07 58.0642 184.31 57.2573 183.494 56.5094C182.919 56.0502 182.455 55.4671 182.136 54.8037C181.818 54.1402 181.653 53.4135 181.655 52.6775C181.574 50.835 181.177 49.0202 180.479 47.3129C180.187 46.6692 179.74 46.1076 179.178 45.6776C178.617 45.2477 177.958 44.9626 177.261 44.8477C176.49 43.9965 175.477 43.4019 174.358 43.1439C173.239 42.8858 172.068 42.9767 171.002 43.4044C169.825 43.9148 168.787 44.6975 167.972 45.6879C167.157 46.6783 166.589 47.8482 166.314 49.1011C165.966 50.3307 165.718 51.5866 165.573 52.8564C165.31 54.4248 164.801 55.942 164.066 57.3524C163.261 59.1534 162.712 61.3631 163.811 62.9853C164.686 63.942 165.687 64.7756 166.787 65.4632C168.907 67.3153 169.75 70.6618 172.407 71.5687C173.463 71.8499 174.57 71.8761 175.638 71.6454C178.088 71.2744 180.485 70.6141 182.779 69.6783C184.628 69.0974 186.212 67.8801 187.249 66.2424C187.623 65.383 187.796 64.4493 187.754 63.5128C187.712 62.5763 187.456 61.6618 187.006 60.8394C186.664 60.1579 186.248 59.5155 185.767 58.9235Z'
        fill='#A6B0C6'
      />
      <path
        d='M160.477 66.2551C161.812 64.8253 163.42 63.6764 165.205 62.8758C166.991 62.0753 168.918 61.6391 170.874 61.593L177.005 61.4908C177.005 61.4908 182.472 62.2061 185.767 64.9012C195.602 72.9737 196.828 85.7466 196.828 85.7466C196.828 85.7466 154.065 78.4661 154.001 78.1851C153.937 77.9041 157.667 69.4484 160.477 66.2551Z'
        fill='#B8C1D4'
      />
      <path
        d='M177.72 49.8291C178.997 54.8361 176.698 58.5785 174.77 58.8723C171.998 59.2938 170.452 57.9271 169.66 55.462C169.303 54.3635 168.766 50.2378 169.916 49.0755C171.806 47.019 176.66 45.5629 177.72 49.8291Z'
        fill='white'
      />
      <path
        d='M169.814 46.687C169.304 47.0715 168.871 47.548 168.536 48.0921C168.14 48.7818 167.974 49.8036 168.613 50.2762C168.781 50.3857 168.97 50.4602 169.167 50.4953C169.365 50.5304 169.567 50.5254 169.763 50.4806C171.04 50.2251 172.062 49.0628 173.377 48.884C173.709 48.884 174.042 48.884 174.361 48.7946C174.796 48.7115 175.195 48.4924 175.498 48.1687C176.047 47.5939 176.915 49.9314 177.209 50.2507C177.627 50.7017 178.205 50.9721 178.819 51.0043C178.9 51.019 178.984 51.0126 179.062 50.9857C179.14 50.9589 179.21 50.9125 179.266 50.851C179.326 50.7643 179.361 50.6627 179.368 50.5572C179.416 49.664 179.215 48.7751 178.786 47.99C178.357 47.2048 177.718 46.5546 176.941 46.1123C175.378 45.2594 173.54 45.0619 171.832 45.563C170.874 45.8313 169.826 46.4316 169.724 47.4279'
        fill='#A4B3C0'
      />
      <path
        d='M171.193 56.5349L170.899 61.1459C170.899 61.1459 170.261 63.2407 174.054 63.2407C177.848 63.2407 176.928 61.1459 176.928 61.1459L176.302 56.0367'
        fill='white'
      />
    </g>
    <rect x='160' y='21' width='27' height='2' rx='1' fill='white' />
    <g opacity='0.6'>
      <rect x='147' y='93' width='53' height='4' rx='2' fill='white' />
      <rect x='147' y='104' width='45' height='4' rx='2' fill='white' />
      <rect x='147' y='114' width='53' height='4' rx='2' fill='white' />
    </g>
    <rect x='57' width='91' height='150' rx='7' fill='url(#paint0_linear)' />
    <mask
      id='mask2'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='75'
      y='25'
      width='55'
      height='55'
    >
      <path
        d='M102.541 80C117.706 80 130 67.7064 130 52.5416C130 37.3768 117.706 25.0833 102.541 25.0833C87.3765 25.0833 75.083 37.3768 75.083 52.5416C75.083 67.7064 87.3765 80 102.541 80Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask2)'>
      <path
        d='M102.541 80C117.706 80 130 67.7064 130 52.5416C130 37.3768 117.706 25.0833 102.541 25.0833C87.3765 25.0833 75.083 37.3768 75.083 52.5416C75.083 67.7064 87.3765 80 102.541 80Z'
        fill='#EAEBFB'
      />
      <path
        d='M118.718 55.296C117.76 54.1156 116.716 53.0073 115.595 51.98C114.805 51.3492 114.168 50.5483 113.731 49.6369C113.293 48.7256 113.067 47.7273 113.069 46.7164C112.959 44.1855 112.413 41.6926 111.455 39.3474C111.053 38.4632 110.439 37.6917 109.668 37.1011C108.896 36.5105 107.992 36.119 107.033 35.9611C105.974 34.7919 104.583 33.9751 103.046 33.6207C101.509 33.2662 99.9 33.3911 98.4361 33.9785C96.8198 34.6797 95.3932 35.7548 94.2737 37.1153C93.1542 38.4757 92.3739 40.0826 91.997 41.8037C91.5182 43.4927 91.1779 45.2178 90.9793 46.962C90.6171 49.1164 89.9185 51.2005 88.909 53.138C87.8036 55.6118 87.0492 58.6472 88.5581 60.8754C89.7607 62.1896 91.1359 63.3346 92.6461 64.2792C95.5587 66.8233 96.7167 71.4201 100.366 72.6658C101.816 73.052 103.338 73.0882 104.805 72.7711C108.17 72.2615 111.462 71.3545 114.613 70.0691C117.153 69.2712 119.329 67.5991 120.754 65.3495C121.268 64.169 121.505 62.8864 121.448 61.6C121.39 60.3136 121.038 59.0575 120.42 57.9278C119.95 56.9916 119.379 56.1092 118.718 55.296Z'
        fill='#202263'
      />
      <path
        d='M83.9786 65.367C85.8132 63.4029 88.0215 61.8248 90.4739 60.7251C92.9263 59.6254 95.5735 59.0263 98.2604 58.963L106.682 58.8226C106.682 58.8226 114.192 59.8052 118.718 63.5072C132.228 74.5958 133.912 92.1411 133.912 92.1411C133.912 92.1411 75.1709 82.1403 75.0831 81.7543C74.9954 81.3683 80.1186 69.7533 83.9786 65.367Z'
        fill='#FB3F68'
      />
      <path
        d='M107.665 42.8037C109.419 49.6815 106.261 54.8223 103.612 55.2258C99.8045 55.8048 97.6816 53.9274 96.5938 50.5412C96.1025 49.0323 95.3656 43.3652 96.9447 41.7686C99.5414 38.9438 106.209 36.9436 107.665 42.8037Z'
        fill='#FEB2A3'
      />
      <path
        d='M96.8042 38.4878C96.1039 39.0158 95.5088 39.6705 95.0497 40.4178C94.5057 41.3652 94.2777 42.7688 95.1549 43.418C95.3858 43.5684 95.6446 43.6707 95.9159 43.719C96.1872 43.7672 96.4654 43.7603 96.734 43.6987C98.4885 43.3478 99.8921 41.7512 101.699 41.5056C102.155 41.5056 102.612 41.5056 103.05 41.3828C103.649 41.2687 104.195 40.9677 104.612 40.523C105.366 39.7335 106.559 42.9443 106.963 43.3829C107.537 44.0025 108.33 44.3738 109.174 44.4181C109.285 44.4382 109.401 44.4295 109.508 44.3926C109.615 44.3558 109.712 44.2921 109.788 44.2076C109.871 44.0885 109.919 43.9489 109.928 43.804C109.994 42.5771 109.718 41.356 109.129 40.2775C108.54 39.1991 107.662 38.3059 106.594 37.6983C104.447 36.5267 101.923 36.2554 99.5763 36.9438C98.2604 37.3123 96.8217 38.1369 96.6814 39.5054'
        fill='#202263'
      />
      <path
        d='M98.6989 52.015L98.2954 58.3488C98.2954 58.3488 97.4181 61.2262 102.629 61.2262C107.84 61.2262 106.577 58.3488 106.577 58.3488L105.717 51.3307'
        fill='#FEB2A3'
      />
    </g>
    <mask
      id='mask3'
      mask-type='alpha'
      maskUnits='userSpaceOnUse'
      x='68'
      y='17'
      width='70'
      height='70'
    >
      <path
        d='M129.04 60.5071C133.488 45.8754 125.233 30.408 110.601 25.9598C95.9698 21.5116 80.5025 29.7669 76.0542 44.3986C71.606 59.0302 79.8613 74.4976 94.493 78.9458C109.125 83.394 124.592 75.1387 129.04 60.5071Z'
        fill='white'
      />
    </mask>
    <g mask='url(#mask3)'>
      <path
        d='M129.04 60.5071C133.488 45.8754 125.233 30.408 110.601 25.9598C95.9698 21.5116 80.5025 29.7669 76.0542 44.3986C71.606 59.0302 79.8613 74.4976 94.493 78.9458C109.125 83.394 124.592 75.1387 129.04 60.5071Z'
        fill='#F2F8FF'
      />
      <path
        d='M84.4683 64.4511C86.3184 62.4704 88.5453 60.8789 91.0184 59.77C93.4915 58.661 96.1611 58.0569 98.8707 57.993L107.364 57.8514C107.364 57.8514 114.954 58.8423 119.501 62.5756C133.125 73.7402 134.842 91.469 134.842 91.469C134.842 91.469 75.5862 81.4014 75.4977 80.9945C75.4092 80.5875 80.6111 68.8745 84.4683 64.4511Z'
        fill='#1E294F'
      />
      <path
        d='M97.4726 39.3786C96.4345 40.5541 95.6674 41.9434 95.2255 43.4481C94.9271 44.6935 94.9457 45.9938 95.2795 47.2302C95.6133 48.4666 96.2517 49.5995 97.1364 50.5255C97.366 50.772 97.6279 50.9862 97.9149 51.1625C98.3492 51.4025 98.8223 51.5642 99.3127 51.6402C100.537 51.8201 101.776 51.8675 103.011 51.7817C107.098 51.5871 110.3 49.0746 110.424 44.8105C110.574 43.4888 110.275 42.1552 109.575 41.0241C108.726 39.9094 107.222 39.4317 106.408 38.2993'
        fill='#53667D'
      />
      <path
        d='M108.354 41.6972C110.124 48.633 106.939 53.8172 104.267 54.2241C100.428 54.808 98.2867 52.9148 97.1897 49.5C96.6943 47.996 95.9512 42.2633 97.5436 40.6532C100.162 37.8046 106.886 35.7875 108.354 41.6972Z'
        fill='#FEB2A3'
      />
      <path
        d='M99.2422 50.9861L98.8529 57.3734C98.8529 57.3734 97.9505 60.2751 103.206 60.2751C108.46 60.2751 107.187 57.3734 107.187 57.3734L106.32 50.296'
        fill='#FEB2A3'
      />
      <path
        d='M111.734 39.5737C111.602 39.1509 111.326 38.7874 110.955 38.5453C110.584 38.3032 110.14 38.1977 109.7 38.2467C109.399 38.2467 109.045 38.459 108.833 38.2467C108.74 38.0904 108.686 37.9147 108.673 37.7336C108.623 36.8286 108.33 35.9538 107.826 35.2006C107.322 34.4473 106.624 33.8434 105.807 33.4518C103.914 32.7087 102.003 34.7434 99.9328 34.0534C98.6588 33.6287 97.1195 33.2572 96.111 34.2303C95.4917 34.8319 95.2263 35.8581 94.4832 36.2474C94.1529 36.3918 93.7993 36.4757 93.4393 36.4951C92.5741 36.5748 91.7592 36.9367 91.1198 37.5249C90.4804 38.1131 90.052 38.8952 89.9006 39.7507C89.878 40.3238 89.9784 40.8951 90.1951 41.4261C90.4118 41.9571 90.7397 42.4356 91.1568 42.8293C92.3571 43.9537 93.8915 44.6557 95.5271 44.8287C96.8863 45.136 98.3112 44.9279 99.5258 44.2448C100.156 43.7999 100.748 43.3027 101.295 42.7585C101.891 42.1034 102.7 41.6813 103.579 41.5679C104.457 41.4545 105.347 41.657 106.09 42.1393C106.603 42.617 106.939 43.307 107.488 43.7494C107.845 43.9522 108.243 44.0752 108.652 44.1101C109.062 44.1449 109.474 44.0907 109.86 43.9512C110.247 43.8117 110.599 43.5901 110.892 43.3018C111.185 43.0135 111.412 42.6652 111.557 42.2808C111.763 41.8634 111.885 41.4094 111.916 40.9449C111.946 40.4804 111.884 40.0144 111.734 39.5737Z'
        fill='#53667D'
      />
    </g>
    <rect x='88' y='9' width='29' height='3' rx='1.5' fill='white' />
    <g opacity='0.4'>
      <rect x='70' y='91' width='60' height='6' rx='3' fill='#EAEBFB' />
      <rect x='70' y='104' width='42' height='6' rx='3' fill='#EAEBFB' />
      <rect x='70' y='117' width='48' height='6' rx='3' fill='#EAEBFB' />
    </g>
    <circle
      cx='135.5'
      cy='139.5'
      r='15.25'
      fill='white'
      stroke='#6DD699'
      stroke-width='2.5'
    />
    <path
      d='M128.256 140.098L133.085 144.927L142.744 135.268'
      stroke='#6DD699'
      stroke-width='3'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear'
        x1='3'
        y1='150'
        x2='141.074'
        y2='170.402'
        gradientUnits='userSpaceOnUse'
      >
        <stop stop-color='#429BC1' />
        <stop offset='1' stop-color='#45C9D6' />
      </linearGradient>
    </defs>
  </Icon>
);
export default GetOfferIcon;
