import { useCallback, useState } from 'react';
import { throwErrorSentry } from 'src/utils/throwErrorSentry';
import useToast from 'src/hooks/useToast';
import { BELARUS } from 'src/common/constants';
import throttle from 'lodash.throttle';
import { useTranslation } from 'next-i18next';
import { deployment } from 'src/config';
import { getBelarusGeoCode } from 'src/modules/CitySearchBelarus';
import { getGoogleGeoCode } from 'src/modules/CitySearchGoogle';

export const useGetGeoData = () => {
  const { t } = useTranslation(['common']);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState();
  const { showErrorToast } = useToast();

  const getGeoCode =
    deployment === BELARUS ? getBelarusGeoCode : getGoogleGeoCode;

  const getGeoData = async address => {
    let addresses = [];

    try {
      addresses = await getGeoCode(address);
    } catch (error) {
      throwErrorSentry(error);
      showErrorToast(t('errors-messages.default-error'));
    }

    setResults(addresses);
    setLoading(false);
  };

  const throttledOnChange = useCallback(throttle(getGeoData, 1500), []);

  const onChange = val => {
    setLoading(true);
    throttledOnChange(val);
  };

  return {
    onChange,
    isLoading,
    results,
  };
};
