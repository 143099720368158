import React from 'react';
import DefaultContainer from '../DefaultContainer';
import BackButton from '../../components/BackButton';
import { MIN_HEIGHT } from '../../common/constants';

const DefaultLayout = ({ children, ...props }) => (
  <>
    <DefaultContainer minH={MIN_HEIGHT} {...props}>
      {children}
    </DefaultContainer>
    <BackButton />
  </>
);

export default DefaultLayout;
