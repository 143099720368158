import React from 'react';
import { Link } from '@chakra-ui/react';
import { useRouter } from 'next/router';

const NextLinkContainer = ({
  children,
  target,
  href,
  dataTestId,
  ...props
}) => {
  const router = useRouter();

  const onClick = async event => {
    if (target !== '_blank') {
      event.preventDefault();

      await router.push(href);
    }
  };
  return (
    <Link
      data-testid={dataTestId}
      onClick={onClick}
      {...props}
      href={href}
      isExternal={target === '_blank'}
    >
      {children}
    </Link>
  );
};

export default NextLinkContainer;
