/* eslint-disable no-case-declarations */
import { userTypes } from 'src/common/constants';
import { types } from './constants';

const {
  NEW_MESSAGE,
  NEW_EXECUTOR,
  ORDER_COMPLETE_BY_EXECUTOR,
  FEEDBACK_FROM_EXECUTOR,
  ORDER_APPROVED_BY_ORDERER,
  TENDER_APPLIED,
  NEW_OFFER,
  FEEDBACK_FROM_ORDERER,
  ORDER_IS_PUBLISHED,
} = types;

export const getNewMessage = ntf => {
  if (!ntf) return null;
  return ntf.find(e => e.type === NEW_MESSAGE);
};

export const getNewExecutor = ntf => {
  if (!ntf) return null;
  return ntf.find(e => e.type === NEW_EXECUTOR);
};

export const getCompleteByExecutor = ntf => {
  if (!ntf) return null;
  return ntf.find(e => e.type === ORDER_COMPLETE_BY_EXECUTOR);
};

/**
 * @param {Array} ntf Events of entity
 * @param {object} filter Events can be filtered by payload
 *
 * Extract all events for particular entity and group them by type
 */
export const extractAll = (ntf, { path, value } = {}) => {
  if (!ntf) return null;
  return ntf.reduce((acc, e) => {
    switch (e.type) {
      case NEW_OFFER:
        if (path) {
          if (e?.payload?.[path] === value) acc.newOffer = e;
        } else {
          acc.newOffer = e;
        }
        break;
      case ORDER_IS_PUBLISHED:
        if (path) {
          if (e?.payload?.[path] === value) acc.newOffer = e;
        } else {
          acc.isPublished = e;
        }
        break;
      case NEW_MESSAGE:
        if (path) {
          if (e?.payload?.[path] === value) acc.newMessage = e;
        } else {
          acc.newMessage = e;
        }
        break;
      case NEW_EXECUTOR:
        if (path) {
          if (e?.payload?.[path] === value) acc.newExecutor = e;
        } else {
          acc.newExecutor = e;
        }
        break;
      case TENDER_APPLIED:
        if (path) {
          if (e?.payload?.[path] === value) acc.tenderApplied = e;
        } else {
          acc.tenderApplied = e;
        }
        break;
      case ORDER_COMPLETE_BY_EXECUTOR:
        if (path) {
          if (e?.payload?.[path] === value) acc.done = e;
        } else {
          acc.done = e;
        }
        break;
      case ORDER_APPROVED_BY_ORDERER:
        if (path) {
          if (e?.payload?.[path] === value) acc.done = e;
        } else {
          acc.done = e;
        }
        break;
      case FEEDBACK_FROM_EXECUTOR:
        if (path) {
          if (e?.payload?.[path] === value) acc.newFeedback = e;
        } else {
          acc.newFeedback = e;
        }
        break;
      case FEEDBACK_FROM_ORDERER:
        if (path) {
          if (e?.payload?.[path] === value) acc.newFeedback = e;
        } else {
          acc.newFeedback = e;
        }
        break;
      default:
        break;
    }
    return acc;
  }, {});
};

const mapNotification = ({ id, type, status, isPopUp, payload }) => ({
  id,
  type,
  status,
  isPopUp,
  payload,
});

export const mapNotifications = param => {
  const ntf = param || [];
  return ntf.reduce(
    (acc, e) => {
      if (e.userType === userTypes.ORDERER) {
        if (acc.orderer[e.entityId]) {
          acc.orderer[e.entityId].push(mapNotification(e));
          return acc;
        }
        acc.orderer[e.entityId] = [mapNotification(e)];
        return acc;
      }

      if (e.userType === userTypes.EXECUTOR) {
        if (acc.executor[e.entityId]) {
          acc.executor[e.entityId].push(mapNotification(e));
          return acc;
        }
        acc.executor[e.entityId] = [mapNotification(e)];
        return acc;
      }
      return acc;
    },
    { orderer: {}, executor: {} },
  );
};
