import { lettersEnRuRegExp, emptySpaceRegExp } from './regExp';

const decl = (number, txt, cases = [1, 0, 2, 2, 2, 1]) =>
  txt[
    number % 100 > 4 && number % 100 < 20
      ? 1
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];

const declLngVariant = (count, cases = [2, 1, 3, 3, 3, 2]) => {
  if (!count || count === 0) return 0;
  return count % 100 > 4 && count % 100 < 20
    ? 2
    : cases[count % 10 < 5 ? count % 10 : 5];
};

const truncateString = (str, lettersCount) => {
  if (str?.length > lettersCount) {
    return `${str?.slice(0, lettersCount)}...`;
  }
  return str;
};

/**
 * Checks if there are Russian or Latin letters in the string
 * @param {string} str - string to check
 */
const isHaveLetters = str => lettersEnRuRegExp.test(str);

/**
 * Converts a string AdditionalServices to an array in the required format
 * @param {string} str - string to convert
 * @return {Array} = formatted фdditionalServices array
 */
const convertAdditionalServices = str =>
  str
    ?.split(',')
    ?.filter(el => isHaveLetters(el))
    ?.map(word => {
      const trimmed = word.replace(emptySpaceRegExp, ' ').trim();
      return trimmed[0].toUpperCase() + trimmed.substring(1);
    });

/**
 * Checks whether fields exist in the object
 * @param {object} queryParams - object to check for fields
 * @param {array} [exceptionIds=[]] - array of exception IDs
 * @returns {boolean} - boolean value indicating if fields exist
 */
const isExistQueryParams = (queryParams, exceptionIds = []) => {
  const exceptions = [...exceptionIds, 'ssgParams'];
  const filteredKeys = Object.keys(queryParams).filter(
    key => !exceptions.includes(key),
  );
  return filteredKeys.length !== 0;
};

/**
 * Getting the object of all children and nested children from item
 * @param {object} item - the item from which you need to get the object of all children
 * @param {object} prevRes - previous value
 * @return {Array} array of objects of all children
 */
function flatItems(item) {
  return [item, ...item?.items.map(flatItems).flat()];
}

/**
 * React-admin изменяет pathObject и при удалении оставляет не корректный объект в pathObject
 * Возвращает последний объект в pathObject с существующим id
 * @param {Array} pathObject - array of objects of the tree of the element's parents
 * @return {string} the last existing element id
 */
const getLastItemIdInPathObject = pathObject => {
  const newArray = pathObject.filter(el => el.id);
  return newArray[newArray.length - 1].id;
};

/**
 * Возвращает массив индексов для открытия аккордеонов
 * @param {Array} jobItems - массив jobItems
 * @param {string} latestIndex - индекс последнего найденого элемента
 * @param {Array} selectedIds - массив id, для которых нужно определить индексы
 * @return {Array} массив индексов для открытия аккордеонов
 */
const findIndexes = (jobItems, latestIndex, selectedIds) => {
  const currentIndexes = {};
  let currentIndex = latestIndex;
  jobItems?.forEach(item => {
    if (item.items?.length) currentIndex += 1;
    if (selectedIds.includes(item.id)) {
      const i = latestIndex === -1 ? 0 : latestIndex;
      currentIndexes[i] = i;
    }
    if (item.items?.length) {
      const result = findIndexes(item.items, currentIndex, selectedIds);
      currentIndex = result.currentIndex;
      if (result.currentIndexes.length && latestIndex !== -1)
        currentIndexes[latestIndex] = latestIndex;
      result.currentIndexes.forEach(el => {
        currentIndexes[el] = el;
      });
    }
  });
  return { currentIndex, currentIndexes: Object.keys(currentIndexes) };
};

const generateRandomString = (length = 10) => {
  let randomString = '';
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const randomCharacter = characters.charAt(randomIndex);
    randomString += randomCharacter;
  }

  return randomString;
};

export {
  decl,
  declLngVariant,
  truncateString,
  isHaveLetters,
  convertAdditionalServices,
  isExistQueryParams,
  flatItems,
  getLastItemIdInPathObject,
  findIndexes,
  generateRandomString,
};
