import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Modal from 'src/components/Modal';
import { useTranslation } from 'next-i18next';
import SelectCityModalContent from './SelectCityModalContent.jsx';
import { types } from '../model/types';

const SelectCity = ({
  type,
  saveValue,
  defaultValue,
  placeholder,
  isError,
  customStyle,
  testid,
  clear,
}) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { t } = useTranslation(['common']);

  const getTitle = () => {
    if (defaultValue?.isRemote) return t('remote');
    if (defaultValue?.name)
      return `${defaultValue?.name} ${defaultValue?.street || ''}`;
    return placeholder;
  };
  const closeModal = () => setModalStatus(!modalStatus);

  return (
    <>
      <Button
        w='100%'
        variant={isError ? 'error' : 'outline'}
        data-testid={testid}
        rightIcon={
          <ChevronDownIcon
            transform={modalStatus ? 'rotate(180deg)' : 'rotate(0deg)'}
          />
        }
        onClick={() => {
          setModalStatus(!modalStatus);
        }}
        fontSize='md'
        whiteSpace='nowrap'
        overflow='hidden'
        textOverflow='ellipsis'
        {...customStyle}
      >
        {getTitle()}
      </Button>

      <Modal isOpen={modalStatus} onClose={closeModal}>
        <SelectCityModalContent
          type={type}
          types={types}
          clear={clear}
          saveValue={saveValue}
          defaultValue={defaultValue}
          onClose={closeModal}
        />
      </Modal>
    </>
  );
};

export default SelectCity;
