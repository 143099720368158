import {
  primaryGradient,
  primaryGradientTransparent,
  primaryGradientLight,
  grayGradient500,
} from './constants';

const colors = {
  brand: {
    primary: '#429bc2',
    primaryGradient,
    secondaryBackground: '#EDF2F7',
    primaryGradientLight,
    viber: '#7360f2',
    telegram: '#179cde',
    facebook: '#4267b2',
    messenger: 'linear-gradient(10deg, #0695FF 0%,  #A334FA 50%, #FF6968 100%)',
  },
  typography: {
    secondary: '#A0AEC0',
    primary: '#171923',
    error: '#F56565',
    success: '#50B75F',
  },
  black: {
    transparentBackground: '#00000080',
    transparentBackgroundLite: '#00000070',
    400: '#3E4346',
    500: '#292C2E',
  },
  blackTransparent: {
    500: '#0b0a0ac2',
    400: '#0b0a0a99',
    100: '#0000000a',
  },
  primaryGradientTransparent,
  grayTransparent: {
    100: '#EDF2F742',
    110: '#EDF2F792',
  },
  lightGray: {
    500: '#EDF2F7',
    100: '#F4F4F4',
    50: '#F7FAFC',
  },
  white: '#ffffff',
  cyan: {
    500: '#44ABC9',
    400: '#8FD7E3',
    300: '#45C9D6',
  },
  tomato: {
    400: '#FEB2B2',
    300: '#FED7D7',
  },
  grayGradient: {
    500: grayGradient500,
  },
  error: {
    transparentBackground: '#E53E3E77',
    500: '#E53E3E',
  },
  translucentGray: 'rgba(41, 44, 46, 0.5)',
};

export default colors;
