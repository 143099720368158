module.exports = {
  api: process.env.api,
  isProd: process.env.isProd,
  gtmId: process.env.gtmId,
  telegramBotName: process.env.telegramBotName,
  viberBotName: process.env.viberBotName,
  fbMessengerBotName: process.env.fbMessengerBotName,
  sentryDsn: process.env.sentryDsn,
  baseUrl: process.env.baseUrl,
  deployment: process.env.deployment,
  googleApiKey: process.env.googleApiKey,
  defaultLocale: process.env.defaultLocale,
  maxParallelUploads: 1,
};
