export const primaryGradient =
  'linear-gradient(69.3deg, #429BC1 16.57%, #45C9D6 108.7%)';
export const primaryGradientTransparent =
  'linear-gradient(69.3deg, #429bc1a8 16.57%, #45c9d699 108.7%)';
export const grayGradient500 =
  'linear-gradient(0deg, #000000e6 16.57%, #ffffff00 92.7%)';
export const primaryGradientLight =
  'linear-gradient(69.3deg, rgb(74 182 228) 16.57%, rgb(84 212 224) 108.7%)';
export const grayGradientToTop = 'linear-gradient(180deg,#edf2f7bf,#edf2f700)';
export const grayGradientToBottom = 'linear-gradient(#edf2f700,#edf2f7bf)';
export const grayGradientToRight =
  'linear-gradient(90deg, #edf2f700,#edf2f7bf)';
export const whiteGradientToBottom = 'linear-gradient(#ffffff00,#ffffffff)';
export const blackGradientToBottom = 'linear-gradient(#b9b9b900,#00000063)';

export const constants = {
  primaryGradient,
  grayGradient500,
  primaryGradientLight,
  error200: 'red.200',
  error100: 'red.100',
};

export const shaddows = {
  allSidesGrayXS: '0px 4px 13px -3px grey',
  allSidesGraySM: '0px 0px 16px -8px gray',
  allSidesGrayMD: '0px 4px 23px -11px grey',
};

export const size = {
  footer: {
    mobile: 119,
    desktop: 50,
  },
  header: 16,
  container: {
    mobile: {
      x: 2.5,
      y: 5,
      y2: 10,
    },
    desktop: {
      x: 5,
      y: 10,
    },
  },
};
