import React from 'react';
import { Box } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';

const ErrorMessageContainer = ({
  errorDataTestId,
  errors,
  name,
  ...styles
}) => (
  <ErrorMessage
    errors={errors}
    name={name}
    render={({ message }) => (
      <Box pos='relative'>
        <Box
          fontSize='xs'
          position='absolute'
          data-testid={errorDataTestId}
          mt={1}
          {...styles}
        >
          {message}
        </Box>
      </Box>
    )}
  />
);

export default ErrorMessageContainer;
