import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { baseUrl } from '../../config';

// TODO Location
const MetaDecorator = ({
  title,
  description,
  keywords,
  url = 'https://masteryoda.by',
  canonical,
  noIndex = null,
  image = 'https://masteryoda.by/images/cover-m.jpg',
  twitterImage = 'https://masteryoda.by/images/cover-m.jpg',
  ns,
}) => {
  const { i18n } = useTranslation();
  const language = i18n?.language;
  const noIndexAttr = noIndex ? { content: 'none' } : { content: 'all' };

  const PreloadLocales = () => {
    if (ns?.length && language) {
      return (
        <>
          {ns.map((el, i) => (
            <link
              key={`${el}-${i}-${language}`}
              rel='preload'
              href={`${baseUrl}/locales/${language}/${el}.json`}
              as='fetch'
            />
          ))}
        </>
      );
    }
    return null;
  };
  return (
    <Head>
      <title>{title}</title>
      {canonical && <link rel='canonical' href={canonical} />}
      <PreloadLocales />
      <link rel='manifest' href='/manifest.json' key='manifest' />
      <link
        rel='icon'
        type='image/png'
        href='/icons/logo/favicon.ico'
        key='favicon'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/icons/logo/favicon-16x16.png'
        key='favicon-16x16'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/icons/logo/favicon-32x32.png'
        key='favicon-32x32'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='96x96'
        href='/icons/logo/favicon-96x96.png'
        key='favicon-96x96'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='192x192'
        href='/icons/logo/android-icon-192x192.png'
        key='android-icon-192x192'
      />

      <link
        rel='apple-touch-icon'
        sizes='57x57'
        href='/icons/logo/apple-icon-57x57.png'
        key='apple-icon-57x57'
      />
      <link
        rel='apple-touch-icon'
        sizes='60x60'
        href='/icons/logo/apple-icon-60x60.png'
        key='apple-icon-60x60'
      />
      <link
        rel='apple-touch-icon'
        sizes='72x72'
        href='/icons/logo/apple-icon-72x72.png'
        key='apple-icon-72x72'
      />
      <link
        rel='apple-touch-icon'
        sizes='76x76'
        href='/icons/logo/apple-icon-76x76.png'
        key='apple-icon-76x76'
      />
      <link
        rel='apple-touch-icon'
        sizes='114x114'
        href='/icons/logo/apple-icon-114x114.png'
        key='apple-icon-114x114'
      />
      <link
        rel='apple-touch-icon'
        sizes='120x120'
        href='/icons/logo/apple-icon-120x120.png'
        key='apple-icon-120x120'
      />
      <link
        rel='apple-touch-icon'
        sizes='144x144'
        href='/icons/logo/apple-icon-144x144.png'
        key='apple-icon-144x144'
      />
      <link
        rel='apple-touch-icon'
        sizes='152x152'
        href='/icons/logo/apple-icon-152x152.png'
        key='apple-icon-152x152'
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/icons/logo/apple-icon-180x180.png'
        key='apple-icon-180x180'
      />

      <meta
        name='mobile-web-app-capable'
        content='yes'
        key='mobile-web-app-capable'
      />
      <meta
        name='application-name'
        content='MatserYoda'
        key='application-name'
      />
      <meta {...noIndexAttr} name='robots' data-testid='meta-robots-none' />
      <meta
        name='description'
        content={description.replace(/\n/g, ' ')}
        key='description'
      />
      <meta name='keywords' content={keywords} key='keywords' />
      <meta name='new_keywords' content={keywords} key='new_keywords' />
      <meta name='theme-color' content='#429bc2' key='theme-color' />
      <meta
        name='msapplication-TileColor'
        content='#ffffff'
        key='msapplication-TileColor'
      />
      <meta
        name='msapplication-TileImage'
        content='/icons/logo/ms-icon-144x144.png'
        key='msapplication-TileImage'
      />

      <meta property='og:title' content={title} key='og:title' />
      <meta property='og:site_name' content='MatserYoda' key='og:site_name' />
      <meta
        property='og:description'
        content={description.replace(/\n/g, ' ')}
        key='og:description'
      />
      <meta property='og:url' content={url} key='og:url' />
      <meta property='og:type' content='website' key='og:type' />
      <meta property='og:image' content={image} key='og:image' />
      <meta property='og:locale' content='ru_RU' key='og:locale' />
      <meta property='og:image:width' content='968' key='og:image:width' />
      <meta property='og:image:height' content='504' key='og:image:height' />

      <meta name='twitter:title' content={title} key='twitter:title' />
      <meta
        name='twitter:description'
        content={description.replace(/\n/g, ' ')}
        key='twitter:description'
      />
      <meta name='twitter:url' content={url} key='twitter:url' />
      <meta
        name='twitter:image:src'
        content={twitterImage}
        key='twitter:image:src'
      />
      <meta
        name='twitter:card'
        content='summary_large_image'
        key='twitter:card'
      />
      <meta
        name='twitter:domain'
        content='masteryoda.by'
        key='twitter:domain'
      />
    </Head>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
};

export default MetaDecorator;
