import * as rest from '@chakra-ui/theme-tools';

const { mode } = rest;

const parts = ['popper', 'content', 'header', 'body', 'footer', 'arrow'];
const baseStylePopper = {
  w: 'auto',
  maxW: 'auto',
  zIndex: 10,
};

const baseStyleContent = props => ({
  bg: mode('white', 'gray.700')(props),
  border: '1px solid',
  borderColor: 'inherit',
  borderRadius: 'md',
  boxShadow: 'sm',
  w: 'xs',
  zIndex: 'inherit',
  _focus: {
    outline: 0,
    boxShadow: 'outline',
  },
});

const baseStyleArrow = props => ({
  bg: mode('white', 'gray.700')(props),
});

const baseStyleHeader = {
  px: 3,
  py: 2,
  borderBottomWidth: '1px',
};
const baseStyleBody = {
  px: 3,
  py: 2,
};
const baseStyleFooter = {
  px: 3,
  py: 2,
  borderTopWidth: '1px',
};

const baseStyle = props => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: baseStyleArrow(props),
});

export default {
  parts,
  baseStyle,
};
