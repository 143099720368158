import { useContext } from 'react';
import { commonStore } from 'src/store/storeProvider';

const mobileBoxPadding = {
  pt: 10,
  px: 2.5,
  pb: 2.5,
};

const deskBoxPadding = {
  pt: 10,
  px: 5,
  pb: 5,
};

const useModalPaddings = () => {
  const {
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);
  return isMobile ? mobileBoxPadding : deskBoxPadding;
};

export default useModalPaddings;
