export const DELETE_NOT = 'DELETE_NOT';
export const SET_NOT = 'SET_NOT';
export const SET_INIT_NOT = 'SET_INIT_NOT';
export const DELETE_FULL_NOT = 'DELETE_FULL_NOT';
export const DELETE_NOT_ITEM = 'DELETE_NOT_ITEM';

export const statuses = {
  NEW: 'NEW',
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  CLOSED: 'CLOSED',
  APPROVED: 'APPROVED',
  WAITING_FOR_OWNER_COMPLETE: 'WAITING_FOR_OWNER_COMPLETE',
};

export const tenderStatuses = {
  WAITING_FROM_CUSTOMER: 'WAITING_FROM_CUSTOMER',
  WAITING: 'WAITING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const types = {
  NEW_MESSAGE: 'NEW_MESSAGE',
  NEW_EXECUTOR: 'NEW_EXECUTOR',
  ORDER_COMPLETE_BY_EXECUTOR: 'ORDER_COMPLETE_BY_EXECUTOR',
  ORDER_APPROVED_BY_ORDERER: 'ORDER_APPROVED_BY_ORDERER',
  FEEDBACK_FROM_EXECUTOR: 'FEEDBACK_FROM_EXECUTOR',
  FEEDBACK_FROM_ORDERER: 'FEEDBACK_FROM_ORDERER',
  TENDER_APPLIED: 'TENDER_APPLIED',
  NEW_OFFER: 'NEW_OFFER',
  ORDER_IS_PUBLISHED: 'ORDER_IS_PUBLISHED',
};