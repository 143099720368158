export const SET_MOBILE = 'SET_MOBILE';
export const SET_CITY = 'SET_CITY';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_FILTER_QUERY_STRING = 'SET_FILTER_QUERY_STRING';
export const CLEAR_FILTER_QUERY_STRING = 'CLEAR_FILTER_QUERY_STRING';
export const IS_QUIZ = 'IS_QUIZ';
export const BECOME_PERFORMER = 'BECOME_PERFORMER';
export const jobItemsLimit = 100;
export const ID = 'id';
export const CITY = 'city';
export const CITY_NAME = 'cityName';
export const JOB_TYPE = 'jobType';
export const JOB_ITEM_LEVEL_1 = 'jobItemLevel1';
export const JOB_ITEM_LEVEL_2 = 'jobItemLevel2';
export const REGION = 'region';

export const numbers = {
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
  FOUR: 'FOUR',
  FIVE: 'FIVE',
};
