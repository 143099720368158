import { constants } from '../constants';

const PinInput = {
  // ...Input,
  // baseStyle: {
  //   ...Input.baseStyle,
  // },
  variants: {
    none: {
      border: 'none',
      borderColor: 'none',
      bg: 'none',
    },
    error: {
      fontSize: 'md',
      border: '1px solid',
      borderColor: 'typography.error',
      bg: constants.error100,
      _hover: {
        bg: constants.error200,
      },
    },
    outline: {
      bg: 'white',
      fontSize: 'md',
    },
  },
};

export default PinInput;
