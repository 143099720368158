import { getColor, mode } from '@chakra-ui/theme-tools';

function getDefaults(props) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  };
}

function variantFilled(props) {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '2px solid',
      borderColor: 'red.200',
      bg: mode('red.100', 'whiteAlpha.50')(props),
      _hover: {
        bg: mode('red.200', 'whiteAlpha.100')(props),
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
      _focus: {
        bg: 'white',
        borderColor: getColor(theme, fc),
      },
    },
    addon: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('brand.secondaryBackground', 'whiteAlpha.50')(props),
    },
  };
}
const Select = {
  variants: {
    error: variantFilled,
    primaryInput: {
      field: {
        bg: 'white',
        border: 'none',
        padding: '0 18px',
        _focus: {
          border: 'none',
        },
      },
      addon: {},
    },
  },
};

export default Select;
