import React from 'react';
import { Icon } from '@chakra-ui/react';

const WhatsAppIcon = props => (
  <Icon viewBox='0 0 38 38' s boxSize={6} {...props}>
    <path
      d='M19 0C8.52296 0 0 8.52296 0 19C0 22.5742 0.993115 26.0439 2.87704 29.0568C2.12654 31.6862 0.770962 36.6598 0.756346 36.7117C0.686192 36.9689 0.761461 37.2437 0.953654 37.4285C1.14585 37.6134 1.42354 37.6792 1.67638 37.6017L9.20769 35.2852C12.1578 37.0624 15.5362 38 19 38C29.477 38 38 29.477 38 19C38 8.52296 29.477 0 19 0ZM19 36.5385C15.6977 36.5385 12.4808 35.6155 9.69658 33.8704C9.57892 33.7966 9.44373 33.7593 9.30854 33.7593C9.23619 33.7593 9.16385 33.7703 9.09369 33.7915L2.5175 35.8157C3.00054 34.0524 3.86869 30.8984 4.37219 29.1438C4.43065 28.9407 4.39777 28.7214 4.28304 28.5438C2.43712 25.7033 1.46154 22.4032 1.46154 19C1.46154 9.32973 9.32973 1.46154 19 1.46154C28.6703 1.46154 36.5385 9.32973 36.5385 19C36.5385 28.6703 28.6703 36.5385 19 36.5385Z'
      fill='currentColor'
    />
    <path
      d='M31.4121 23.4768C30.0631 22.7277 28.9144 21.9765 28.0762 21.4284C27.436 21.0104 26.9734 20.7086 26.6344 20.5384C25.6866 20.0656 24.9682 20.3995 24.6949 20.6757C24.6605 20.7101 24.6299 20.7474 24.6035 20.7868C23.6192 22.2637 22.3338 23.6763 21.9574 23.7523C21.5226 23.6843 19.4874 22.5275 17.4647 20.8431C15.3995 19.1221 14.1002 17.4742 13.9095 16.3518C15.2344 14.9882 15.7116 14.1302 15.7116 13.1539C15.7116 12.1477 13.3643 7.94648 12.9397 7.5219C12.5137 7.09659 11.5542 7.03009 10.0876 7.3224C9.94651 7.3509 9.81644 7.42032 9.71413 7.5219C9.53655 7.69948 5.3814 11.9357 7.35594 17.0701C9.5234 22.7051 15.0867 29.255 22.1796 30.319C22.9856 30.4396 23.7412 30.4995 24.4486 30.4995C28.6213 30.4995 31.084 28.4 31.7775 24.2353C31.8294 23.9306 31.6825 23.6266 31.4121 23.4768ZM22.3966 28.8735C14.896 27.7489 10.1716 20.3177 8.72028 16.5454C7.28067 12.8039 9.93555 9.46794 10.602 8.71378C11.1442 8.62171 11.715 8.58297 11.9532 8.62024C12.4509 9.31228 14.174 12.5679 14.25 13.1539C14.25 13.5376 14.1251 14.0718 12.6372 15.5604C12.4998 15.697 12.4231 15.8826 12.4231 16.077C12.4231 19.9033 20.4937 25.2116 21.9231 25.2116C23.1661 25.2116 24.787 23.1224 25.7099 21.7609C25.7633 21.7631 25.8532 21.7814 25.9811 21.8457C26.2441 21.978 26.7001 22.2754 27.2767 22.6525C28.0382 23.1501 29.0495 23.8107 30.2436 24.4955C29.7014 27.1043 28.1858 29.7431 22.3966 28.8735Z'
      fill='currentColor'
    />
  </Icon>
);
export default WhatsAppIcon;
