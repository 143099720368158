import React, { useContext } from 'react';
import { Box, Img } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { commonStore } from '../../store/storeProvider';
import goBack from '../../utils/goBack';

const BackButton = () => {
  const {
    common: {
      ui: { isMobile },
      filterQueryString,
    },
  } = useContext(commonStore);
  const router = useRouter();
  return (
    <>
      {isMobile && router?.query?.from === 'search' && (
        <Box pos='fixed' bottom={4} left={2.5} cursor='pointer' zIndex={1}>
          <Img
            h={10}
            w={10}
            transitionDuration='0.1s'
            _hover={{ w: 10.5, h: 10.5 }}
            src='/deployment/icons/arrowBackMobile.png'
            onClick={() => goBack(router, filterQueryString)}
          />
        </Box>
      )}
    </>
  );
};

export default BackButton;
