export const mapAddress = ({ street, city }) => {
  return {
    name: city?.name,
    title: city?.name,
    id: city?.id,
    street,
    location: {
      latitude: city?.lat,
      longitude: city?.lng,
    },
  };
};
